import React, { useState } from 'react'
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from 'react-router-dom'
import DeleteAccModal from "./DeleteAccModal";
import LogOutModal from "./LogOutModal";
import { useAuth } from '../../../middleware/AuthProvider';
import MembershipModel from '../../CommonComponet/MembershipModel';
import Vector from "../../../images/Subscriptionplan/Vector.png";

const RespoAccSettingsidebar = () => {

  const { AuthData } = useAuth();


  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [logoutmodalShow, setLogoutModalShow] = useState(false);
  const [membershipModelShow, setMembershipModelShow] = useState(false);

  const showPlanModal = () =>{
    setMembershipModelShow(true)
  }

  return (
    <>
      <DropdownButton id="dropdown-basic-button" title="Setting">
        <Dropdown.Item as={Link} to="/accountsetting">Notifications</Dropdown.Item>

        {
          AuthData.is_premium ?
            <Dropdown.Item as={Link} to="/privacy">Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" /></Dropdown.Item>
            :
            <Dropdown.Item onClick={() => showPlanModal()} >Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" /></Dropdown.Item>
        }
        <Dropdown.Item as={Link} to="/reportproblem">Report a Problem</Dropdown.Item>
        <Dropdown.Item onClick={() => setDeleteModalShow(true)}>Delete Account</Dropdown.Item>
        <Dropdown.Item onClick={() => setLogoutModalShow(true)}>Log out</Dropdown.Item>
      </DropdownButton>

      <DeleteAccModal
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <LogOutModal
        show={logoutmodalShow}
        onHide={() => setLogoutModalShow(false)}
      />

      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />
    </>
  )
}

export default RespoAccSettingsidebar