import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { useEffect } from "react";
import { useState } from "react";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, AuthData } = useAuth();
  const location = useLocation();
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);
  const [referralCode, setReferralCode] = useState("");

  const isRequired = (value) => {
    if(value == null || value == "" || value == undefined) {
      return false;
    }
    return true;
  }

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //    if(params.get("referral_code")){
  //     setReferralCode(params.get("referral_code"))
  //    }
  //   }, [])

  useEffect(() => {
    const fields = [
      'name',
      'username',
      'date_of_birth',
      'gender_id',
      'i_am_here_to',
    ]
    if(isAuthenticated && AuthData.role == 'user') {
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if(!isRequired(AuthData[element])) {
          setIsProfileCompleted(false);
        }
      }
      // console.log("AuthData", AuthData);
      if(AuthData.is_couple) {
        // console.log("AuthData['partner_details']['partner_name']", AuthData['partner_details']['partner_name']);
        if(!isRequired(AuthData['partner_details']['partner_name'])) {
          setIsProfileCompleted(false);
        }
        if(!isRequired(AuthData['partner_details']['partner_dob'])) {
          setIsProfileCompleted(false);
        }
      }
    }
  })

  return isAuthenticated ? (
    isProfileCompleted ?
      children
    : (
      <Navigate to={"/profile-edit"} state={{ from: location }} />
    )
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
