import React, { useCallback, useRef, useState } from 'react'
import "../../css/Matched.css"
import Footer from '../CommonComponet/Footer'
import Header from '../CommonComponet/Header'
import { LikeUserApi, Matches } from '../../api/Api'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import defaultImg from "../../images/user-profile-default-image.png";
import { useAuth } from '../../middleware/AuthProvider'
import Vector from "../../images/Subscriptionplan/Vector.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Row } from "react-bootstrap";
import { useSocket } from '../../middleware/SocketProvider'
import { Store } from 'react-notifications-component';
import MembershipModel from '../CommonComponet/MembershipModel'
import FooterMobile from '../CommonComponet/FooterMobile'
import { LuSend } from "react-icons/lu";
import { Helmet } from 'react-helmet'
import Userdetailcard from '../CommonComponet/Userdetailcard'


const Matched = () => {
  const { AuthData, setAuthData } = useAuth();
  const { Socket } = useSocket();


  const [data, setData] = useState([])
  const [encryptedDatadata, setEncryptedDataData] = useState(false)
  const [hasMoreRecord, setHasMoreRecord] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pagePublic, setPagePublic] = useState(1);
  const [totalCount, setTotalCount] = useState([])
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [membershipModelShow, setMembershipModelShow] = useState(false);

  var girdItem = 0
  const divRef = useRef(null);

  useEffect(() => {
    document.title = "Matched Users On Hukup Australia"
  }, [])

  useEffect(() => {
    MatchesApiFin()
    Socket.on("like-change-" + AuthData.id, (likeData) => {
      if (!likeData.is_like) {
        setData(data.filter((ele) => {
          return ele.matched_user_id != likeData.liked_user_id
        }))
      }
    })
    return () => {
      Socket.off("like-change-" + AuthData.id)
    }
  }, [])

  useEffect(() => {
    Socket.on("like-change-" + AuthData.id, (likeData) => {
      if (!likeData.is_like) {
        setData(data.filter((ele) => {
          return ele.matched_user_id != likeData.liked_user_id
        }))
      }
    })
  }, [data])

  // -------- matched data post api ------
  const MatchesApiFin = (page = pagePublic) => {

    if (!hasMoreRecord || isLoading) {
      return false;
    }
    var datas = {
      page: page,
    };
    setIsLoading(true)
    Matches(datas).then(function (response) {
      setEncryptedDataData(response.data.encryptedData)
      setData(response.data.data)
      setTotalCount(response.data.totalMatch)
      setIsLoading(false)
      setCardSkeleton(false);
      if (page == 1) {
        setData(response.data.data)
      }
      else {
        setData(data.concat(response.data.data))
      }
      setPagePublic(page + 1)
      var userDatas = response.data.data;
      if (!userDatas.length) {
        setHasMoreRecord(false);
      }
      else {
        setHasMoreRecord(true);
      }
    })
      .catch(function (error) {
        console.log(error);
      });

  }

  const handleScroll = useCallback(() => {
    if (
      divRef.current &&
      divRef.current.scrollTop + divRef.current.clientHeight >=
      divRef.current.scrollHeight - 200 &&
      !isLoading
    ) {
      MatchesApiFin();
    }

  }, [isLoading, pagePublic, data, hasMoreRecord]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);


  return (
    <>

      <Helmet>
        <meta name="description" content={"Matched Users On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_first" />
          <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_second" />
          <img alt='smileemoji' src={require("../../../src/images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
          <img alt='CurveBlackline' src={require("../../../src/images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
          <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        <Header />

        <div className="container-fluid content_main px-0">

          <div className="container">
            <div className="row">
              {/* <div className="d-none d-sm-none d-md-none d-lg-block col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 ">
                <div className="d-flex flex-column align-items-center position-relative  mt-5"> */}
              {/* ====== User Name And User Image section ======== */}
              <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                <Userdetailcard />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 matched_section_contain">
                <div className="row">
                  <div className="mx-auto col-10 matched_section_title ">
                    <h1 className="matched_title">Your Matches <span className="pinkcustom">{totalCount}</span></h1>
                  </div>
                  <div className="col-12 matched_section_slider" ref={divRef}>
                    {/* ============ card section ============ */}
                    {cardSkeleton && (
                      <SkeletonTheme >
                        <Row className="">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                            return (
                              <Col xs={6} md={4} lg={3}>
                                <Skeleton height={200} count={1} />
                              </Col>
                            )
                          })}
                        </Row>
                      </SkeletonTheme>
                    )}
                    <div className="row row-cols-2 row-cols-lg-3 row-cols-md-3 row-cols-xl-5 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                      {
                        data.map((item, index) => {
                          var gridClass = "";
                          if (window.innerWidth < 768) {
                            if (index == 0) {
                              gridClass = "slider_img";
                              girdItem = 0;
                            }
                            else {
                              girdItem = girdItem + 1;
                              if (girdItem == 3 || girdItem == 4) {
                                gridClass = "slider_img";
                              }
                              if (girdItem == 4) {
                                girdItem = 0;
                              }
                            }
                          }
                          else {
                            gridClass = index % 2 === 0 ? "" : "slider_img";
                          }
                          return (
                            <ProfileCard gridClass={gridClass} item={item} index={index} data={data} setData={setData} totalCount={totalCount} setTotalCount={setTotalCount} />
                          )
                        })
                      }
                      {(totalCount == 0 && !cardSkeleton) ? <img alt='Matched' src={require("../../images/Visitors/MatchedObject.png")} className="Matched_is_non_img" /> : ""}
                      {/* ========== Matched Fake Data ======== */}
                      {
                        encryptedDatadata && (() => {
                          const renderedFakeData = [];
                          for (let index = 0; index < totalCount - data.length; index++) {
                            renderedFakeData.push(
                              <div key={index} className="card_main" onClick={() => setMembershipModelShow(true)}>
                                <div className="card_section_main_sidebar_liked blur_img_fake_contain">
                                  <div className="card_section_sidebar_center_main" >
                                    {/*== fav section  =====*/}
                                    <div className="card_section_chiled">
                                      <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
                                    </div>

                                    {/*== like section  =====*/}
                                    <div className="card_section_chiled">
                                      <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
                                    </div>

                                    {/*=== chat icon ====*/}
                                    <div className="card_section_chiled">
                                      <LuSend className="card_Chatpage_luSend_sidebar" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col px-0 slide_main_section">
                                  <Link className='linkover'>
                                    <div className="card_content_section">
                                      <img src={require("../../images/nophoto.png")} className="card_background_img img-fluid blur_img_fake_contain" alt="Favourite"></img>
                                      {/* <div className={props.gridClass} ></div> */}
                                      <div className="fake_slider_img" ><img alt='Premium' src={Vector} className="fake_private_image" onClick={() => setMembershipModelShow(true)} /></div>
                                      <div className="content_bottom card_content_bottom">
                                        <div className="user_name_width_set">
                                          <h4 className="my-0 content_slider_name blur_name_like_fake_contain">prit</h4>
                                          <p className="my-0 content_slider_description blur_name_like_fake_contain"><span className="content_slider_description_color">10.5km</span></p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                              </div>
                            )
                          }
                          return renderedFakeData;
                        })()}
                    </div>
                  </div>
                </div>
                {/* ============ card section ============ */}
              </div>
            </div>

          </div>
          <FooterMobile />

        </div>
        <Footer />
      </div>

      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />
    </>
  )
}

export default Matched

const ProfileCard = (props) => {
  const { Socket } = useSocket();
  const { AuthData } = useAuth();

  const [isLike, SetIsLike] = useState(props.item.is_like);
  const [isFav, SetIsFav] = useState(props.item.is_favourites)
  const navigate = useNavigate();

  const LikeApi = () => {

    var likeData = {
      liked_user_id: props.item.matched_user_id,
    };
    Socket.off("liked-response-" + props.item.matched_user_id);
    Socket.emit("like", likeData);
    Socket.on("liked-response-" + props.item.matched_user_id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        SetIsLike(!isLike)
        props.setData(props.data?.filter((item) => {
          return item.id != props.item.id
        }))
        props.setTotalCount(props.totalCount - 1)
      }
    });

  };

  useEffect(() => {
    Socket.on("favorite-change-" + AuthData.id, (data) => {
      if (data.favourite_user_id == props.item.matched_user_id) {
        SetIsFav(data.is_favourite)
      }
    })

    return () => {
      Socket.off("favorite-change-" + AuthData.id)
    }
  }, [props])

  const FavApi = () => {
    var favouriteData = {
      favourite_user_id: props.item.matched_user_id,
    };
    Socket.off("favourites-response-" + props.item.matched_user_id);
    Socket.emit("favourite", favouriteData);
    Socket.on("favourites-response-" + props.item.matched_user_id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        SetIsFav(!isFav)
      }
    });
  };

  return (
    <div className="card_main" >
      <div className="card_section_main_sidebar_Matched">
        <div className="card_section_sidebar_center_main" >

          {/*== fav section  =====*/}
          <div className="card_section_chiled" onClick={FavApi}>
            {
              isFav ? (
                <img alt='Favourite' src={require("../../images/UserDetailspage/staruserdetailes.png")} className="fav_icon_heart" />
              ) : (
                <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
              )
            }
          </div>

          {/*== like section  =====*/}
          <div className="card_section_chiled" onClick={LikeApi}>
            {
              <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
            }
          </div>

          {/*=== chat icon ====*/}
          <div className="card_section_chiled">
            <LuSend className="card_Chatpage_luSend_sidebar" onClick={() => navigate("/messages?username=" + props?.item?.username)} />
          </div>
        </div>
      </div>

      <div className="col px-0 slide_main_section"  >
        <Link to={"/user/" + props.item.username} className='linkover'>
          <div className="card_content_section">
            <img src={props.item.thumbnail_image} className="card_background_img img-fluid" alt="Profile" />
            <div className={props.gridClass} ></div>
            <div className="content_bottom card_content_bottom">
              <div className="user_name_width_set">
                <h4 className="my-0 content_slider_name"> {props.item.name}</h4>
                <p className="my-0 content_slider_description"><span className="content_slider_description_color">{props.item?.distance < 1 ? ` ${(props.item?.distance * 1000)} M` : ` ${props.item?.distance} KM`}</span></p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}