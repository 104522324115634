import React from "react";
import Footer from "../CommonComponet/Footer";
import { useAuth } from "../../middleware/AuthProvider";
import Header from "../CommonComponet/Header";
import HeaderSecond from "../CommonComponet/HeaderSecond";
import FooterSecond from "../CommonComponet/FooterSecond";

const EmailVerificationError = () => {
  const { isAuthenticated, siteSetting } = useAuth();
  return (
    <>
      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        {
          isAuthenticated ? (
            <Header />
          ): (<HeaderSecond />)
        }

        <div className="container content_main">
          <div className="row">
            <div className="col-12 Blocked_User_section_contain">
              <div className="">
                <div className="mt-5">
                <p>Dear User,</p>

                <p>We regret to inform you that the email verification process for your account was unsuccessful. We understand that this may be inconvenient, and we apologize for any inconvenience caused.</p>

                <br />
                <p>Here are a few common reasons why verification might have failed:</p>

                <ul>
                  <li><p>The verification link may have expired. Verification links are time-sensitive for security reasons.</p></li>
                  <li><p>Please ensure that you clicked on the correct verification link provided in the verification email.</p></li>
                  <li><p>It's possible that your email has already been verified. Please check your account status or contact our support team for assistance.</p></li>
                </ul>

                <br />
                <p>If you continue to experience issues, kindly follow these steps:</p>

                <ul>
                  <li><p>Request a new verification email by logging into your account and navigating to the profile section.</p></li>
                  <li><p>Double-check your email inbox, including spam or junk folders, to ensure you receive the new verification email.</p></li>
                </ul>

                <p>If you have any questions or need assistance, feel free to reach out to our support team at <a href={"https://gmail.com"}>{siteSetting?.siteSetting?.support_email}</a></p>

                <p>Thank you for your understanding.</p>

                <p>Best regards,</p>
                <p>{siteSetting?.siteSetting?.site_name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
        isAuthenticated ? (
        <Footer />
        ) : (<FooterSecond />)
      }
      </div>
    </>
  );
};

export default EmailVerificationError;
