import React, { useRef } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import "../../css/Gallery.css";
import { useState } from "react";
import Showgallery from "./Showgallery";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import privatLock from "../../images/lock.png";
import { OtherUserPrivateGallery, OtherUserPublicGallery } from "../../api/Api";
import { useCallback } from "react";
import { useSocket } from "../../middleware/SocketProvider";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataLoader from "../../components/ScreenLoader/DataLoader";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";

function UserGallery() {
  const { Socket } = useSocket();
  const { username } = useParams();
  const navigate = useNavigate();
  const [key, setKey] = useState("Gallery");
  const [isDataLoader, setisDataLoader] = useState(false);
  const [accessUserId, setAccessUserId] = useState(0);
  const [userDetail, setUserDetail] = useState({});
  const [publicGallery, setPublicGallery] = useState([]);
  const [pvtGallery, setPvtGallery] = useState([]);
  const [pagePublic, setPagePublic] = useState(1);
  const [pagePvt, setPagePvt] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingpvt, setIsLoadingpvt] = useState(false);
  const [hasMoreRecord, setHasMoreRecord] = useState(true);
  const [hasMoreRecordPvt, setHasMoreRecordPvt] = useState(true);
  const [hasPrivateGallaryAccess, setHasPrivateGallaryAccess] = useState(false);
  const [galleryAccessStatus, setGalleryAccessStatus] = useState("");
  const [hasIsPrivateImage, setHasIsPrivateImage] = useState(false);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(true);
  const divRef = useRef(null);
  const divRefpvt = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('tab');
    if (paramValue == "private") {
      setKey("Private Gallery")
    }
    else {
      setKey("Gallery")
    }
  }, [location.search]);


  // useEffect(() => {
  //   if (userDetail?.name) {
  //     document.title = `Hukup ${userDetail.name} gallery`;
  //   } else {
  //     document.title = `Hukup ${username} gallery`;
  //   }
  // }, [userDetail.name])

  useEffect(() => {
    document.title = `${username} gallery On Hukup Australia`;
  }, [username])

  useEffect(() => {
    UserPublicGalleryApi();
    UserPrivateGalleryApi();

    Socket.on('gallery-access-request-update-' + accessUserId, (data) => {
      if (data.status) {
        setPagePvt(1);
        UserPrivateGalleryApi(1);
      }
    })
    return () => {
      Socket.off('gallery-access-request-' + accessUserId)
      Socket.off('gallery-access-request-update-' + accessUserId)
    }
  }, []);

  const UserPublicGalleryApi = () => {
    if (!hasMoreRecord || isLoading) {
      return false;
    }
    var data = {
      page: pagePublic,
    };
    setIsLoading(true);
    OtherUserPublicGallery(username, data)
      .then(function (response) {
        setIsLoading(false);
        setCardSkeleton(false);
        if (response.data.visit_limit_over || !response.data.status) {
          Store.addNotification({
            title: "View Profile Limit",
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          navigate("/home")
          return false
        }
        setUserDetail(response.data.user_detail);
        setHasPrivateGallaryAccess(response.data.is_private_access && response.data.access_status == "approved");
        setHasIsPrivateImage(response.data.is_private_image)
        setGalleryAccessStatus(response.data.access_status);
        setAccessUserId(response.data.userId);
        setPublicGallery(publicGallery.concat(response.data.data));
        setPagePublic(pagePublic + 1);
        var userDatas = response.data.data;
        if (!userDatas.length) {
          setHasMoreRecord(false);
        } else {
          setHasMoreRecord(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const UserPrivateGalleryApi = (pageNo = pagePvt) => {
    if (!hasMoreRecordPvt || isLoadingpvt) {
      return false;
    }
    var data = {
      page: pageNo,
    };
    setIsLoadingpvt(true);
    OtherUserPrivateGallery(username, data)
      .then(function (response) {
        setIsLoadingpvt(false);
        setCardSkeletonMeUser(false)
        if (!response.data.status) {
          return false;
        }
        if (!response.data.access_message == "") {
          Store.addNotification({
            message: response.data.access_message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        if (response.data.access_status == "approved") {
          setPagePvt(pageNo + 1);
          setPvtGallery(pvtGallery.concat(response.data.data));
          var userDatas = response.data.data;
          if (!userDatas.length) {
            setHasMoreRecordPvt(false);
          } else {
            setHasMoreRecordPvt(true);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleScroll = useCallback(() => {
    if (
      divRef.current &&
      divRef.current.scrollTop + divRef.current.clientHeight >=
      divRef.current.scrollHeight - 200 &&
      !isLoading
    ) {
      UserPublicGalleryApi();
    }
  }, [isLoading, pagePublic, hasMoreRecord, publicGallery]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  // __________________________________
  const handleScrollPvtImg = useCallback(() => {
    if (
      divRefpvt.current &&
      divRefpvt.current.scrollTop + divRefpvt.current.clientHeight >=
      divRefpvt.current.scrollHeight - 200 &&
      !isLoadingpvt
    ) {
      UserPrivateGalleryApi();
    }
  }, [isLoadingpvt, setHasMoreRecordPvt, pvtGallery, pagePvt]);

  useEffect(() => {
    if (divRefpvt.current) {
      divRefpvt.current.addEventListener("scroll", handleScrollPvtImg);
    }
    return () => {
      if (divRefpvt.current) {
        divRefpvt.current.removeEventListener("scroll", handleScrollPvtImg);
      }
    };
  }, [handleScrollPvtImg]);

  const sendAccessRequest = () => {
    setisDataLoader(true)
    Socket.off('gallery-access-request-' + accessUserId)
    Socket.emit('gallery-access-request', { access_user_id: accessUserId })
    Socket.on('gallery-access-request-' + accessUserId, (data) => {
      setisDataLoader(false)
      if (data.status) {
        setGalleryAccessStatus("pending")
        Store.addNotification({
          message: data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
  }


  return (

    <>
      <Helmet>
        <meta name="description" content={`${username} gallery On Hukup Australia`} />
      </Helmet>
      <div className="container-fluid p-0 main_section">
        {isDataLoader && (
          <DataLoader />
        )}
        <div className="background_section_main">
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />


        <div className="container-fluid gallery_main_section text-center px-0">

          <div className="container ">
            <div className="row">
              <div className="col-12 gallery_image_padding">
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="my-2 px-1"
                >
                  <Tab
                    eventKey="Gallery"
                    title="Gallery"
                    className="px-0 tab_liked_you"
                  >
                    <div ref={divRef} className="gallery_tab_main_section">
                      {cardSkeleton && (
                        <SkeletonTheme >
                          <Row className="m-0">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                              return (
                                <Col xs={6} md={3} lg={2} xxl={2}>
                                  <Skeleton height={200} count={1} />
                                </Col>
                              )
                            })}
                          </Row>
                        </SkeletonTheme>
                      )}
                      <div className="gallery ">
                        {publicGallery.map((image, index) => {
                          return <Showgallery userDetail={userDetail} key={index} image={image} />;
                        })}
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="Private Gallery"
                    title="Private Gallery"
                    className="px-0 tab_liked_you"
                  >
                    {
                      !hasPrivateGallaryAccess && (
                        <div className="lock_div">
                          <img alt="Lock" src={privatLock} className="lock_image_show"></img>
                          {galleryAccessStatus == '' && (
                            <>
                              <button disabled={!hasIsPrivateImage} className={hasIsPrivateImage ? "request_button" : "request_button_disabled"} onClick={sendAccessRequest}>Request For Access</button>
                              {!hasIsPrivateImage ? <p className="mt-1"> Private gallary not uploaded</p> : ""}
                            </>
                          )}
                          {galleryAccessStatus == 'pending' && (
                            <span className="pending_request_button">Request is pending</span>
                          )}
                          {galleryAccessStatus == 'rejected' && (
                            <div className="pending_request_button">
                              <p>Request is rejected</p>
                              <button disabled={!hasIsPrivateImage} className={hasIsPrivateImage ? "request_button_rejected" : "request_button_disabled"} onClick={sendAccessRequest}>Request For Access</button>
                              {!hasIsPrivateImage ? <p className="mt-1">Private gallary not uploaded</p> : ""}
                            </div>
                          )}
                          {galleryAccessStatus == 'expired' && (
                            <div className="pending_request_button">
                              <p>Access is expired</p>
                              <button disabled={!hasIsPrivateImage} className={hasIsPrivateImage ? "request_button_rejected" : "request_button_disabled"} onClick={sendAccessRequest}>Request For Access</button>
                              {!hasIsPrivateImage ? <p className="mt-1">Private gallary not uploaded</p> : ""}
                            </div>
                          )}
                        </div>
                      )
                    }
                    <div
                      ref={divRefpvt}
                      className="gallery_tab_main_section"
                    >
                      {cardSkeletonMeUser && (
                        <SkeletonTheme >
                          <Row className="m-0">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                              return (
                                <Col xs={6} md={3} lg={2} xxl={2}>
                                  <Skeleton height={200} count={1} />
                                </Col>
                              )
                            })}
                          </Row>
                        </SkeletonTheme>
                      )}
                      <div className="gallery ">
                        {pvtGallery.map((image, index) => {
                          return <Showgallery userDetail={userDetail} key={index} image={image} />;
                        })}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <FooterMobile />

        </div>
        <Footer />
      </div>

    </>
  );
}
export default UserGallery;
