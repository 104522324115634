import React, { useState } from 'react'
import Header from '../../CommonComponet/Header'
import Footer from '../../CommonComponet/Footer'
import "./Settingnotifications.css"
import { NotificationSetting, NotificationView } from '../../../api/Api'
import { useEffect } from 'react'
import AccountSettingsidebar from "./AccountSettingsidebar.js"
import RespoAccSettingsidebar from "./RespoAccSettingsidebar.js"
import FooterMobile from '../../CommonComponet/FooterMobile'
import { Helmet } from 'react-helmet'

const Settingnotifications = () => {

    const [isSendMessage, setIsSendMessage] = useState(false);
    const [allowNotification, setAllowNotification] = useState(false);
    const [isHappens, setIsHappens] = useState(false);
    const [likeYourProfile, setLikeYourProfile] = useState(false);
    const [profileAsFavorite, setProfileAsFavorite] = useState(false);
    const [settingData, setSettingData] = useState(null);


    useEffect(() => {
        document.title = "Notifications Page On Hukup Australia"
        NotificationViewApi()
    }, [])

    useEffect(() => {
        if (settingData) {
            NotificationSettingApi()
        }
    }, [isSendMessage, isHappens, likeYourProfile, profileAsFavorite, allowNotification])


    //  --- get  to notifiction data to swicth ----- 
    const NotificationViewApi = () => {
        NotificationView().then(function (response) {
            setIsSendMessage(response.data.data.send_message !== 0 ? true : false)
            setIsHappens(response.data.data.match_happens !== 0 ? true : false)
            setLikeYourProfile(response.data.data.like_your_profile !== 0 ? true : false)
            setProfileAsFavorite(response.data.data.profile_as_favorite !== 0 ? true : false)
            setAllowNotification(response.data.data.allow_notification !== 0 ? true : false)
            setSettingData(response.data.data)
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    //--------- call notification setting api --------------
    const NotificationSettingApi = () => {
        var data = {
            allow_notification: allowNotification,
            send_message: isSendMessage,
            match_happens: isHappens,
            like_your_profile: likeYourProfile,
            profile_as_favorite: profileAsFavorite,
        }

        NotificationSetting(data).then(function (response) {
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <>

            <Helmet>
                <meta name="description" content={"Notifications Page On Hukup Australia"} />
            </Helmet>

            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <img alt='smileemoji' src={require("../../../../src/images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='CurveBlackline' src={require("../../../../src/images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='orangeline' src={require("../../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt='greenline' src={require("../../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt='Ellipse' src={require("../../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt='footerblackline' src={require("../../../../src/images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                <Header />
                <div className="container-fluid content_main px-0">
                    <div className="Notifications_main_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-3 Settingnotifications_siderbar_main pl-lg-3 pl-xl-4 d-none d-lg-block">
                                    <AccountSettingsidebar />
                                </div>


                                <div className="col-12 col-lg-8 offset-0 offset-lg-1">
                                    <div className="text-center  mt-5 mb-4 d-none d-lg-block">
                                        <h1 className="Settingnotifications_Setting_title">Notifications</h1>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-3 d-block d-lg-none">
                                        <div className=" ">
                                            <span className="notifiction_setting_mobile_title">Notifications</span>
                                        </div>
                                        <div>
                                            <RespoAccSettingsidebar />
                                        </div>
                                    </div>

                                    <div className="Settingnotifications_Allow_notification_main ">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label Settingnotifications_label_name" >Allow notification</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={allowNotification} onChange={(e) => { setAllowNotification(!allowNotification); }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="Settingnotifications_Allow_notification_main mt-4">
                                        <h4 className="mb-3 Settingnotifications_E_mail_Privacy">E-mail notification</h4>
                                        <div className="d-flex align-items-center justify-content-between my-3  ">
                                            <label className="form-check-label Settingnotifications_label_name" >When any user send a message</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={isSendMessage} onChange={(e) => { setIsSendMessage(!isSendMessage); }} />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between my-3 ">
                                            <label className="form-check-label Settingnotifications_label_name" >When any new match happens</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={isHappens} onChange={(e) => { setIsHappens(!isHappens); }} />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between my-3 ">
                                            <label className="form-check-label Settingnotifications_label_name" >When any user like your profile</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={likeYourProfile} onChange={(e) => { setLikeYourProfile(!likeYourProfile); }} />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between my-3 ">
                                            <label className="form-check-label Settingnotifications_label_name" >When any user marks your profile as favourite</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" checked={profileAsFavorite} onChange={(e) => { setProfileAsFavorite(!profileAsFavorite); }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMobile />
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Settingnotifications