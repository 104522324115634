import React, { useEffect, useState } from 'react'
import Header from '../../CommonComponet/Header'
import HeaderSecond from '../../CommonComponet/HeaderSecond'
import Footer from '../../CommonComponet/Footer'
import FooterSecond from '../../CommonComponet/FooterSecond'
import { useAuth } from '../../../middleware/AuthProvider'
import "./Faq.css"
import { Helmet } from "react-helmet";
import { FaqMetaPageDataApi, FaqPageDataApi } from '../../../api/Api'

const Faq = () => {
    const { isAuthenticated } = useAuth();
    const [questionanswer, setQuestionanswer] = useState([])
    const [faqmetadata, setFaqmetadata] = useState({})

    useEffect(() => {
        FaqPageDataApicall()
        FaqMetaPageDataApiCall()

    }, [])

    const FaqPageDataApicall = () => {
        FaqPageDataApi().then(function (response) {
            if (response.data.status) {
                setQuestionanswer(response.data.data)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const FaqMetaPageDataApiCall = () => {

        FaqMetaPageDataApi().then(function (response) {
            if (response.data.status) {
                setFaqmetadata(response.data.data)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <>
            <Helmet>
                <title>{faqmetadata.title}</title>
                <meta name="description" content={faqmetadata.meta_description} />
                <meta name="keywords" content={faqmetadata.meta_keyword}></meta>
            </Helmet>

            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <img alt="Whitedot" src={require("../../../images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_first" />
                    <img alt="Whitedot" src={require("../../../images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_second" />
                    <img alt="smileemoji" src={require("../../../images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt="CurveBlackline" src={require("../../../images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt="orangeline" src={require("../../../images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt="greenline" src={require("../../../images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt="Ellipse" src={require("../../../images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt="footerblackline" src={require("../../../images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                {
                    isAuthenticated ? (
                        <Header />
                    ) : (<HeaderSecond />)
                }

                <div className="container Faq_content_main">
                    <div className="row position-relative">
                        <div className="text-center ">
                            <h2 className="mt-2">FAQ</h2>
                            <h4 className="my-2" >(Frequently Asked Questions)</h4>
                        </div>
                        {
                            questionanswer.map((ele) => {
                                return (
                                    <>
                                        <details>
                                            <summary className="faq_summary_title">
                                                {ele.questions}
                                            </summary>
                                            <p>{ele.answer}</p>
                                        </details>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    isAuthenticated ? (
                        <Footer />
                    ) : (<FooterSecond />)
                }
            </div>
        </>
    )
}

export default Faq