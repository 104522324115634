import React, { useEffect, useRef, useState } from "react";
import "../Profiledetails/Profiledetails.css";
import "../../../css/Paymentmethod.css";
import {
  MdKeyboardArrowLeft,
} from "react-icons/md";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Ellipse12 from "../../../images/profilepage/Ellipse 12.png";
import Ellipse13 from "../../../images/profilepage/Ellipse 13.png";
import Ellipse14 from "../../../images/profilepage/Ellipse 14.png";
import Ellipse4 from "../../../images/profilepage/Ellipse 4.png";
import Ellipse5 from "../../../images/profilepage/Ellipse 5.png";
import Group15 from "../../../images/profilepage/Group 15.png";
import Group5 from "../../../images/profilepage/Group 5.png";
import Group6 from "../../../images/profilepage/Group 6.png";
import cameraicon from "../../../images/profilepage/camera.png";
import defaultphoto from "../../../images/nophoto.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CountrycodeApi, ProfilPageLocationDataApi, ProfileSecondPageDataApi, UpdateProfileApi, UpdateProfilePartenerPageApi, UpdateProfileSecondPageApi, UsernameCheckApiCall } from "../../../api/Api";
import { useAuth } from "../../../middleware/AuthProvider";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import CropImageModal from "../../CommonComponet/CropImageModal";
import calendar from '../../../images/Calendar.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from "react-helmet";
import DataLoader from "../../../components/ScreenLoader/DataLoader";

const Profiledetails = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { AuthData, setAuthData } = useAuth()
  const [defaultUserCoverImage, setDefaultUserCoverImage] = useState(AuthData.thumbnail_image ? AuthData.thumbnail_image : defaultphoto);
  const [step, Setstep] = useState(0);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });
  const [image, setImage] = useState(null);

  const [gender, setGender] = useState(AuthData.gender_id ? AuthData.gender_id : 1);
  const [name, SetName] = useState(AuthData.name)
  const [userName, SetUserName] = useState(AuthData.username)
  const [profileImg, SetProfileImg] = useState("")
  const [referralCode, SetreferralCode] = useState("")
  const [isDataLoader, setisDataLoader] = useState(false);
  // const [location, SetLocation] = useState(AuthData.location)
  // const [email, setEmail] = useState(AuthData.email)
  // const [locationdata, setLocationdata] = useState([]);

  const [genderList, setGenderList] = useState([]);
  // const [countryData, setCountryData] = useState([])
  // const [countryId, setCountryId] = useState("")
  // const [locplaceholder, SetLocplaceholder] = useState("")
  // const [countryIdToVerify, setCountryIdToVerify] = useState(false)
  // const [showsuburb, setShowsuburb] = useState(AuthData.is_show_suburb)

  const [nameError, setNameError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [referral,setreferral] = useState(false);
  // const [locationError, setLocationError] = useState('');
  // const [emailError, setEmailError] = useState("")
  // const [countryIdError, setCountryIdError] = useState("")

  const [category, setcategory] = useState([]);
  const [usercategory, setUserCategory] = useState('')

  const [partnername, setPartnername] = useState(AuthData.partner_details?.partner_name)

  const isCouple = genderList.find((element) => element.id == gender)

  const formatDate = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);
    // Get the year, month, and day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = date.getDate().toString().padStart(2, '0');

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };
  const [dateOfBirth, SetDateOfBirth] = useState(
    AuthData.date_of_birth
      ? new Date(AuthData.date_of_birth)
      : ""
  );

  const [partnerDateofBirth, setPartnerDateofBirth] = useState(AuthData.partner_details?.partner_dob
    ? new Date(AuthData.partner_details?.partner_dob)
    : "")
  const [partnernameError, setpartnernameError] = useState('')
  const [partnerDateOfBirthError, setPartnerDateOfBirthError] = useState('')

  useEffect(() => {
    document.title = "Hukup Welcome";
  }, [])


  useEffect(() => {
  const params = new URLSearchParams(location.search);
   if(params.get("referral_code")){
    SetreferralCode(params.get("referral_code"));
    setreferral(true)
   }
  }, [])

  const coverImageRef = useRef();
  const showOpenCoverFileDialog = () => {
    coverImageRef.current.click();
  };
  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(file?.type)) {
      SetProfileImg("");
      setDefaultUserCoverImage(defaultphoto)
      Store.addNotification({
        message: "Only JPG and PNG Image allowed",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return;
    }
    setCrop({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });
    setSrc(URL.createObjectURL(file));
  };


  useEffect(() => {
    ProfileSecondPageDataApi().then(function (response) {
      setGenderList(response.data.data.gender);
      setinterest(response.data.data.interest)
      setmakeanewfriend(response.data.data.purpose)
      setcategory(response.data.data.categories)
    })
      .catch(function (error) {
        console.log(error);
      });
    // ProfilPageLocationData()
    // CountrycodeApiCall()
  }, [])

  // const CountrycodeApiCall = () => {
  //   CountrycodeApi().then(function (response) {
  //     setCountryData(response.data.data)
  //     var checkCountryId = AuthData.country_id ? AuthData.country_id : response.data.data[0].id
  //     setCountryId(AuthData.country_id ? AuthData.country_id : "")
  //     var findcountryid = response.data.data
  //     var dd = findcountryid.find((ele) => ele.id == checkCountryId)

  //     if (dd.is_show_suburb) {
  //       setCountryIdToVerify(false)
  //       SetLocplaceholder("Suburb*")
  //     } else {
  //       setCountryIdToVerify(true)
  //       SetLocation(dd.country_name)
  //       SetLocplaceholder("Country name*")
  //     }

  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     })
  // }


  // const handleCountryId = (e) => {
  //   setCountryIdError("");
  //   setCountryId(e.target.value)
  //   var doundata = countryData.find((ele) => ele.id == e.target.value)

  //   if (doundata.is_show_suburb) {
  //     setCountryIdToVerify(false)
  //     SetLocation("");
  //     SetLocplaceholder("Suburb*");
  //   } else {
  //     setCountryIdToVerify(true)
  //     SetLocation(doundata.country_name);
  //     setLocationError("")
  //     SetLocplaceholder("Country name*");
  //   }
  // }

  // const ProfilPageLocationData = () => {
  //   ProfilPageLocationDataApi().then(function (response) {
  //     setLocationdata(response.data.data);
  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  const [interest, setinterest] = useState([]);
  const [selectinterest, setSelectinterest] = useState(AuthData.interest?.map((e) => e.id));
  const interestClick = (type) => {
    if (selectinterest.find((ele) => ele == type)) {
      setSelectinterest(selectinterest.filter((ele) => type != ele));
    } else {
      setSelectinterest(selectinterest.concat([type]));
    }
  };
  const [makeanewfriend, setmakeanewfriend] = useState([]);
  const [selectmakeanewfriend, setSelectmakeanewfriend] = useState(AuthData.i_am_here_to ? AuthData.i_am_here_to?.split(", ") : []);
  const makeanewfriendClick = (type) => {
    if (selectmakeanewfriend?.find((ele) => ele == type)) {
      setSelectmakeanewfriend(
        selectmakeanewfriend?.filter((ele) => type != ele)
      );
    } else {
      setSelectmakeanewfriend(selectmakeanewfriend?.concat([type.toString()]));
    }
  };

  // validation
  const validation = () => {
    Errorclear();
    var isvalid = true
    var usernameValid = true;
    if (name == "" || !name || name.trim() == "") {
      setNameError("Please Enter Name");
      isvalid = false;
    }
    // if (userName == "" || !userName || userName.trim() == "") {
    //   setUserNameError("Please Enter Username");
    //   isvalid = false;
    // } else {
    //   if (!/^[a-zA-Z]+$/.test(userName.charAt(0))) {
    //     setUserNameError("Username must be start with alphabets");
    //     isvalid = false;
    //     usernameValid = false;
    //   }
    //   if (usernameValid) {
    //     const res = /^[a-zA-Z0-9_]+$/.test(userName);
    //     if (!res) {
    //       setUserNameError("Username only conatin alphabets, number and underscore(_)");
    //       isvalid = false;
    //       usernameValid = false;
    //     }
    //   }
    //   if (usernameValid) {
    //     if (/^\d+$/.test(userName)) {
    //       setUserNameError("Only number is not allowed in username");
    //       isvalid = false;
    //       usernameValid = false;
    //     }
    //   }
    //   if (usernameValid) {
    //     if (/^[_]+$/.test(userName)) {
    //       setUserNameError("Only underscore is not allowed in username");
    //       isvalid = false;
    //       usernameValid = false;
    //     }
    //   }
    // }

    // if (AuthData.is_show_suburb) {
    //   if (location == "" || !location || location.trim() == "") {
    //     setLocationError("Please Enter Location");
    //     isvalid = false;
    //   }
    // }

    if (dateOfBirth == "" || !dateOfBirth) {
      setDateOfBirthError("Please Enter Date of Birth");
      isvalid = false;
    } else {
      const currentDate = new Date();
      const selectedDateObj = new Date(dateOfBirth);
      currentDate.setHours(0)
      currentDate.setMinutes(0)
      currentDate.setSeconds(0)
      selectedDateObj.setHours(0)
      selectedDateObj.setMinutes(0)
      selectedDateObj.setSeconds(0)

      // Calculate the minimum date of birth for an 18-year-old
      const minDateOfBirth = new Date(currentDate);
      minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - 18);
      minDateOfBirth.setDate(minDateOfBirth.getDate() - 1);

      if (selectedDateObj > minDateOfBirth) {
        // Check if the selected date is at least 18 years ago
        setDateOfBirthError("Age is must be 18+");
        isvalid = false;
      }
    }

    // if (email != "") {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!emailRegex.test(email)) {
    //     setEmailError("Email is not valid")
    //     isvalid = false;
    //   }
    // }

    // if (countryId == "" || !countryId) {
    //   setCountryIdError("Please Select countrycode");
    //   isvalid = false;
    // }

    return isvalid;
  }

  const Errorclear = (e) => {
    setNameError("");
    setUserNameError("");
    // setLocationError("");
    setDateOfBirthError("");
    // setEmailError("");
    // setCountryIdError("");
  };

  const handleUserName = () => {

    var usernameValid = true;
    var isvalid = true;


    if (usernameValid && userName.length) {

      if (!/^[a-zA-Z]+$/.test(userName.charAt(0))) {
        setUserNameError("Username must be start with alphabets");
        isvalid = false;
        usernameValid = false;
      }

      if (usernameValid) {
        const res = /^[a-zA-Z0-9_]+$/.test(userName);
        if (!res) {
          setUserNameError("Username only conatin alphabets, number and underscore(_)");
          isvalid = false;
          usernameValid = false;
        }
      }

      if (usernameValid) {
        if (/^\d+$/.test(userName)) {
          setUserNameError("Only number is not allowed in username");
          isvalid = false;
          usernameValid = false;
        }
      }

      if (usernameValid) {
        if (/^[_]+$/.test(userName)) {
          setUserNameError("Only underscore is not allowed in username");
          isvalid = false;
          usernameValid = false;
        }
      }
    }
    if (isvalid && userName.length) {
      var data = {
        username: userName
      }
      UsernameCheckApiCall(data).then(function (response) {
        if (!response.data.status) {
          setUserNameError(response.data.message)
        } else {
          setUserNameError("")
        }
      })
        .catch(function (error) {
          console.log(error);
        })
    }

  };



  const UpdateProfileApiCall = () => {
    setisDataLoader(true)
    if (!validation()) {
      setisDataLoader(false)
      return false;
    }
    const formData = new FormData()
    formData.append("username", userName)
    formData.append("name", name)
    formData.append("referral_code", referralCode)
    // formData.append("location", location)
    formData.append("date_of_birth", formatDate(dateOfBirth))
    if (profileImg != "") {
      formData.append("profile", profileImg, "user-" + AuthData.id + ".png")
    }
    // formData.append("email", email)
    // formData.append("country_id", countryId)


    UpdateProfileApi(formData).then(function (response) {
      setisDataLoader(false)
      // handle success
      if (!response.data.status) {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
      response.data.data.token = AuthData.token;
      response.data.data.description = encodeURIComponent(response.data.data.description)
      setAuthData(response.data.data);
      Setstep(1)
      if (response.data.data.gender_id && response.data.data.gender_id == gender) {
        setGender(response.data.data.gender_id)
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      })
  }

  useEffect(() => {
    setUserCategory("")
  }, [gender])


  // ==== second  page =======
  const [genderError, setGenderError] = useState('');
  const [selectinterestError, setSelectinterestError] = useState('');
  const [selectmakeanewfriendError, setSelectmakeanewfriendError] = useState('');
  const [categoryError, setCategoryError] = useState('');

  const secondPageValidation = () => {
    secondPageErrorclear();
    var isvalid = true
    if (gender == "" || !gender) {
      setGenderError("Please Select Gender");
      isvalid = false;
    }
    if (selectinterest.length === 0) {
      setSelectinterestError("Please at least One Select");
      isvalid = false;
    }
    if (selectmakeanewfriend.length === 0) {
      setSelectmakeanewfriendError("Please at least One Select");
      isvalid = false;
    }
    if (usercategory == "" || !usercategory) {
      setCategoryError("Please Select Category");
      isvalid = false;
    }
    return isvalid;
  }

  const secondPageErrorclear = (e) => {
    setGenderError("");
    setSelectinterestError("");
    setSelectmakeanewfriendError("");
    setCategoryError("");
  };

  const UpdateProfileSecondPageApiCall = () => {
    setisDataLoader(true)
    if (!secondPageValidation()) {
      setisDataLoader(false)
      return false;
    }
    var data = {
      gender_id: gender,
      interest: selectinterest,
      purpose: selectmakeanewfriend.join(', '),
      category: usercategory
    }
    UpdateProfileSecondPageApi(data).then(function (response) {
      setisDataLoader(false)
      response.data.data.token = AuthData.token;
      response.data.data.description = encodeURIComponent(response.data.data.description)
      setAuthData(response.data.data);

      if (isCouple && isCouple.is_couple) {
        Setstep(2)
      } else {
        navigate("/home");
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      })
  }

  const cropImageNow = () => {
    if (!crop.width || !crop.height) {
      return false;
    }

    if (crop.height < 20 || crop.width < 20) {
      Store.addNotification({
        message: "Notification",
        message: "Please select large area",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return false;
    }

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = scaleX * crop.width * pixelRatio;
    canvas.height = scaleY * crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    setDefaultUserCoverImage(URL.createObjectURL(blob))
    SetProfileImg(blob);
    setSrc(null);
  };

  const UpdateProfilePartenerPageApicall = () => {
    setisDataLoader(true)
    setpartnernameError("")
    setPartnerDateOfBirthError("")

    var isvalid = true

    if (partnername == "" || !partnername || partnername.trim() == "") {
      setisDataLoader(false)
      setpartnernameError("Please Enter Partner name");
      isvalid = false;
    }

    if (partnerDateofBirth == "" || !partnerDateofBirth) {
      setisDataLoader(false)
      setPartnerDateOfBirthError("Please Enter Date of Birth");
      isvalid = false;
    } else {
      const currentDate = new Date();
      const selectedDateObj = new Date(partnerDateofBirth);
      currentDate.setHours(0)
      currentDate.setMinutes(0)
      currentDate.setSeconds(0)
      selectedDateObj.setHours(0)
      selectedDateObj.setMinutes(0)
      selectedDateObj.setSeconds(0)

      // Calculate the minimum date of birth for an 18-year-old
      const minDateOfBirth = new Date(currentDate);
      minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - 18);
      minDateOfBirth.setDate(minDateOfBirth.getDate() - 1);

      if (selectedDateObj > minDateOfBirth) {
        setisDataLoader(false)
        // Check if the selected date is at least 18 years ago
        setPartnerDateOfBirthError("Age is must be 18+");
        isvalid = false;
      }
    }

    if (isvalid) {
      var data = {
        partner_name: partnername,
        partner_dob: formatDate(partnerDateofBirth),
      }

      UpdateProfilePartenerPageApi(data).then(function (response) {
      setisDataLoader(false)
        setAuthData(response.data.data);
        navigate("/home");
      })
        .catch(function (error) {
        setisDataLoader(false)
          // handle error
          console.log(error);
        })
    }
  }

  useEffect(() =>{
   if((AuthData.referral_user_code !== null || AuthData.referral_user_code !== "")){
    SetreferralCode("")
   }
  }, [step])

// birthdate last 18 year old conditione 
  
  const currentDate = new Date();
  const minAllowedDate = new Date(currentDate);
  minAllowedDate.setFullYear(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate() - 1);

  return (
    <>
      <Helmet>
        <meta name="description" content={"Welcome On Hukup Australia"} />
      </Helmet>
      {isDataLoader && (
        <DataLoader />
      )}
      <div className="container-fluid">

        {(step === 0) ? (
          <>
            <Row>
              <Col className="text-center mt-5">
                <div className="float-left position-relative back_profileicon">
                </div>
                <span className="profiletext">Profile details</span>
              </Col>
            </Row>
            <div
              className="container pt-4 pt-sm-4 pt-md-5 pt-lg-5 pt-xl-5 set_profiledetails_pages"
            >
              <div className="row mt-3">
                <div className="col-12">
                  <div className="mx-auto position-relative profileimgset">
                    <img alt="Profile" src={defaultUserCoverImage} className="w-100 h-100" />
                    <input
                      type="file"
                      accept="image/*"
                      ref={coverImageRef}
                      onChange={handleCoverChange}
                      className="d-none"
                    />
                    <button
                      className="p-1 position-absolute profile-cover-button"
                      onClick={showOpenCoverFileDialog}
                    >
                      <img alt="edit" src={cameraicon} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 mx-auto">
                  <Form>
                    <div className="row centerinputdetlis justify-content-center">
                      <Col className="mt-4 mt-sm-5" md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Name *"
                        >
                          <Form.Control type="text" placeholder="Name*" value={name} onChange={(e) => { SetName(e.target.value); setNameError("") }} />
                        </FloatingLabel>
                        <span className="errorClass">{nameError}</span>
                      </Col>
                      {/* <Col className="mt-4 mt-sm-5" md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          className="payment_method_floatingLabel"
                          label="Username *"
                        >
                          <Form.Control
                            disabled={AuthData.username ? true : false}
                            type="text"
                            className="no-spinner"
                            placeholder="Username *"
                            value={userName}
                            onChange={(e) => {
                              if (!AuthData.username) {
                                SetUserName(e.target.value);
                                setUserNameError("")
                              }
                            }}
                            onKeyUp={handleUserName}
                          />
                        </FloatingLabel>
                        <span className="errorClass">{userNameError}</span>
                      </Col> */}
                      <Col className="mt-4 mt-sm-5 mx-auto" md={6}>
                        <FloatingLabel controlId="floatingInput"
                          className={AuthData.date_of_birth ? "payment_method_floatingLabel disabled_class" : "payment_method_floatingLabel"}
                          label="Choose birthday date *">
                          <div className={AuthData.date_of_birth ? "form-control disabled_class" : "form-control"} >
                            <DatePicker
                              disabled={AuthData.date_of_birth ? true : false}
                              type='date'
                              showYearDropdown={true}
                              showMonthDropdown={true}
                              dropdownMode={"select"}
                              showIcon
                              icon={
                                <img alt="Calander" src={calendar}></img>
                              }
                              selected={dateOfBirth}
                              onSelect={(date) => {
                                if (!AuthData.date_of_birth) {
                                  SetDateOfBirth(date);
                                  setDateOfBirthError("")
                                }
                              }}
                              onChange={(date) => {
                                if (!AuthData.date_of_birth) {
                                  SetDateOfBirth(date);
                                  setDateOfBirthError("")
                                }
                              }}
                              maxDate={minAllowedDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                            />
                          </div>
                        </FloatingLabel>
                        <span className="errorClass">{dateOfBirthError}</span>
                      </Col>
                      {(AuthData.referral_user_code == null || AuthData.referral_user_code == "") ? (
                      !referral ? (
                      <Col className="mt-4 mt-sm-5" md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Referral Code"
                          >
                          <Form.Control type="text" placeholder="Referral Code" value={referralCode} onChange={(e) => { SetreferralCode(e.target.value) }} />
                        </FloatingLabel>
                      </Col> 
                      )
                      :
                      (
                      <Col className="mt-4 mt-sm-5" md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Referral Code"
                          >
                          <Form.Control type="text" placeholder="Referral Code" value={referralCode} disabled />
                        </FloatingLabel>
                      </Col> 
                      ) 
                      ) : (
                        ""
                      )
                      }
                      {/* {
                        AuthData.login_with == "email" ?
                          <Col className="mt-4 mt-sm-5" md={6}>
                            <FloatingLabel controlId="floatingSelect" className="payment_method_floatingLabel" label="Country code">
                              <Form.Select aria-label="Floating label select example"
                                value={countryId}
                                onChange={(e) => handleCountryId(e)}
                              >
                                <option value="" disabled={countryId ? true : false}>Select country code</option>
                                {countryData.map((e) => {
                                  return (
                                    <option value={e.id}>(+{e.country_code})</option>
                                    // <option value={e.id}>{e.country_name}</option>
                                  )
                                })}
                              </Form.Select>
                            </FloatingLabel>
                            <span className="errorClass">{countryIdError}</span>
                          </Col>
                          : ""
                      } */}
                      {/* {
                        AuthData.is_show_suburb ?
                          <Col className="mt-4 mt-sm-5 " md={6}>
                            <FloatingLabel
                              controlId="floatingInput"
                              className="payment_method_floatingLabel"
                              // label={locplaceholder}
                              label="Suburb*"
                            >
                              <Form.Control
                                type="text"
                                className="no-spinner"
                                placeholder={locplaceholder}
                                placeholder="Suburb*"
                                disabled={countryIdToVerify ? true : false}
                                value={location}
                                onChange={(e) => { SetLocation(e.target.value); setLocationError("") }}
                                list="browsers"
                                id="browser"
                              />
                              <datalist id="browsers">
                                {locationdata.map((item) => {
                                  return (
                                    <option value={item.name} />
                                  )
                                })}
                              </datalist>
                            </FloatingLabel>
                            <span className="errorClass">{locationError}</span>
                          </Col>
                          : ""
                      } */}

                      {/* <Col className="mt-4 mt-sm-5 mx-auto" md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          className="payment_method_floatingLabel"
                          label="Email"
                        >
                          <Form.Control
                            type="email"
                            className="no-spinner"
                            placeholder="Email"
                            value={email} onChange={(e) => { setEmail(e.target.value) }}
                          />
                        </FloatingLabel>
                        <span className="errorClass">{emailError}</span>
                      </Col> */}
                      <div className="col-12 text-center py-3 py-sm-3 py-md-5 py-lg-5 py-xl-5"></div>
                    </div>
                  </Form>
                </div>
              </div>

              <Row>
                <Col className="text-center text-md-end" xs={12}>
                  <button
                    className="confirmbtn"
                    onClick={(e) => UpdateProfileApiCall()}
                  >
                    Confirm
                  </button>
                </Col>
              </Row>
            </div>
          </>
        ) : (<></>)}

        {(step === 1) ? (
          <>
            <Row>
              <Col className="text-center mt-5">
                <div className="profiletext">
                  <div className="position-absolute click_back_profilepage">
                    <MdKeyboardArrowLeft fontSize={42} onClick={(e) => Setstep(step - 1)} />
                  </div>
                  Profile details
                </div>
              </Col>
            </Row>
            <div className="container widthcustom  pt-4 pt-sm-4 pt-md-5 pt-lg-5 pt-xl-5 set_profiledetails_pages">
              <div className="row">
                <Col xs={12} className="text-center">
                  <h5 className="fonthere">Select your gender</h5>
                  <div className="mb-4 mt-3">
                    <div className="d-flex justify-content-center">
                      <div class="form-check mx-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={gender == genderList[0].id}
                          onChange={(e) => { setGender(genderList[0].id); setUserCategory("") }}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          {genderList[0].name}
                        </label>
                      </div>
                      <div class="form-check mx-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={gender == genderList[1].id}
                          onChange={(e) => { setGender(genderList[1].id); setUserCategory("") }}

                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          {genderList[1].name}
                        </label>
                      </div>
                      <div>
                        <Form.Select
                          aria-label="Default select example"
                          className="border-remove borderselct bg-remove pt-0"
                          value={gender}
                          onChange={(e) => { setGender(e.target.value); setUserCategory("") }}
                        >
                          <option value="" disabled={gender < 3 ? false : true} > Other </option>
                          {genderList.map((e, index) => {
                            if (index < 2) {
                              return false;
                            } else {
                              return (
                                <option key={index} value={e.id}>
                                  {e.name}
                                </option>
                              );
                            }
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <span className="errorClass">{genderError}</span>
                  </div>
                  <div>
                    <h5 className="fonthere">Select your Category</h5>
                    <div className="mb-4 mt-3">
                      <div className="d-flex flex-wrap justify-content-center">
                        {category.filter((item) => item.gender_id == gender).map((e) => {
                          return (
                            <div class="form-check mx-2" key={e.id}>
                              <input
                                class="form-check-input"
                                type="radio"
                                name="e"
                                id={e.name}
                                value={usercategory}
                                checked={parseInt(usercategory) == e.id}
                                onChange={(ele) => setUserCategory(e.id)}
                              />
                              <label class="form-check-label" for={e.name}>
                                {e.name}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                      <span className="errorClass">{categoryError}</span>
                    </div>
                    <h5 className="fonthere">Your interest in</h5>
                    <div className="mb-4 mt-3">
                      <div className="d-flex justify-content-center">
                        <Col
                          xs={12}
                          className="d-block d-md-flex p-0 justify-content-center "
                        >
                          <Row className="justify-content-center">
                            {interest.map((item) => {
                              return (
                                <div
                                  className={
                                    selectinterest?.includes(item.id)
                                      ? "borderclass mx-1 my-1 cursor-pointer selecteditem"
                                      : "borderclass mx-1 my-1 cursor-pointer unselecteditem"
                                  }
                                  onClick={(e) => interestClick(item.id)}
                                >
                                  <div className="d-flex align-items-center">
                                    {selectinterest?.includes(item.id) ? (
                                      <img alt="Interest" className="iconintrest" src={item.image} />
                                    ) : (
                                      <img alt="Interest" className="iconintrest" src={item.image} />
                                    )}
                                    <div className="mx-1 mr-2">{item.name}</div>
                                  </div>
                                </div>
                              );
                            })}
                          </Row>
                        </Col>
                      </div>
                      <span className="errorClass">{selectinterestError}</span>
                    </div>
                  </div>

                  <div>
                    <h5 className="fonthere">You are here for</h5>
                    <div className="mb-3 mt-3">
                      <div className="d-flex justify-content-center">
                        <Col
                          xs={12}
                          className="d-flex p-0 justify-content-center"
                        >
                          <Row className="justify-content-center">
                            {makeanewfriend.map((item) => {
                              return (
                                <div
                                  className={
                                    selectmakeanewfriend?.includes(item.id.toString())
                                      ? "borderclass mx-1 my-1 cursor-pointer selecteditem"
                                      : "borderclass mx-1 my-1 cursor-pointer unselecteditem"
                                  }
                                  onClick={(e) => makeanewfriendClick(item.id)}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="mx-1 mr-2">{item.name}</div>
                                  </div>
                                </div>
                              );
                            })}
                          </Row>
                        </Col>
                      </div>
                      <span className="errorClass">{selectmakeanewfriendError}</span>
                    </div>
                  </div>
                </Col>
              </div>

              <Row>
                <Col className="text-center text-md-end mb-4" xs={12}>

                  {(isCouple && isCouple.is_couple) ? <button className="confirmbtn" onClick={() => UpdateProfileSecondPageApiCall()}>{"Next"}</button> : <button className="confirmbtn" onClick={() => UpdateProfileSecondPageApiCall()}>{"Finish"}</button>}

                </Col>
              </Row>
            </div>
          </>
        ) : (<></>)}

        {(step === 2) ? (
          <>
            <Row>
              <Col className="text-center mt-5">
                <div className="profiletext">
                  <div className="position-absolute click_back_profilepage">
                    <MdKeyboardArrowLeft fontSize={42} onClick={(e) => Setstep(step - 1)} />
                  </div>
                  Partner details
                </div>
              </Col>
            </Row>
            <div className="container pt-4 pt-sm-4 pt-md-5 pt-lg-5 pt-xl-5 set_profiledetails_pages">
              <div className="row ">
                <div className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 mx-auto">
                  <Form>
                    <div className="row centerinputdetlis">
                      <Col className="mt-4 mt-sm-5" md={6}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Partner name *"
                        >
                          <Form.Control type="text" placeholder="Partner name*" value={partnername} onChange={(e) => { setPartnername(e.target.value) }} />
                        </FloatingLabel>
                        <span className="errorClass">{partnernameError}</span>
                      </Col>

                      <Col className="mt-4 mt-sm-5" md={6}>
                        <FloatingLabel controlId="floatingInput"
                          className={AuthData.partner_details?.partner_dob ? "payment_method_floatingLabel disabled_class" : "payment_method_floatingLabel"}
                          label="Choose partner birthday date *">
                          <div className={AuthData.partner_details?.partner_dob ? "form-control disabled_class" : "form-control"}>
                            <DatePicker
                              disabled={AuthData.partner_details?.partner_dob ? true : false}
                              type='date'
                              dropdownMode={true}
                              showIcon
                              icon={
                                <img alt="Calander" src={calendar}></img>
                              }
                              selected={partnerDateofBirth}
                              onSelect={(date) => {
                                if (!AuthData.partner_details?.partner_dob) {
                                  setPartnerDateofBirth(date);
                                }
                              }}
                              onChange={(date) => {
                                if (!AuthData.partner_details?.partner_dob) {
                                  setPartnerDateofBirth(date);
                                }
                              }}
                              maxDate={minAllowedDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                            />
                          </div>
                        </FloatingLabel>
                        <span className="errorClass">{partnerDateOfBirthError}</span>

                      </Col>
                      <div className="col-12 text-center py-3 py-sm-3 py-md-5 py-lg-5 py-xl-5"></div>
                    </div>
                  </Form>
                </div>
              </div>

              <Row>
                <Col className="text-center text-md-end" xs={12}>
                  <button className="confirmbtn" onClick={() => UpdateProfilePartenerPageApicall()} >
                    Finish
                  </button>
                </Col>
              </Row>
            </div>
          </>
        ) : (<></>)}

        <img alt="Ellipse12" className="position-absolute ellipse12set" src={Ellipse12} />
        <img alt="Ellipse12" className="position-absolute ellipse13set" src={Ellipse13} />
        <img alt="Ellipse12" className="position-absolute ellipse14set" src={Ellipse14} />
        <img alt="Ellipse12" className="position-absolute ellipse4set" src={Ellipse4} />
        <img alt="Ellipse12" className="position-absolute ellipse5set" src={Ellipse5} />
        <img alt="Ellipse12" className="position-absolute group15set" src={Group15} />
        <img alt="Ellipse12" className="position-absolute group5set" src={Group5} />
        <img alt="Ellipse12" className="position-absolute group6set" src={Group6} />
      </div>
      <CropImageModal
        src={src}
        setSrc={setSrc}
        crop={crop}
        setCrop={setCrop}
        image={image}
        setImage={setImage}
        cropImageNow={cropImageNow}
      />
    </>
  );
};

export default Profiledetails;
