import React from 'react'
import defaultImg from "../../images/user-profile-default-image.png";
import { useAuth } from '../../middleware/AuthProvider';

const Userdetailcard = () => {

    const { AuthData } = useAuth();

    return (
        <>
            <div className="d-flex flex-column align-items-center position-relative">
                <img
                    src={require("../../images/pinkhiIcon.png")}
                    alt="pinkhiIcon"
                    className="content_pink_icon"
                />
                <img
                    src={require("../../images/blackline.png")}
                    blackline
                    className="content_blackline"
                />
                <div className="content_user_img">
                    <img
                        src={AuthData.thumbnail_image ? AuthData.thumbnail_image : defaultImg}
                        className="img-fluid"
                        alt="Profile Img"
                    />
                </div>
                <p className="m-0 welcome_name">
                    <b>{AuthData.name}</b> Welcome Back &#128075;
                </p>
                <h2 className="meet_name">
                    <span className="Meet_the_highlight">Meet the </span>Chosen
                    one
                </h2>
                <img
                    src={require("../../images/meetnameblackline.png")}
                    alt="meetnameblackline"
                    className="meetnameblackline"
                />
            </div>
        </>
    )
}

export default Userdetailcard