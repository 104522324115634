import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import Bellsetting from "../../../images/Setting/Bellsetting.png";
import LockSimple from "../../../images/Setting/LockSimple.png";
import Flag from "../../../images/Setting/Flag.png";
import Trash from "../../../images/Setting/Trash.png";
import Logout from "../../../images/Setting/Logout.png";
import DeleteAccModal from "./DeleteAccModal";
import LogOutModal from "./LogOutModal";
import { useAuth } from '../../../middleware/AuthProvider';
import MembershipModel from '../../CommonComponet/MembershipModel';
import Vector from "../../../images/Subscriptionplan/Vector.png";

const AccountSettingsidebar = () => {

  const { AuthData } = useAuth();
  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [logoutmodalShow, setLogoutModalShow] = useState(false);
  const [membershipModelShow, setMembershipModelShow] = useState(false);

  const showPlanModal = () => {
    setMembershipModelShow(true)
  }

  return (
    <>

      <div className="text-center my-5">
        <h1 className="Settingnotifications_Setting_title">
          Setting
        </h1>
      </div>
      <div className=" d-flex align-items-center mb-4">
        <div className="Settingnotifications_icon_main_section">
          <img
            alt='Bell'
            src={Bellsetting}
            className="Settingnotifications_icon"
          />
        </div>
        <span className="ml-4 ">
          <NavLink
            to="/accountsetting"
            className={({ isActive }) =>
              isActive
                ? "active_notifiction"
                : " Setting_sidebar_menu_Privacy_to_Report"
            }
          >
            Notifications
          </NavLink>
        </span>
      </div>
      <div className=" d-flex align-items-center mb-4">
        <div className="Settingnotifications_icon_main_section">
          <img
            alt='Lock'
            src={LockSimple}
            className="Settingnotifications_icon"
          />
        </div>

        {
          AuthData.is_premium ?
            <span className="ml-4">
              <NavLink
                to="/privacy"
                className={({ isActive }) =>
                  isActive
                    ? " active_notifiction"
                    : " Setting_sidebar_menu_Privacy_to_Report"
                }
              >
                Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" />
              </NavLink>
            </span>
            :
            <span className="ml-4 Setting_sidebar_menu_Privacy_to_Report cursor_pointer_common" onClick={() => showPlanModal()}>
              Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" />
            </span>
        }


      </div>
      <div className=" d-flex align-items-center mb-4">
        <div className="Settingnotifications_icon_main_section">
          <img alt='Flag' src={Flag} className="Settingnotifications_icon" />
        </div>
        <span className="ml-4 ">
          <NavLink
            to="/reportproblem"
            className={({ isActive }) =>
              isActive
                ? " active_notifiction"
                : " Setting_sidebar_menu_Privacy_to_Report"
            }
          >
            Report a Problem
          </NavLink>
        </span>
      </div>
      <div className=" d-flex align-items-center mb-4">
        <div className="Settingnotifications_icon_main_section">
          <img alt='Trash' src={Trash} className="Settingnotifications_icon" />
        </div>
        <span
          className="ml-4 "
          onClick={() => setDeleteModalShow(true)}
        >
          <NavLink className="text Setting_sidebar_menu_Delete_Logout  ">
            Delete Account
          </NavLink>
        </span>
      </div>
      <div className=" d-flex align-items-center mb-4">
        <div className="Settingnotifications_icon_main_section">
          <img alt='Logout' src={Logout} className="Settingnotifications_icon" />
        </div>
        <span
          className="ml-4"
          onClick={() => setLogoutModalShow(true)}
        >
          <NavLink className="text Setting_sidebar_menu_Delete_Logout  ">
            Log out
          </NavLink>
        </span>
      </div>
      <DeleteAccModal
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <LogOutModal
        show={logoutmodalShow}
        onHide={() => setLogoutModalShow(false)}
      />

      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />
    </>
  )
}

export default AccountSettingsidebar