import React, { createContext, useContext, useEffect, useState } from "react";
import ScreenLoader from "../../components/ScreenLoader";
import { MyProfileData, siteSettingAPI } from "../../api/Api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [AuthData, setAuthData] = useState(null);
  const [siteSetting, setSiteSetting] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [footerList, setFooterList] = useState([]);

  useEffect(() => {
    async function loadCookies() {
  
      siteSettingAPI().then((response) => {
        setSiteSetting(response.data.data);
      }).catch(() => {
        setSiteSetting({});
      })
  
      const Token = localStorage.getItem("hukup-info");
      
      if (Token) {
        try {
          MyProfileData().then((response) => {
            var userData = JSON.parse(atob(Token));
            response.data.data.token = userData.token;
            response.data.data.ip = response.data.ip;
            setAuthData(response.data.data);
            setIsLoading(false);
          }).catch(() => {
            setIsLoading(false);
            setAuthData(null);
          })
        } catch (error) {
          setIsLoading(false);
          setAuthData(null);
        }
      } else {
        setIsLoading(false);
        setAuthData(null);
      }
    }
    loadCookies();
    return () => {
      setAuthData(null);
      setIsLoading(false);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        siteSetting,
        isAuthenticated: !!AuthData,
        AuthData,
        setAuthData,
        isLoading,
        membershipPlans,
        setMembershipPlans,
        footerList,
        setFooterList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const Loader = ({ children }) => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <ScreenLoader />;
  }

  return children;
};

export const useAuth = () => useContext(AuthContext);
