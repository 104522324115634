import React from "react";
import Footer from "../CommonComponet/Footer";
import { useAuth } from "../../middleware/AuthProvider";
import Header from "../CommonComponet/Header";
import HeaderSecond from "../CommonComponet/HeaderSecond";
import FooterSecond from "../CommonComponet/FooterSecond";

const EmailVerificationSuccess = () => {
  const { isAuthenticated, siteSetting } = useAuth();
  return (
    <>
      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        {
          isAuthenticated ? (
            <Header />
          ): (<HeaderSecond />)
        }

        <div className="container content_main">
          <div className="row">
            <div className="col-12 Blocked_User_section_contain">
              <div className="">
                <div className="mt-5">
                <p>Dear User,</p>

                <p>Congratulations! Your email has been successfully verified, and your account is now fully activated. Thank you for taking the time to verify your email address.</p>

                <p>With your email now verified, you can enjoy all the updates about all activity of our platform</p>

                <p>If you have any questions or need assistance, feel free to reach out to our support team at <a href={"https://gmail.com"}>{siteSetting?.siteSetting?.support_email}</a></p>

                <p>Thank you for your understanding.</p>

                <p>Best regards,</p>
                <p>{siteSetting?.siteSetting?.site_name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
        isAuthenticated ? (
        <Footer />
        ) : (<FooterSecond />)
      }
      </div>
    </>
  );
};

export default EmailVerificationSuccess;
