import React, { useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import "../../css/BlockedUser.css";
import { BlockAndUnblockUser, BlockUserList } from "../../api/Api";
import { useEffect } from "react";
import defaultImg from "../../images/user-profile-default-image.png";
import { useAuth } from '../../middleware/AuthProvider'
import DataLoader from "../../components/ScreenLoader/DataLoader";
import { Store } from 'react-notifications-component';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import Userdetailcard from "../CommonComponet/Userdetailcard";


const BlockedUser = () => {
  const { AuthData, setAuthData } = useAuth();
  const [isDataLoader, setisDataLoader] = useState(false);
  const [blockUserData, setBlockUserData] = useState([]);
  const [blockUserDataCount, setBlockUserDataCount] = useState([]);
  const [cardSkeleton, setCardSkeleton] = useState(true);

  useEffect(() => {
    BlockUserListApi();
    document.title = "Blocked Users On Hukup Australia"

  }, []);

  const BlockUserListApi = () => {
    BlockUserList()
      .then(function (response) {
        setBlockUserData(response.data.data);
        setBlockUserDataCount(response.data.totalBlocked);
        setCardSkeleton(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const UnblockApi = (id) => {
    setisDataLoader(true)
    var data = {
      blocked_user_id: id
    }
    BlockAndUnblockUser(data).then(function (response) {
      setisDataLoader(false)
      if (response.data.status) {
        Store.addNotification({
          // title: response.data.message,
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setBlockUserData(blockUserData.filter((e) => e.blocked_user_id !== id))
        setBlockUserDataCount(blockUserDataCount - 1)
      }
      else {
        Store.addNotification({
          // title: response.data.message,
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    }).catch(function (error) {
      setisDataLoader(false)
      console.log(error);
    });
  }

  return (
    <>

      {isDataLoader && (
        <DataLoader />
      )}
      <Helmet>
        <meta name="description" content={"Blocked Users On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              {/* ====== User Name And User Image section ======== */}
              <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                <Userdetailcard /> 
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 Blocked_User_section_contain">
                <div className="row">
                  <div className="col-12 col-lg-11 col-xl-10 text-center Blocked_User_section_title">
                    <h1 className="Blocked_User_title">
                      Blocked User{" "}
                      <span className="Blocked_User_Data_Count">
                        {blockUserDataCount}
                      </span>
                    </h1>
                  </div>
                  <div className="col-11 col-md-12 col-lg-11 col-xl-10 mx-auto Blocked_User_section_slider px-0">
                    {cardSkeleton && (
                      <SkeletonTheme >
                        <div className=''>
                          <Skeleton height={50} count={10} className="mb-2" />
                        </div>
                      </SkeletonTheme>
                    )}
                    {blockUserData?.map((items) => {
                      return (
                        <div className="row mb-2 py-2 Blocked_User_main_row mx-0">
                          <div className="col-12 d-flex ">
                            <div className="Blocked_User_slider_first_section">
                              <div className="Blocked_User_img_section">
                                <img
                                  src={items.thumbnail_image}
                                  className="Blocked_User_img"
                                />
                              </div>
                            </div>
                            <div className="Blocked_User_slider_second_section d-flex">
                              <div className="Blocked_User_slider_name_city_section pl-2 pl-md-0">
                                <span className="pl-0  pl-md-3 pl-lg-3 pl-xl-3 Blocked_User_slider_name">
                                  {items.name}
                                </span>
                                <span className="pl-0 pl-md-3 pl-lg-3 pl-xl-3 Blocked_User_slider_city">
                                  {items.location}
                                </span>
                              </div>
                              <div className="Blocked_User_slider_button_section pr-0  pr-md-5 pr-lg-4 pr-xl-5">
                                <button className="Blocked_User_slider_button" onClick={(e) => UnblockApi(items.blocked_user_id)}>
                                  Unblock
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlockedUser;
