import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import "./Header.css"
import "../../css/OfferModal.css"
// import "../../css/Notificationspage.css"
import user from "../../images/logo.png"
import { useAuth } from '../../middleware/AuthProvider';
import { Button } from 'react-bootstrap';
import "./HeaderMobile.css"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const HeaderSecond = (props) => {

    const { siteSetting } = useAuth();
    const navigate = useNavigate();

    return (
        <>
            {/* ------------------ with out login header ---------------- */}
            <div className="container-fluid shadow-sm header_main position-relative px-0">
                <div className="h-100 d-block d-lg-none">
                    <div className="h-100  header_mobile_tab_main px-3 ">
                        <div className={props.loginbtnremove == "loginbtnremove" ? 'header_mobile_tab_logo_section_loginbtnremove' : 'header_mobile_tab_logo_section'}>
                            {siteSetting?.siteSetting?.site_logo ?
                                <img src={siteSetting?.siteSetting?.site_logo} className="sidebar_icon"
                                    onClick={() => navigate("/")}
                                    alt="Hukup Australia Logo" />
                                :
                                <SkeletonTheme>
                                    <Skeleton count={1} height={50} width={200} />
                                </SkeletonTheme>
                            }
                        </div>
                        <div className={props.loginbtnremove == "loginbtnremove" ? 'd-none' : 'header_mobile_tab_btn_section'}>
                            <div className="hedSeco_login_btn_main">
                                <Button className="login_btn" onClick={() => navigate("/")}>Login</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={props.loginbtnremove == "loginbtnremove" ? "d-none d-lg-block h-100  shadow-sm bg-white" : "d-none d-lg-block h-100  shadow-sm"}>
                    <Navbar collapseOnSelect expand="lg" className="h-100">
                        <div className={props.loginbtnremove == "loginbtnremove" ? "container-lg d-flex justify-content-center" : "container-lg"}>

                            <Navbar.Brand as={Link} to="/" className="ml-2 ml-sm-0 ml-md-3 ml-lg-5 ml-xl-0 py-0 py-sm-0 py-md-0 py-lg-auto py-xl-auto logoset" >
                                {siteSetting?.siteSetting?.site_logo ?
                                    <img src={siteSetting?.siteSetting?.site_logo} className="sidebar_icon" alt="Hukup Australia Logo" />
                                    :
                                    <SkeletonTheme>
                                        <Skeleton count={1} height={50} width={200} />
                                    </SkeletonTheme>
                                }
                            </Navbar.Brand>
                            <div className={props.loginbtnremove == "loginbtnremove" ? 'd-none' : "hedSeco_login_btn_main"}>
                                <Button className="login_btn" onClick={() => navigate("/")} >Login</Button>
                            </div>
                        </div>
                    </Navbar>
                </div>
            </div>

        </>
    )
}

export default HeaderSecond