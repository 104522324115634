import { Col, Container, Form, Row } from "react-bootstrap";
import "../Messages/Messages.css";
import Footer from "../../CommonComponet/Footer";
import Header from "../../CommonComponet/Header";
import { CiSearch } from "react-icons/ci";
import { HiDotsVertical } from "react-icons/hi";
import { GoDotFill } from "react-icons/go";
import { BsCheck2All, BsEmojiSmile } from "react-icons/bs";
import { MdRemoveRedEye } from "react-icons/md";
import sendmsg from "../../../images/Vector 1.svg";
import { TbSticker } from "react-icons/tb";
import { useCallback, useRef, useState } from "react";
import { IoChevronBackSharp, IoDocument, IoImageOutline } from "react-icons/io5";
import { MdDelete, MdOutlineEdit, MdVideoLibrary } from "react-icons/md";
import { FaLocationDot, FaHeadphones, FaDownload, FaCheck, FaXmark } from "react-icons/fa6";
import { Button, Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "../../../middleware/AuthProvider";
import { useSocket } from "../../../middleware/SocketProvider";
import { useEffect } from "react";
import MessageUserCard from "./MessageUserCard";
import errorImg from "../../../images/nophoto.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AllTypeFileUploadApi, BlockAndUnblockUser, DropdownListReport, GoingForDateApi, ReportUser, AcceptRejectApi, AcceptRejectGalleryApi, PreMessageAddApi, PreMessageViewApi, PreMessageDeleteApi, PreMessageEditApi, giftListApi, ChatDeleteApi, AccessUserPrivateGallary, addUserGalleryApi, UserPrivateGallery } from "../../../api/Api";
import GoogleMapReact from 'google-map-react';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Picker from "emoji-picker-react";
import { LuMessagesSquare, LuSticker } from "react-icons/lu";
import DataLoader from "../../../components/ScreenLoader/DataLoader";
import MembershipModel from "../../CommonComponet/MembershipModel";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import nophoto from "../../../images/nophoto.png";
import peacefulnatural from "../../../images/peaceful_natural.mp4";
import usersetaileslocation from "../../../images/UserDetailspage/usersetaileslocation.png"
import { AiOutlineClose } from "react-icons/ai";
import offLocation from "../../../images/off_location.png"
import { Helmet } from "react-helmet";
import CropImageModal from "../../CommonComponet/CropImageModal";


function Messages() {
  const { Socket } = useSocket();
  const [searchParams, setSearchParams] = useSearchParams();
  const [chatfilter, setChatfilter] = useState('all')
  const [isDataLoader, setisDataLoader] = useState(false);
  const [showUserChat, setShowUserChat] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  const [preTypeModalShow, setPreTypeModalShow] = useState(false);
  const [stickerModalShow, SetStickerModalShow] = useState(false);
  const [blockModalShow, setBlockModalShow] = useState(false);
  const [goingForDate, setGoingForDate] = useState(false);
  const { AuthData } = useAuth();
  const [searchUser, setSearchUser] = useState("");
  const [chatList, setChatList] = useState([]);
  const [isChatListOpen, SetIsChatListOpen] = useState(null);
  const [hasMoreRecord, setHasMoreRecord] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [currentRoomData, setCurrentRoomData] = useState({});
  const [chatMessages, setChatMessages] = useState([]);
  const [currentRoomId, setCurrentRoomId] = useState(0);
  const [isOnline, SetOnline] = useState(false)
  const chatListRef = useRef(null);
  const messageListRef = useRef(null);
  const [messageText, setMessageText] = useState("");
  const [location, setLocation] = useState(null);
  const [reportDataList, setReportDataList] = useState([]);
  const [reason, SetReason] = useState("");
  const [selectAccount, setSelectAccount] = useState("");
  const [isBlockAndDelete, setIsBlockAndDelete] = useState(false);

  const [selectAccountError, setSelectAccountError] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [preTypeMessageError, setPreTypeMessageError] = useState('');
  const [preTypeMessage, SetPreTypeMessage] = useState('');
  const [preTypeMessageBtn, SetPreTypeMessageBtn] = useState({ type: 'Add' });
  const [preTypeMessageList, SetPreTypeMessageList] = useState([]);
  const [preTypeEncryptedCount, SetPreTypeEncryptedCount] = useState([]);

  const [giftList, SetGiftList] = useState([]);
  const [topActiveChatLimit, setTopActiveChatLimit] = useState(0);
  const [membershipModelShow, setMembershipModelShow] = useState(false);

  const [showAddMessageBox, SetShowAddMessageBox] = useState(false);

  const [showPicker, setShowPicker] = useState(false);
  const [locationAllowed, setLocationAllowed] = useState(true);
  const [allowlocationemodal, setAllowlocationemodal] = useState(false);

  const [messagequotamodal, setMessagequotamodal] = useState(false);
  const [messageLimitOver, setMessageLimitOver] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [imavideoaccessgallery, setImavideoaccessgallery] = useState(false);


  const [currentTarget, setCurrentTarget] = useState(null);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });
  const [image, setImage] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);

  const [sharegallery, setSharegallery] = useState(false)
  const [privateimageuploaded, setPrivateimageuploaded] = useState(false)


  const inputFocusRef = useRef(null);

  useEffect(() => {
    document.title = "Messaging On Hukup Australia"
  }, [])

  useEffect(() => {
    if (isEditing) {
      inputFocusRef.current?.focus();
    }
  }, [isEditing]);


  const inputRef = useRef(null);
  const onEmojiClick = (event) => {
    const cursorPos = inputRef.current.selectionStart;
    setMessageText((prevInput) => prevInput.substring(0, cursorPos) + event.emoji + prevInput.substring(cursorPos));
    const newCursorPos = cursorPos + event.emoji.length;
    focusInputAndSetCursor(newCursorPos);
  };

  const focusInputAndSetCursor = (cursorPos) => {
    // Focus the input
    inputRef.current.focus();
    // Set the cursor position
    inputRef.current.setSelectionRange(cursorPos, cursorPos);
  };

  const [blockUserModal, setBlockUserModal] = useState(false);

  const dateModelClose = () => setGoingForDate(false);

  const handleBlockUserClose = () => { setBlockUserModal(false) }

  useEffect(() => {
    if (searchParams.get('username')) {
      Socket.emit("room-user-details", { username: searchParams.get('username') });
      Socket.on("room-user-details", (data) => {
        if (data.status) {
          handleCardClick(data.data.room_id);
        }
      });
    }
    sendChatSocketEvent();
    DropdownListReportApi();
    Socket.on("message_limit_over", (data) => {
      if (!data.status) {
        setMessageLimitOver(true)

        Store.addNotification({
          message: data.message,
          // message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    });

    // Get Pre Type Message api 
    PreMessageViewApi()
      .then(function (response) {
        SetPreTypeMessageList(response.data.data)
        SetPreTypeEncryptedCount(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    // ------- gift list get api ------- 
    giftListApi()
      .then(function (response) {
        SetGiftList(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });

    return () => {
      Socket.off("online");
      Socket.off("offline");
      Socket.off("chat-list");
      Socket.off("new-chat-list");
      Socket.off("view-message-response");
      Socket.off("room-user-details");
      Socket.off("chat-message" + currentRoomId);
      Socket.off("message-seen-" + currentRoomId)
      Socket.off("message_limit_over");
      if (currentRoomData) {
        Socket.off("gallery-access-request-chat-" + currentRoomData.member_id)
      }
    };

  }, []);

  //  user online and offline event call ------------
  useEffect(() => {
    Socket.on("online", (data) => {
      if (currentRoomData.member_id == data.userId) {
        SetOnline(true)
      }
    });
    Socket.on("offline", (data) => {
      if (currentRoomData.member_id == data.userId) {
        SetOnline(false)
      }
    });
    return () => {
      Socket.off("online");
      Socket.off("offline");
    };
  }, [currentRoomData])

  //  user go to to other user page -------------------
  const goToUserPage = (username) => {
    if (currentRoomData.member_id != 0) {
      if (username != searchParams.get("username")) {
        searchParams.set("username", username)
        setSearchParams(searchParams);
      }
      navigate("/user/" + username)
    }
  }

  const sendChatSocketEvent = (offset = chatList.length) => {
    if ((!hasMoreRecord || isLoading) && offset) {
      return false;
    }
    setIsLoading(true);
    Socket.off("chat-list");
    Socket.emit("chat-list", { offset: offset, search: searchUser, user: chatfilter });
    Socket.on("chat-list", (data) => {
      setTopActiveChatLimit(data.top_active_chat)
      var userDatas = data.allRoomMember;
      if (offset) {
        setChatList(chatList.concat(userDatas));
      }
      else {
        setChatList(userDatas)
      }
      setIsLoading(false);
      if (!userDatas.length) {
        setHasMoreRecord(false);
      } else {
        setHasMoreRecord(true);
      }
    });

    Socket.off("new-chat-list");
    Socket.on("new-chat-list", (data) => {
      addChatToTop(data);
    });

  }

  useEffect(() => {
    Socket.off("new-chat-list");
    Socket.on("new-chat-list", (data) => {
      addChatToTop(data);
    });
  }, [chatList])

  useEffect(() => {
    sendChatSocketEvent(0)
  }, [searchUser, chatfilter])

  const addChatToTop = (data) => {
    var newChat = data.userDetail;
    var oldChatList = chatList.filter((ele) => {
      return ele.room_id != newChat.room_id
    });
    setChatList([newChat].concat(oldChatList))
  };

  const handleCardClick = (room_id) => {
    Socket.emit("room-user-details", { roomId: room_id });
    Socket.on("room-user-details", (data) => {
      if (data.status) {
        setCurrentRoomId(room_id);
        setChatMessages([]);
        Socket.off("chat-message" + currentRoomId);
        if (currentRoomData) {
          Socket.off("gallery-access-request-chat-" + currentRoomData.member_id);
          Socket.off("date-access-request-chat-" + currentRoomData.room_id);
        }
        Socket.off("room-user-details");
        Socket.emit("message-seen", { room_id });
        setChatList(chatList.map((e) => {
          if(e.room_id == room_id) {
            e.unread_message = 0;
          }
          return e;
        }));
        setIsMessageLoading(true)
        setHasMoreMessages(true)
        setCurrentRoomData(data.data)

        setPrivateimageuploaded(data.data.is_private_image_uploaded);

        setSharegallery(data.data.is_gallery_request_approved)

        setMessageLimitOver(data.data.message_limit_over);
        setMessageText("")
        Socket.on("gallery-access-request-chat-" + data.data.member_id, (details) => {
          setCurrentRoomData({ ...data.data, gallary_access_data: details.data })
        });
        Socket.on("date-access-request-chat-" + data.data.room_id, (details) => {
          setCurrentRoomData({ ...data.data, date_data: details.data })
        });
        SetOnline(data.data.is_online ? true : false)
        Socket.off("view-message-response");
        Socket.emit("view-message", { room_id: room_id, offset: 0 });
        Socket.on("view-message-response", (messageData) => {
          var newMessages = messageData.data.reverse();
          setChatMessages(newMessages)
          setTimeout(() => {
            scrollToLastMessage();
          }, 1);
          setIsMessageLoading(false)
        });

        SetIsChatListOpen(room_id);
        if (window.innerWidth < 992) {
          setShowUserChat(true);
        }
      }
    });

  };

  const handleMessageScroll = useCallback(() => {
    if (
      messageListRef.current &&
      messageListRef.current.scrollTop < 50 &&
      !isMessageLoading &&
      hasMoreMessages
    ) {
      const lastPosition = messageListRef?.current?.scrollHeight;
      setIsMessageLoading(true)
      Socket.off("view-message-response")
      Socket.emit("view-message", { room_id: currentRoomId, offset: chatMessages.length });
      Socket.on("view-message-response", (data) => {
        if (!data.data.length) {
          setHasMoreMessages(false)
        }
        else {
          setHasMoreMessages(true)
        }
        var newMessages = data.data.reverse();
        setChatMessages(newMessages.concat(chatMessages))
        setIsMessageLoading(false)
        messageListRef.current.scrollTop = messageListRef?.currentautoFocusSearch?.scrollHeight - lastPosition;
      });
    }
  }, [isMessageLoading, chatMessages, currentRoomId, hasMoreMessages]);

  useEffect(() => {
    if (messageListRef.current) {
      if (currentRoomId) {
        if (messageListRef.current) {
          messageListRef.current.addEventListener("scroll", handleMessageScroll);
        }
        return () => {
          if (messageListRef.current) {
            messageListRef.current.removeEventListener("scroll", handleMessageScroll);
          }
        };
      }
    }
  }, [handleMessageScroll]);

  const scrollToLastMessage = () => {
    if (messageListRef.current) {
      messageListRef?.current?.scrollIntoView({ behavior: "smooth" })
      const scrollHeight = messageListRef?.current?.scrollHeight;
      const height = messageListRef?.current?.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messageListRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  useEffect(() => {
    Socket.off("chat-message" + currentRoomId);
    Socket.on("chat-message" + currentRoomId, (data) => {
      if (messageListRef?.current) {
        if (currentRoomId == data.data[0].room_id) {
          var clientHeight = messageListRef.current.clientHeight
          var scrollHeight = messageListRef.current.scrollHeight
          var scrollTop = messageListRef.current.scrollTop
          var scrollLocation = scrollHeight - scrollTop;
          setChatMessages(chatMessages.concat(data.data))
          Socket.emit("message-seen", { room_id: currentRoomId });
          if (data.data[0].sender_id == AuthData.id) {
            scrollToLastMessage();
          }
          else {
            if ((scrollLocation - clientHeight) < (clientHeight - 100)) {
              scrollToLastMessage();
            }
          }
        }
      }
    });
  }, [chatMessages, currentRoomId])

  const userBackChat = () => {
    Socket.off("online");
    Socket.off("offline");
    Socket.off("view-message-response");
    Socket.off("room-user-details");
    if (currentRoomData) {
      Socket.off("gallery-access-request-chat-" + currentRoomData.member_id);
      Socket.off("date-access-request-chat-" + currentRoomData.room_id);
    }
    Socket.off("chat-message" + currentRoomId);
    Socket.off("message-seen-" + currentRoomId);
    setCurrentRoomId(0)
    SetIsChatListOpen(false)
    setCurrentRoomData({})
    setShowUserChat(false);
  };

  const handleScroll = useCallback(() => {
    if (
      chatListRef.current &&
      chatListRef.current.scrollTop + chatListRef.current.clientHeight >=
      chatListRef.current.scrollHeight - 200 &&
      !isLoading
    ) {
      sendChatSocketEvent();
    }
  }, [isLoading, hasMoreRecord, chatList]);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatListRef.current) {
        chatListRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (privateimageuploaded == false) {
      setSharegallery(true)
    }
  }, [])

  const convertTimestampToTime = (timestamp) => {
    const messageDate = new Date(timestamp);

    const hours = messageDate.getHours();
    const minutes = messageDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTimes = `${formattedHours}:${formattedMinutes} ${ampm}`;

    const todayDate = new Date();
    const formattedMonth = messageDate.getMonth() + 1; // Adjust for zero-based index

    if (todayDate.getFullYear() !== messageDate.getFullYear()) {
      return `${messageDate.getDate()}-${formattedMonth}-${messageDate.getFullYear()}`;
    }
    if (todayDate.getMonth() !== messageDate.getMonth()) {
      return `${messageDate.getDate()}-${formattedMonth}-${messageDate.getFullYear()}`;
    }
    if ((todayDate.getDate() - messageDate.getDate()) > 1) {
      return `${messageDate.getDate()}-${formattedMonth}-${messageDate.getFullYear()}`;
    }
    if ((todayDate.getDate() - messageDate.getDate()) > 0) {
      return `${messageDate.getDate()}-${formattedMonth}-${messageDate.getFullYear()}`;
    }
    return formattedTimes;
  };

  const convertTimestampToTimeMessage = (timestamp) => {
    const messageDate = new Date(timestamp);

    const hours = messageDate.getHours();
    const minutes = messageDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTimes = `${formattedHours}:${formattedMinutes} ${ampm}`;

    const todayDate = new Date();

    if (todayDate.getFullYear() !== messageDate.getFullYear()) {
      return (messageDate.getDate() + "-" + (messageDate.getMonth() + 1) + "-" + messageDate.getFullYear() + " " + formattedTimes);
    }
    if (todayDate.getMonth() !== messageDate.getMonth()) {
      return (messageDate.getDate() + "-" + (messageDate.getMonth() + 1) + "-" + messageDate.getFullYear() + " " + formattedTimes);
    }
    if (todayDate.getDate() - messageDate.getDate() > 1) {
      return (messageDate.getDate() + "-" + (messageDate.getMonth() + 1) + "-" + messageDate.getFullYear() + " " + formattedTimes);
    }
    if (todayDate.getDate() - messageDate.getDate() > 0) {
      return (messageDate.getDate() + "-" + (messageDate.getMonth() + 1) + "-" + messageDate.getFullYear() + " " + formattedTimes);
    }
    return formattedTimes;
  };

  const sendMessage = () => {
    if (messageText.trim() == "" || !messageText) {
      return false
    }
    const messageData = {
      room_id: currentRoomId,
      message_type: 'text',
      message: messageText,
    }
    Socket.emit('chat-message', messageData)
    setMessageText("");
    setShowPicker(false)
  }

  const coverImageRef = useRef();
  const showOpenImageFileDialog = () => {
    coverImageRef.current.click();
  };

  const coverVideoRef = useRef();

  const showOpenVideoFileDialog = () => {
    coverVideoRef.current.click();
  };

  const coverDocumentRef = useRef();
  const showOpenDocumentFileDialog = () => {
    coverDocumentRef.current.click();
  };

  const coverAudioRef = useRef();
  const showOpenAudioFileDialog = () => {
    coverAudioRef.current.click();
  };

  // ----- handle media file to upload in chat room with validtion --------
  const handleMediaUpload = (event, mediaType) => {

    setisDataLoader(true)
    const files = event.target.files


    if (!files.length) {
      setisDataLoader(false)
      return false;
    }

    const formData = new FormData()

    var valid = true

    for (let index = 0; index < files.length; index++) {
      var datafile = files && (files[index].type.split('/')[0] == 'video' || files[index].type.split('/')[0] == 'audio' || files[index].type.split('/')[0] == 'image');
      if (mediaType == 'document' && !datafile) {
        formData.append("image[]", files[index])
        valid = true
      } else {
        if (mediaType == 'video' || mediaType == 'audio' || mediaType == 'image') {
          formData.append("image[]", files[index])
          valid = true
        } else {
          Store.addNotification({
            message: "select only document",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2500,
              onScreen: false
            }
          });
          setisDataLoader(false)
          valid = false
        }
      }
    }

    if (valid) {
      AllTypeFileUploadApi(formData).then(function (response) {
        setisDataLoader(false)
        if (!response.data.status) {
          Store.addNotification({
            message: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          return false;
        }

        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          const messageData = {
            room_id: currentRoomId,
            message_type: element.type,
            message: element.url,
          }
          Socket.emit('chat-message', messageData)
        }

        event.target.value = "";

      })
        .catch(function (error) {
          setisDataLoader(false)
          // handle error
          console.log(error);
        })
    }

  }

  // -- geolocation to get lat and long ------
  useEffect(() => {
    // Check if the browser supports geolocation
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationAllowed(true)
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          setLocationAllowed(false)
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      setLocationAllowed(false)
      console.error('Geolocation is not supported in this browser');
    }

  }, []);

  // ---  share location function on chat room --------
  const handleShareLocation = () => {

    if (location) {
      // You can customize how you want to share the location here
      const shareLink = `https://www.google.com/maps?q=${location.latitude},${location.longitude}`;
      const messageData = {
        room_id: currentRoomId,
        message_type: "location",
        message: `${location.latitude},${location.longitude}`,
      }
      Socket.emit('chat-message', messageData)
    } else {
      console.error('Location not available');
    }
  };

  //  ---- get data api report dropdowon list ------
  const DropdownListReportApi = () => {
    DropdownListReport().then(function (response) {
      setReportDataList(response.data.data)
      setSelectAccount(response.data.data[0]?.report_user_type)
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  // -------- report validation in chat room -------
  const validation = () => {
    Errorclear();
    var isvalid = true
    if (reason == "" || !reason || reason.trim() == "") {
      setReasonError("Please enter reason");
      isvalid = false;
    }
    if (selectAccount == "" || !selectAccount || selectAccount.trim() == "") {
      setSelectAccountError("Please select account");
      isvalid = false;
    }
    return isvalid;
  }

  //  ------- report error clear function -----
  const Errorclear = (e) => {
    setSelectAccountError("");
    setReasonError("");
  };

  const clearValueFun = () => {
    SetReason("");
    setSelectAccount(selectAccount);
    setIsBlockAndDelete(false);
    DropdownListReportApi();
  }

  //  ---- report user data post api call ------
  const ReportUserApi = (id) => {
    setisDataLoader(true)
    if (!validation()) {
      setisDataLoader(false)
      return false;
    }
    var data = {
      reported_user_id: id,
      reason: reason,
      report_from: "chat",
      type: selectAccount,
      block_and_delete_chat: isBlockAndDelete
    };
    ReportUser(data).then(function (response) {
      setisDataLoader(false)
      if (response.data.status) {
        Store.addNotification({
          message: response.data.message,
          // message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        if (isBlockAndDelete) {
          handleBlockUserClose()
          userBackChat();
          setChatList(chatList.filter((ele) => {
            return ele.room_id != currentRoomId
          }))
        }
      } else {
        Store.addNotification({
          message: response.data.message,
          // message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      clearValueFun()
      setReportModalShow(false)
    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }
  const navigate = useNavigate();

  // ----- block use post api call function ------
  const BlockUserApi = (id) => {
    setisDataLoader(true)
    var data = {
      blocked_user_id: id
    }
    BlockAndUnblockUser(data)
      .then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          Store.addNotification({
            message: response.data.message,
            // message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          handleBlockUserClose()
          userBackChat();
          setChatList(chatList.filter((ele) => {
            return ele.room_id != currentRoomId
          }))
        } else {
          Store.addNotification({
            message: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          handleBlockUserClose()
        }
        // navigate("/home")
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  const goingForDateFun = (id) => {
    setisDataLoader(true)
    var data = {
      room_id: id
    }
    GoingForDateApi(data)
      .then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          Store.addNotification({
            message: response.data.message,
            // message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          dateModelClose()
          setCurrentRoomData({ ...currentRoomData, date_data: response.data.data })
        }
        else {
          Store.addNotification({
            message: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          dateModelClose()
        }
        // navigate("/home")
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  // const dateRequestFun = (id, status) => {
  //   setisDataLoader(true)
  //   var data = {
  //     dates_id: id,
  //     status: status
  //   }
  //   AcceptRejectApi(data)
  //     .then(function (response) {
  //       setisDataLoader(false)
  //       if (response.data.status) {
  //         setCurrentRoomData({ ...currentRoomData, date_data: response.data.data })
  //       }
  //       // navigate("/home")
  //     })
  //     .catch(function (error) {
  //       setisDataLoader(false)
  //       console.log(error);
  //     });
  // }

  const galleryRequestFun = (access_user_id, id, status) => {
    setisDataLoader(true)
    var data = {
      access_user_id: access_user_id,
      request_id: id,
      status: status ? "approved" : "rejected"
    }
    AcceptRejectGalleryApi(data)
      .then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          setCurrentRoomData({ ...currentRoomData, gallary_access_data: response.data.data })
        }
        else {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  const sendStickerFun = (image) => {
    const messageData = {
      room_id: currentRoomId,
      message_type: 'sticker',
      message: image,
    }
    Socket.emit('chat-message', messageData);
    SetStickerModalShow(false)
  }

  const SendPreTypeMessage = (message) => {
    const messageData = {
      room_id: currentRoomId,
      message_type: 'text',
      message: message,
    }
    Socket.emit('chat-message', messageData);
    setPreTypeModalShow(false)
  }

  const addMessageFun = () => {
    setisDataLoader(true)
    setPreTypeMessageError("")
    if (preTypeMessage == "" || !preTypeMessage || preTypeMessage.trim() == "") {
      setPreTypeMessageError("Please Enter Message");
      setisDataLoader(false)
      return false
    }
    var data = {
      message: preTypeMessage
    }
    PreMessageAddApi(data)
      .then(function (response) {
        setisDataLoader(false)
        SetPreTypeMessage("")
        if (response.data.status) {
          SetPreTypeMessageList(preTypeMessageList.concat(response.data.data))

        } else {
          Store.addNotification({
            message: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        // navigate("/home")
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  const DeletePriTypeMsg = (id) => {
    setisDataLoader(true)
    PreMessageDeleteApi(id)
      .then(function (response) {
        setisDataLoader(false)
        SetPreTypeMessage("")
        if (response.data.status) {
          SetPreTypeMessageList(preTypeMessageList.filter((ele) => { return ele.id != id }))
        }
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  const EditPriTypeMessage = (id, message) => {
    setPreTypeMessageError("")
    SetPreTypeMessageBtn({
      type: "Edit",
      id: id
    })
    SetPreTypeMessage(message)
    SetShowAddMessageBox(true)
  }

  const UpdatePreTypeMessage = () => {
    setisDataLoader(true)
    if (preTypeMessage == "" || !preTypeMessage || preTypeMessage.trim() == "") {
      setPreTypeMessageError("Please Enter Message");
      setisDataLoader(false)
      return false
    }
    var data = {
      message: preTypeMessage
    }

    PreMessageEditApi(preTypeMessageBtn.id, data)
      .then(function (response) {
        setisDataLoader(false)
        SetPreTypeMessage("")
        if (response.data.status) {
          SetPreTypeMessageList(preTypeMessageList.map((ele) => {
            if (ele.id == preTypeMessageBtn.id) {
              ele.message = preTypeMessage
            }
            return ele
          }))
        }
        SetPreTypeMessageBtn({ type: 'Add' })
        SetShowAddMessageBox(false)
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  const resetPriTypeMessageBox = () => {
    setPreTypeModalShow(false)
    setPreTypeMessageError("")
    SetPreTypeMessageBtn({ type: 'Add' })
    SetShowAddMessageBox(false)
    setisDataLoader(false)
    SetPreTypeMessage("")
    setEditing(false);
  }
  // ----- submit pre type message --------
  const SubmitAddPreTypeMessage = () => {
    if (preTypeMessageBtn.type == "Edit") {
      setPreTypeMessageError("")
      SetPreTypeMessage("")
      SetPreTypeMessageBtn({ type: 'Add' })
    } else {
      setPreTypeMessageError("")
      SetShowAddMessageBox(!showAddMessageBox)
    }
    setEditing(true);
  }

  const ClearChat = (chatId) => {

    var data = {
      room_id: chatId
    };
    ChatDeleteApi(data).then(function (response) {
      if (response.data.status) {
        setChatList(chatList.filter((ele) => {
          return ele.room_id != currentRoomId
        }))
        setChatMessages([])
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Function to handle the onChange event
  const handleChangechatlist = (e) => {
    const newValue = e.target.value;
    setChatfilter(newValue);
  };

  // --------- Calculation the online and offline time ------------
  function DateTimeDisplayMessage(inputDate) {
    const currentDate = new Date();
    const specifiedDateTime = new Date(inputDate);

    // Calculate the time difference in hours and minutes
    const timeDifference = currentDate - specifiedDateTime;
    const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
    const minutesDifference = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));

    const isWithin24Hours = Math.abs(hoursDifference) <= 24;

    if (isWithin24Hours) {

      if (hoursDifference === 0 && minutesDifference === 0) {
        return "Last seen just now";
      } else {
        return hoursDifference === 0
          ? `Last seen ${minutesDifference} minutes ago`
          : `Last seen ${hoursDifference} hours ago`;
      }
    } else {
      // Calculate the difference in days
      const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

      return `Last seen ${daysDifference}  days ago`;
    }
  }

  // ----- calculation the meter and kilometer for chatroom header -----------
  const distance = currentRoomData?.distance;
  const displayValue = distance < 1 ? `${(distance * 1000)} M` : `${distance} KM`;


  const dismessage = () => {
    setAllowlocationemodal(true);
  }

  // ---- text message set limit --------
  const handletextmessages = (e) => {
    if (e.target.value.length > 1000) {
      Store.addNotification({
        message: "Enter only 1000 letters",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: false
        }
      });
    } else {
      setMessageText(e.target.value);
    }
  };

  const GalleryAccessForUser = (id) => {
    var data = {
      userId: id,
    }
    AccessUserPrivateGallary(data).then(function (response) {
      if (response.data.status) {
        setImavideoaccessgallery(false)
        setSharegallery(!sharegallery)
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  //  image and video upload to private gallery 
  const coverImagePrivetRef = useRef();
  const showOpenPrivetCoverFileDialog = () => {
    coverImagePrivetRef.current.click();
  };

  const handleGalleryUpload = (event, is_private) => {
    setImavideoaccessgallery(false)

    const files = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (files?.type.includes("image")) {
      if (!allowedTypes.includes(files?.type)) {
        Store.addNotification({
          message: "Only JPG JPEG and PNG Image allowed",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return;
      }
    }

    setCrop({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });

    if (files.type.includes("image")) {
      setSrc(URL.createObjectURL(files));
      setIsPrivate(is_private)
      event.target.value = null;
    }
    else {
      setisDataLoader(true);
      const formData = new FormData()
      formData.append("image[]", files)
      formData.append("is_private", is_private.toString())
      addUserGalleryApi(formData).then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          if (!sharegallery) {
            GalleryAccessForUser(currentRoomData.member_id)
          }

          if (!privateimageuploaded) {
            setPrivateimageuploaded(true)
          }

          Store.addNotification({
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          return false;
        }

      })
        .catch(function (error) {
          setisDataLoader(false)
          // handle error
          console.log(error);
        })
    }

  }

  const cropImageNow = () => {
    if (!crop.width || !crop.height) {
      return false;
    }

    if (crop.height < 20 || crop.width < 20) {
      Store.addNotification({
        title: "Notification",
        message: "Please select large area",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return false;
    }

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = scaleX * crop.width * pixelRatio;
    canvas.height = scaleY * crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    setSrc(null);
    setisDataLoader(true);
    const formData = new FormData()
    formData.append("image[]", blob, "user-" + AuthData.id + ".png")
    formData.append("is_private", isPrivate.toString())
    addUserGalleryApi(formData).then(function (response) {
      setisDataLoader(false)
      if (response.data.status == true) {

        if (!sharegallery) {
          GalleryAccessForUser(currentRoomData.member_id)
        }
        if (!privateimageuploaded) {
          setPrivateimageuploaded(true)
        }

        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        // handle error
        console.log(error);
      })
  };

  const UploadedNotPrivateGallery = () => {
    Store.addNotification({
      message: "you have not uploaded a private gallery",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__zoomIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: false
      }
    });
  }


  return (
    <>

      <Helmet>
        <meta name="description" content={"Messaging On Hukup Australia"} />
      </Helmet>

      {isDataLoader && (
        <DataLoader />
      )}
      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img
            alt="Whitedot"
            src={require("../../../images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../images/Ellipse.png")}
            className="footer_ellipse_img d-none"
          />
          <img
            alt="footerblackline"
            src={require("../../../images/footerblackline.png")}
            className="footer_blackline_img d-none"
          />
        </div>
        <Header page="message" currentRoom={currentRoomData} />
        <Container className="px-0 px-lg-1">
          <Row className="m-0">
            <Col
              lg={4}
              className={`chat_card p-0 ${showUserChat ? "d-none" : "d-block"}`}
            >
              <Row className="m-0 mt-3">
                <Col
                  lg={2}
                  md={1}
                  xs={2}
                  className="profile_img_col set_image_main_pic "
                >
                  <div className="main_profile_imgcol_padding ">
                    <img
                      alt="Profile"
                      src={AuthData.thumbnail_image}
                      className="profile_img d_screen_image"
                    ></img>
                  </div>
                </Col>
                <Col lg={6} md={7} xs={6} className="user_details pl-xl-0">
                  <p className="mb-0 user_name">{AuthData.name}</p>
                  <p className="mb-0 user_info">{AuthData.username}</p>
                </Col>
                <Col md={4} xs={4} className="profile_img_col justify-content-end">
                  <div className="position-relative">
                    <Form.Group>
                      <select
                        className={AuthData.is_premium ? "form-control match_select_menu" : "form-control match_select_menu search_user_non_premum"}
                        id="match only"
                        name="match_only"
                        value={chatfilter}
                        onChange={handleChangechatlist}
                      >
                        <option value="all">All</option>
                        <option value="match">Matched</option>
                        <option value="online">Online Only</option>
                        <option value="favourite">Favourite</option>
                      </select>
                    </Form.Group>
                    {
                      !AuthData.is_premium ?
                        <div
                          onClick={() => setMembershipModelShow(true)}
                          className="mes_filter_fake"></div>
                        : ""
                    }
                  </div>
                </Col>
                <Col xs={12} className="my-3" onClick={() => !AuthData.is_premium ? setMembershipModelShow(true) : ""}>
                  <span className="icon-feedback ">
                    <CiSearch />
                  </span>
                  <input
                    value={searchUser}
                    name="search"
                    type="text"
                    className={AuthData.is_premium ? "form-control search_user" : "form-control search_user search_user_non_premum"}
                    placeholder="Search for people"
                    onChange={(e) => {
                      if (AuthData.is_premium) {
                        setSearchUser(e.target.value)
                      }
                    }
                    }
                  />
                </Col>

                <div ref={chatListRef} className="chat_user_card">
                  {chatList.map((e, index) => {
                    return (
                      <MessageUserCard
                        key={e.roomId}
                        topActiveChatLimit={topActiveChatLimit}
                        e={e}
                        index={index}
                        currentRoomId={currentRoomId}
                        convertTimestampToTime={convertTimestampToTime}
                        handleCardClick={handleCardClick}
                      />
                    );
                  })}
                </div>
              </Row>
            </Col>
            {isChatListOpen && (
              <Col
                lg={8}
                className={`open_close_col p-0 ${showUserChat ? "d-block" : ""
                  }`}
              >
                <Row className={currentRoomData?.member_id == 0 ? "d-flex align-items-center m-2 user_top_row" : "m-2 user_top_row"}>
                  <Col
                    xs={1}
                    className="d-lg-none moblie_screen_chat_header back_button set_back_click"
                    onClick={userBackChat}
                  >
                    <span>
                      <IoChevronBackSharp />
                    </span>
                  </Col>
                  <Col
                    sm={1}
                    xs={2}
                    className="profile_img_col moblie_screen_chat_header new_add_class"
                  >
                    <img
                      alt="Profile"
                      onClick={() => goToUserPage(currentRoomData.username)}
                      src={currentRoomData.thumbnail_image}
                      className="user_chat_profile_img_chat_side imgset_header"
                      onError={(e) => {
                        e.target.src = errorImg;
                      }}
                    ></img>
                  </Col>
                  <Col md={7} sm={7} xs={8} className={currentRoomData.last_login_date != "" ? "pl-xl-0" : "d-flex pl-xl-0"}>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 profile_user">
                        <span onClick={() => goToUserPage(currentRoomData.username)} className="currentRoom_Data_name">
                          {currentRoomData.name}
                        </span>
                      </p>
                      {(currentRoomData.member_id != 0) ?
                        <>
                          <img alt="Location" className="mb-0 ml-2 ml-md-3 mt-1 chat_location_icon" src={usersetaileslocation} />
                          <p className="mb-0  mt-1 ml-1 hed_dis_chat_count">{displayValue}</p>
                        </> : ""}
                    </div>

                    {currentRoomData?.member_id != 0 && currentRoomData.last_login_date != "" && (
                      (isOnline && AuthData.is_premium && currentRoomData.member_id != 0) ?
                        (<p className="mb-0 user_profile_status">
                          <sapn className="status_dot_online">
                            <GoDotFill />
                          </sapn>
                          Online
                        </p>) : (
                          <p className="mb-0 user_profile_status">
                            {AuthData.is_premium ?
                              DateTimeDisplayMessage(currentRoomData.last_activity_date)
                              :
                              (<>
                                <sapn className="status_dot_offline">
                                  <GoDotFill />
                                </sapn>
                                <span className="mr-1 off_on_premium"
                                  onClick={() => setMembershipModelShow(true)}>
                                  Upgrade
                                </span>
                              </>)
                            }
                          </p>
                        )
                    )}
                  </Col>
                  {currentRoomData.member_id != 0 && (
                    <Col
                      lg={4}
                      md={3}
                      sm={2}
                      xs={1}
                      className="top_option moblie_screen_chat_header"
                    >
                      {
                        currentRoomData.date_data == null ?
                          <button
                            className="top_date_button not_show_button"
                            onClick={() => setGoingForDate(true)}
                          >
                            Going On Date?
                          </button>
                          : <button
                            className="top_date_button not_show_button"
                            onClick={() => setGoingForDate(true)}
                          >
                            Going On Date?
                          </button>

                      }
                      <Dropdown className="custom p-0">
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="mx-xl-3 mx-2">
                            <HiDotsVertical />
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-0">
                          <Dropdown.Item className="text-center border-bottom" onClick={() => setReportModalShow(true)}>
                            Report User
                          </Dropdown.Item>
                          <Dropdown.Item className="text-center border-bottom" onClick={() => setBlockUserModal(true)}>
                            Block User
                          </Dropdown.Item>
                          {(chatMessages.length > 0) && (
                            <Dropdown.Item className="text-center" onClick={(e) => {
                              e.preventDefault()
                              ClearChat(currentRoomId)
                            }
                            }>
                              Clear Chat
                            </Dropdown.Item>
                          )}
                          {
                            currentRoomData.date_data == null ?
                              <Dropdown.Item
                                className="show_menu border-top"
                                onClick={() => setGoingForDate(true)}
                              >
                                Going On Date?
                              </Dropdown.Item>
                              : ""
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  )}
                </Row>
                <Row className="m-0">
                  <Col xs={12} className="message_user">
                    <div className="all_user_chat_section" ref={messageListRef}>
                      <div className="date_section">
                        {/* {
                          (currentRoomData.date_data?.user_id == currentRoomData.member_id) && (
                            <Row>
                              <Col xs={12} className="d-flex justify-content-center">
                                {
                                  currentRoomData.date_data?.status == "pending" && (
                                    <p className="dates_text">{currentRoomData.name + " Request for date"}
                                      <span>
                                        <FaCheck className="ms-md-5 me-md-1 ms-sm-2 me-sm-1 dateIcon" onClick={() => dateRequestFun(currentRoomData.date_data.id, true)} />
                                        <FaXmark className="mx-1 dateIcon" onClick={() => dateRequestFun(currentRoomData.date_data.id, false)} />
                                      </span>
                                    </p>
                                  )
                                }
                                {
                                  currentRoomData.date_data?.status == "accept" && (
                                    <p className="dates_text">You accept date request. </p>
                                  )
                                }
                                {
                                  currentRoomData.date_data?.status == "reject" && (
                                    <p className="dates_text">You rejected date request.</p>
                                  )
                                }
                              </Col>
                            </Row>
                          )
                        }
                        {
                          (currentRoomData.date_data?.user_id != currentRoomData.member_id) && (
                            <Row>
                              <Col xs={12} className="d-flex justify-content-center">
                                {
                                  currentRoomData.date_data?.status == "pending" && (
                                    <p className="dates_text">Your date request is pending. </p>
                                  )
                                }
                                {
                                  currentRoomData.date_data?.status == "accept" && (
                                    <p className="dates_text">Your date request is accepted. </p>
                                  )
                                }
                                {
                                  currentRoomData.date_data?.status == "reject" && (
                                    <p className="dates_text">Your date request is rejected.</p>
                                  )
                                }
                              </Col>
                            </Row>
                          )
                        } */}
                        {
                          (currentRoomData.gallary_access_data?.user_id == currentRoomData.member_id) && (
                            <Row>
                              <Col xs={12} className="d-flex justify-content-center">
                                {
                                  currentRoomData.gallary_access_data?.status == "pending" && (
                                    <p className="dates_text">{currentRoomData.name + " Request for Gallery access"}
                                      <span>
                                        <FaCheck className="ms-md-5 me-md-1 ms-sm-2 me-sm-1 dateIcon" onClick={() => galleryRequestFun(currentRoomData.gallary_access_data.user_id, currentRoomData.gallary_access_data.id, true)} />
                                        <FaXmark className="mx-1 dateIcon" onClick={() => galleryRequestFun(currentRoomData.gallary_access_data.user_id, currentRoomData.gallary_access_data.id, false)} />
                                      </span>
                                    </p>
                                  )
                                }
                              </Col>
                            </Row>
                          )
                        }
                      </div>

                      {chatMessages?.map((e) => {
                        return (
                          <MessagesBox
                            currentRoomData={currentRoomData}
                            convertTimestampToTimeMessage={convertTimestampToTimeMessage}
                            message={e}
                            profileImage={currentRoomData.thumbnail_image}
                            goToUserPage={goToUserPage}
                          />
                        )
                      })}

                    </div>

                    {currentRoomData.member_id != 0 && (
                      <Row className=" ">
                        <Col xs={12} className="d-flex position-relative">
                          <div className="w-100">
                            <div className="new_position">
                              {showPicker && (
                                <Picker
                                  className="emoji-box"
                                  onEmojiClick={onEmojiClick}
                                  disableSkinTonePicker
                                  disableAutoFocus
                                  emojiTooltip
                                />
                              )}
                            </div>
                            <div className="send_sticker" >
                              <Dropdown drop="up" align={"end"} className="customMedia p-0">
                                <Dropdown.Toggle id="dropdown-basic">
                                  <TbSticker className="media_sticker" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-0">
                                  {/* <Dropdown.Item onClick={showOpenDocumentFileDialog}>
                                    <IoDocument className="me-2" />Document
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={showOpenImageFileDialog}>
                                    <IoImageOutline className="me-2" />Image
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={showOpenVideoFileDialog}>
                                    <MdVideoLibrary className="me-2" />Video
                                  </Dropdown.Item> */}
                                  <Dropdown.Item onClick={() => setImavideoaccessgallery(true)}>
                                    <IoImageOutline className="me-2" />Image/Video
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={showOpenAudioFileDialog}>
                                    <FaHeadphones className="me-2" />Audio
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={handleShareLocation}>
                                    <FaLocationDot className="me-2" />Location
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => setPreTypeModalShow(true)}>
                                    <LuMessagesSquare className="me-2" />Pre Type Message
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => SetStickerModalShow(true)}>
                                    <LuSticker className="me-2" />Ice Breakers
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleMediaUpload(e, 'image')}
                                ref={coverImageRef}
                                className="d-none"
                                multiple
                              />
                              <input
                                type="file"
                                accept="video/*"
                                onChange={(e) => handleMediaUpload(e, 'video')}
                                ref={coverVideoRef}
                                className="d-none"
                                multiple
                              />
                              <input
                                type="file"
                                accept="document/*"
                                onChange={(e) => handleMediaUpload(e, 'document')}
                                ref={coverDocumentRef}
                                className="d-none"
                                multiple
                              />
                              <input
                                type="file"
                                accept="audio/*"
                                onChange={(e) => handleMediaUpload(e, 'audio')}
                                ref={coverAudioRef}
                                className="d-none"
                                multiple
                              />
                            </div>
                            <span className="emoji_button">
                              <BsEmojiSmile className="emojiIcon" onClick={() => setShowPicker((val) => !val)} />
                            </span>
                            <input
                              value={messageText}
                              onKeyPress={(e) => {
                                if (e.key == 'Enter') {
                                  sendMessage();
                                }
                              }}
                              ref={inputRef}
                              // onChange={(e) => setMessageText(e.target.value)}
                              onChange={(e) => handletextmessages(e)}
                              name="message"
                              type="text"
                              placeholder="Type message..."
                              // className={!messageLimitOver ? "form-control msg_box" : "form-control msg_box_disble"}
                              // disabled={!messageLimitOver ? false : true}
                              className={locationAllowed ? !messageLimitOver ? "form-control msg_box" : "form-control msg_box_disble" : "form-control msg_box_disble"}
                              disabled={!locationAllowed ? true : !messageLimitOver ? false : true}
                            />
                          </div>
                          <button
                            className="send_msg_img"
                          >
                            <img alt="Send Message" onClick={sendMessage} src={sendmsg} className="send_msg_button"></img>
                          </button>

                          {
                            !locationAllowed ?
                              <div onClick={dismessage} className="dismess_permi_locatione" >
                              </div> :
                              messageLimitOver ?
                                <div
                                  onClick={() => setMessagequotamodal(true)}
                                  className="dismess_permi_locatione" >

                                </div>
                                : ""
                          }

                          {/* {messageLimitOver ?
                          <div
                            onClick={() => setMessagequotamodal(true)}
                            className="dismess_permi_locatione" >

                          </div>
                          : ""} */}

                        </Col>

                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
        {
          isChatListOpen && window.innerWidth <= 1023 ? "" :
            <Footer footercolor="footercolor" />
        }
      </div>

      <CropImageModal
        src={src}
        setSrc={setSrc}
        crop={crop}
        setCrop={setCrop}
        image={image}
        setImage={setImage}
        cropImageNow={cropImageNow}
      />

      <Modal
        show={imavideoaccessgallery}
        onHide={() => setImavideoaccessgallery(false)}
        centered
        size="sm"
      >
        <div className="bg-white rounded-3 d-flex flex-column p-4">
          <div className="d-flex flex-column">
            <button
              className={privateimageuploaded ? "upload_chat_gallery_access mb-3" : "upload_chat_gallery_access_disabled mb-3"}
              onClick={() => privateimageuploaded ? GalleryAccessForUser(currentRoomData.member_id) : UploadedNotPrivateGallery()}>
              {(sharegallery && privateimageuploaded) ? "Revoke Gallery Access" : "Share Gallery"}</button>

            <div className="mb-3 w-100" onClick={showOpenPrivetCoverFileDialog}>
              <input
                type="file"
                accept="image/*, video/*"
                onChange={(e) => handleGalleryUpload(e, "true")}
                ref={coverImagePrivetRef}
                className="d-none"
                multiple
              />
              <button className="upload_chat_gallery_access w-100">Upload Gallery</button>
            </div>
            <button className="upload_chat_gallery_access_Cancel" onClick={() => setImavideoaccessgallery(false)}>Cancel</button>
          </div>
        </div>
      </Modal>

      {/* ----- Message quota modal -------- */}
      <Modal
        show={messagequotamodal}
        onHide={() => setMessagequotamodal(false)}
        centered
      >
        <div className="bg-white">
          <Modal.Body>
            <div className="text-center h-100">
              <p className="mt-0 mb-0 offLocation_para_home">Your message quota is exhausted.</p>
              <p className="mt-0 mb-0 offLocation_para_home">Wait for a day or
                <span onClick={() => { setMembershipModelShow(true); setMessagequotamodal(false); }} className="offLocation_upgrade_line"> upgrade</span> </p>
            </div>
            <div className="text-center mt-4">
              <Button className="Message_quota_upgrade" onClick={() => { setMembershipModelShow(true); setMessagequotamodal(false); }}>Upgrade</Button>
            </div>
            <div className="text-center mt-3">
              <Button className="Message_quota_btn_Cancel" onClick={() => setMessagequotamodal(false)}>Cancel</Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* ------ allow locatione modal ----  */}
      <Modal
        show={allowlocationemodal}
        onHide={() => setAllowlocationemodal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="bg-white">

          <div className="text-right">
            <AiOutlineClose onClick={() => setAllowlocationemodal(false)} className="cursor_pointer_common mr-1 mt-1 allow_locatione_close" />
          </div>
          <Modal.Body>
            <div className="text-center h-100">
              <img src={offLocation} className="offLocation_in_home_page" />
              <p className="mt-3 mb-0 offLocation_para_home">You can't send a message because your location is off. please allow location access.</p>
              <p><a href="https://blog.hukup.net/faq/browser-location-access/" target="blank">Click here </a>if need help</p>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* ------- Report User Modal ------- */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={reportModalShow}
        onHide={() => setReportModalShow(false)}
      >
        <div className="Userdetails_modal_main_section text-center">
          <h4 className="Userdetails_modal_title">Report {currentRoomData.name} ?</h4>
          <p className="Userdetails_modal_report_resone ">
            Why are you reporting this account ?
          </p>
          <Form>
            <Form.Group>
              <Form.Select
                className="form-control  Userdetails_modal_selector"
                id="match only"
                name="match_only"
                value={selectAccount}
                onChange={(e) => setSelectAccount(e.target.value)}
              >
                {reportDataList.map((e) => {
                  return (
                    <>
                      <option value={e.report_user_type}>{e.report_user_type}</option>
                    </>
                  )
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="my-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                placeholder="Reason.."
                rows={1}
                className="Userdetails_modal_textarea"
                value={reason}
                onChange={(e) => SetReason(e.target.value)}
              />
            </Form.Group>
            <span className="errorClass">{reasonError}</span>
            <div className="form-check my-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="Block and delete chat"
                checked={isBlockAndDelete}
                onChange={(e) => setIsBlockAndDelete(!isBlockAndDelete)}
              />
              <label class="form-check-label" for="Block and delete chat">
                Block and delete chat
              </label>
            </div>
            <div className="mb-4">
              <Button className="Userdetails_modal_btn_Report_user" onClick={(e) => ReportUserApi(currentRoomData.member_id)}>
                Report user
              </Button>
            </div>
            <div className="">
              <Button
                className="Userdetails_modal_btn_Cancel"
                onClick={() => {
                  setReportModalShow(false)
                  clearValueFun()
                }
                }
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      {/*  ------ Pre Type Message Modal -------- */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={preTypeModalShow}
        onHide={() =>
          resetPriTypeMessageBox()
        }
      >
        <div className="Userdetails_modal_main_section text-center">
          <h4 className="Userdetails_modal_title">Pre Type Message</h4>
          <hr />
          <div>
            <div className="preMessageBox">
              {
                preTypeMessageList?.map((e, index) => {
                  return (
                    <MessageListComponent
                      data={e} key={index}
                      DeletePriTypeMsg={DeletePriTypeMsg}
                      EditPriTypeMessage={EditPriTypeMessage}
                      SendPreTypeMessage={SendPreTypeMessage}
                      preTypeEncryptedCount={preTypeEncryptedCount}
                    />
                  )
                })
              }
            </div>
            {
              showAddMessageBox && (
                <div className="my-2">
                  <hr />
                  <Row>
                    <Col md={8} >
                      <Form.Control
                        type="text"
                        placeholder="Add Message..."
                        value={preTypeMessage}
                        onChange={(e) => SetPreTypeMessage(e.target.value)}
                        ref={inputFocusRef}
                      />
                      <p className="text-start errorClass">{preTypeMessageError}</p>
                    </Col>
                    <Col md={4}>
                      {
                        preTypeMessageBtn.type == "Add" && (
                          <Button
                            className="Userdetails_modal_btn_Cancel"
                            onClick={() => addMessageFun()}
                          >
                            Save
                          </Button>
                        )
                      }
                      {
                        preTypeMessageBtn.type == "Edit" && (
                          <Button
                            className="Userdetails_modal_btn_Cancel"
                            onClick={() => UpdatePreTypeMessage()}
                          >
                            Update
                          </Button>
                        )
                      }
                    </Col>
                  </Row>

                  <hr />
                </div>
              )
            }
            <div className="d-flex">
              <Button
                className="Userdetails_modal_btn_Cancel mx-2"
                onClick={() => SubmitAddPreTypeMessage()
                }
              >
                Add Message
              </Button>
              <Button
                className="Userdetails_modal_btn_Cancel"
                onClick={() => {
                  setPreTypeModalShow(false)
                  resetPriTypeMessageBox()
                }
                }
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* ------ Sticker Modal ------------ */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={stickerModalShow}
        onHide={() => SetStickerModalShow(false)}
      >
        <div className="Userdetails_modal_main_section text-center">
          <h4 className="Userdetails_modal_title">Sticker</h4>
          <hr />
          <div>
            <Row className="stickerBox">
              {
                giftList.map((e) => {
                  return (
                    <StickerListComponent data={e} sendStickerFun={sendStickerFun} />
                  )
                })
              }
            </Row>
            <div>
              <Button
                className="Userdetails_modal_btn_Cancel"
                onClick={() => {
                  SetStickerModalShow(false)
                }
                }
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* ---- Going To Date Modal ------- */}
      <Modal
        show={goingForDate}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={dateModelClose}
        centered
      >
        <div className="going_date_model model_width_set">
          <div className="date_model_header">Are you sure ?</div>
          <div className="model_text">
            As you are going on date with {currentRoomData.name} for your safety, your
            communication with {currentRoomData.name} will be preserved for 30 days even if
            either of you block or delete profile.
          </div>
          <div>
            <button className="ok_model_button" onClick={() => goingForDateFun(currentRoomData.room_id)}>Ok</button>
          </div>
          <div className="pt-3">
            <button className="cancel_model_button" onClick={dateModelClose}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>


      {/* ------ Block User Modal -------- */}
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        show={blockUserModal}
        onHide={handleBlockUserClose}
      >
        <div className="Userdetails_modal_main_section text-center">
          <Form>
            <h4 className="Userdetails_modal_title pb-3">Are you sure want to block {currentRoomData.name}?</h4>

            <div className="mb-3">
              <Button className="Userdetails_modal_btn_Report_user" onClick={(e) => BlockUserApi(currentRoomData.member_id)}>Yes</Button>
            </div>
            <div className="">
              <Button className="Userdetails_modal_btn_Cancel" onClick={() => handleBlockUserClose()}>Cancel</Button>
            </div>
          </Form>
        </div>
      </Modal>

      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />
    </>
  );
}
export default Messages;

function MessagesBox(props) {

  const { Socket } = useSocket();
  const { AuthData } = useAuth();
  const [senderClass, setSenderClass] = useState("")
  const [messageSideClass, setMessageSideClass] = useState("")
  const [profileImage, setProfileImage] = useState(props.profileImage)
  const [isSeen, setIsSeen] = useState(props.message.is_seen)
  const [photoIndex, setPhotoIndex] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const originalDateString = props.message.created_at;
  const originalDate = new Date(originalDateString);

  // Convert to the desired format
  const formattedDateString = `${originalDate.getFullYear()}-${String(originalDate.getMonth() + 1).padStart(2, '0')}-${String(originalDate.getDate()).padStart(2, '0')}`;

  var encryptedmessage = formattedDateString < props.currentRoomData.message_encrypted_date ? "message_encrypted" : ""

  var encryptedmessagedata = formattedDateString < props.currentRoomData.message_encrypted_date ? true : false


  useEffect(() => {
    if (AuthData.id == props.message.sender_id) {
      setSenderClass("user_side_chat")
      setMessageSideClass("outgoing_msg")
      setProfileImage(AuthData.thumbnail_image);
    }
    else {
      setSenderClass("")
      setMessageSideClass("incomming_msg")
    }
    if (!isSeen) {
      Socket.on("message-seen-" + props.message.room_id, (data) => {
        setIsSeen(1)
      });
    }
    return () => {
      Socket.off("message-seen-" + props.message.room_id);
    }
  }, [])

  const lightBoxModalImage = (url) => {
    setIsOpen(true)
    setPhotoIndex(url)
  }

  // --- convert to text message to link send url top chat room -------
  const textString = props.message.message;
  const convertedText = convertTextToLinks(textString);

  function convertTextToLinks(text) {
    const urlRegex = /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+|[\w-]+\.com[^\s]*)/g;
    return text.replace(urlRegex, (match, space, url) => {
      let originalUrl = url;
      if (url.startsWith("www.")) {
        url = `https://${url}`;
      } else if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://www.${url}`;
      }
      return `${space}<a href="${url}" target="_blank" rel="noopener noreferrer">${originalUrl}</a>`;
    });
  }


  return (
    <>
      {["private_gallery_approved_notification"].includes(props.message.type) &&
        <Row className="my-2">
          <Col className={!AuthData.is_premium ? ` ${encryptedmessage}` : ""}>
            <div className="message_private_gallery_notifiction">
              {
                encryptedmessagedata && !AuthData.is_premium ?
                  <div className="notification m-auto ">
                    <span className="mb-0 " dangerouslySetInnerHTML={{ __html: "notifiction " }}></span>
                    <span className="ml-2">
                      View
                    </span>
                  </div>
                  :
                  <div className="notification m-auto ">
                    <span className="mb-0 " dangerouslySetInnerHTML={{ __html: props.message.message }}></span>
                    {AuthData.id != props.message.sender_id && (
                      <span className="ml-2">
                        <Link to={"/usergallery/" + props.currentRoomData.username + "?tab=private"} className="mess_apr_View_link">View</Link>
                      </span>
                    )}
                  </div>
              }
              <p className="mb-0 text-end mess_all_notifi_time">
                {props.convertTimestampToTimeMessage(props.message.created_at)}
              </p>
            </div>
          </Col>
        </Row>
      }
      {["liked_notification", "visite_notification", "favorite_notification", "private_gallery_sent_notification", "private_gallery_rejected_notification", "match_notification"].includes(props.message.type) &&
        <Row className="my-2">
          <Col className={!AuthData.is_premium && encryptedmessagedata ? ` ${encryptedmessage}` : ""}>
            <div className="message_all_notifiction">
              {
                encryptedmessagedata && !AuthData.is_premium ?
                  <div className="notification m-auto">
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: "notifiction" }}></p>
                  </div>
                  :
                  <>
                    <div className="notification m-auto">
                      <p className="mb-0 text-break" dangerouslySetInnerHTML={{ __html: props.message.message }}></p>
                    </div>
                    <p className="mb-0 text-end mess_all_notifi_time">
                      {props.convertTimestampToTimeMessage(props.message.created_at)}
                    </p>
                  </>
              }

            </div>
          </Col>
        </Row>
      }
      <Row className={senderClass + " m-0"} >
        {/* ---- sender and reciver image ------ */}
        {window.innerWidth > 767 ?
          <Col sm={1} xs={2} className="new_add_class">
            {!["liked_notification", "visite_notification", "favorite_notification", "private_gallery_sent_notification", "private_gallery_approved_notification", "private_gallery_rejected_notification", "match_notification"].includes(props.message.type) && (
              (AuthData.id == props.message.sender_id) ? (
                <img
                  src={profileImage}
                  className="profile_img chat_u imgset"
                  onError={(e) => {
                    e.target.src = errorImg;
                  }}
                  alt="Profile"
                ></img>
              ) : (
                <span className="new_add_class" onClick={() => props.goToUserPage(props.currentRoomData.username)}>
                  <img
                    src={profileImage}
                    className="profile_img chat_u imgset"
                    onError={(e) => {
                      e.target.src = errorImg;
                    }}
                    alt="Profile"
                  ></img>
                </span>
              )
            )}
          </Col>
          : ""
        }
        {/* ---- Message In Room ------ */}
        <Col sm={5} xs={10} className="px-0">
          {props.message.type == 'text' &&
            <>
              <div className={!AuthData.is_premium && props.currentRoomData.member_id != 0 && encryptedmessagedata ? `${messageSideClass}  ${encryptedmessage}` : messageSideClass}>
                {
                  !AuthData.is_premium && encryptedmessagedata && props.currentRoomData.member_id != 0 ?
                    <p>Fake Message</p>
                    :
                    <p dangerouslySetInnerHTML={{ __html: convertedText }}   >
                    </p>
                }
              </div>
            </>
          }
          {(props.message.type == 'image' || props.message.type == 'sticker') &&
            <>
              <div className={!AuthData.is_premium && encryptedmessagedata && props.currentRoomData.member_id != 0 ? `${encryptedmessage} chat_message_image` : "chat_message_image"} >
                {
                  !AuthData.is_premium && encryptedmessagedata && props.currentRoomData.member_id != 0 ?
                    <img alt="Chat" src={nophoto} />
                    :
                    <img alt="Chat" src={props.message.message} onClick={() => lightBoxModalImage(props.message.message)} />
                }
              </div>
            </>
          }
          {isOpen && (
            <Lightbox
              mainSrc={photoIndex}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
          {props.message.type == 'video' &&
            <>
              <div className="chat_message_image">
                {
                  !AuthData.is_premium && encryptedmessagedata && props.currentRoomData.member_id != 0 ?
                    <video controls
                      className={!AuthData.is_premium && encryptedmessagedata && props.currentRoomData.member_id != 0 ? encryptedmessage : ""}>
                      <source src={peacefulnatural}></source>
                    </video>
                    :
                    <video controls
                    // className={!AuthData.is_premium && encryptedmessagedata && props.currentRoomData.member_id != 0 ? encryptedmessage : ""}
                    >
                      <source src={props.message.message}></source>
                    </video>
                }
              </div>
            </>
          }
          {props.message.type == 'audio' &&
            <>
              <div className={AuthData.id != props.message.sender_id ? "text-start" : "text-end"}>
                <audio controls className={!AuthData.is_premium && encryptedmessagedata ? `${encryptedmessage} audio-container` : "audio-container"}>
                  <source src={props.message.message}></source>
                </audio>
              </div>
            </>
          }
          {props.message.type == 'document' &&
            <div>
              <DocumentShow messageSideClass={messageSideClass}
                message={props.message.message} encryptedmessage={encryptedmessage}
                encryptedmessagedata={encryptedmessagedata}
              />
            </div>
          }
          {props.message.type == 'location' &&
            <>
              <LocationShowComponent message={props.message.message}
                encryptedmessage={encryptedmessage} encryptedmessagedata={encryptedmessagedata} />
            </>
          }
          {!["liked_notification", "visite_notification", "favorite_notification", "private_gallery_sent_notification", "private_gallery_approved_notification", "private_gallery_rejected_notification", "match_notification"].includes(props.message.type) && (
            <div className={!AuthData.is_premium && encryptedmessagedata ? `${encryptedmessage} user_msg_time` : "user_msg_time"}>
              {props.convertTimestampToTimeMessage(props.message.created_at)}
              {AuthData.id == props.message.sender_id && (
                isSeen ? (
                  <span className="msg_check ml-1">
                    <MdRemoveRedEye />
                  </span>
                ) : (
                  <span className="msg_un_check ml-1">
                    <BsCheck2All />
                  </span>
                )
              )}
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

const DocumentShow = ({ messageSideClass, message, encryptedmessage, encryptedmessagedata }) => {
  const [fileName, setFileName] = useState("");
  const [type, setType] = useState("");
  const { AuthData } = useAuth();

  useEffect(() => {
    asyncFn(message)
  }, [message])

  const asyncFn = async (params) => {
    var splitData = params.split("uploads/images/");
    var result = splitData[splitData.length - 1]
    var name = result.split("-")
    var types = result.split(".")
    name.shift()
    types.shift()
    setFileName(name)

    for (var i = 0; i < types.length; i++) {
      setType(types[i]);
    }
  };


  return (
    <>
      <div className={messageSideClass}>
        <p className={!AuthData.is_premium && encryptedmessagedata ? encryptedmessage : ""}>
          {
            !AuthData.is_premium && encryptedmessagedata ?
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column align-items-center">
                  <IoDocument className="m-2 fs-2" />
                  <span>No Type</span>
                </div>
                <p className="doc_name">No Name</p>
                <a href="" className="p-0">
                  <FaDownload className="m-2 fs-4 downloadBtn" />
                </a>
              </div>
              :
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column align-items-center">
                  <IoDocument className="m-2 fs-2" />
                  <span>{type}</span>
                </div>
                <p className="doc_name">{fileName}</p>
                <a target="_blank" href={message} className="p-0" download={fileName}>
                  <FaDownload className="m-2 fs-4 downloadBtn" />
                </a>
              </div>
          }
        </p>
      </div>
    </>
  )
}

const LocationShowComponent = ({ message, encryptedmessage, encryptedmessagedata }) => {
  const [location, setLocation] = useState({ lat: parseFloat(message.split(",")[0]), lng: parseFloat(message.split(",")[1]) });
  const { AuthData, siteSetting } = useAuth();

  const handleApiLoaded = (map, maps) => {
    map.setOptions({ disableDefaultUI: true });
  };

  return (
    <>
      <div className={!AuthData.is_premium && encryptedmessagedata ? `${encryptedmessage} chat_message_location` : "chat_message_location"}>
        <div className="google_map_maindiv ">
          {
            !AuthData.is_premium && encryptedmessagedata ?
              <a target="_blank" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${"25.2744"},${"133.7751"}`} >
                {
                  location && (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: siteSetting.siteSetting.google_map_key }}
                      defaultCenter={location}
                      defaultZoom={10}
                      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                      draggable={false}
                    >
                      <div
                        lat={location.lat}
                        lng={location.lng}
                      >
                        <img alt="marker" className="marker_point" src={"https://i.pinimg.com/originals/30/98/49/309849c5815761081926477e5e872f1e.png"} />
                      </div>
                    </GoogleMapReact>
                  )
                }
              </a>
              :
              <a target="_blank" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${location.lat},${location.lng}`}>
                {
                  location && (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: siteSetting.siteSetting.google_map_key }}
                      defaultCenter={location}
                      defaultZoom={10}
                      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                      draggable={false}

                    >
                      <div
                        lat={location.lat}
                        lng={location.lng}
                      >
                        <img alt="marker" className="marker_point" src={"https://i.pinimg.com/originals/30/98/49/309849c5815761081926477e5e872f1e.png"} />
                      </div>
                    </GoogleMapReact>
                  )
                }
              </a>
          }
        </div>
      </div>
    </>
  )
}

const MessageListComponent = ({ data, DeletePriTypeMsg, EditPriTypeMessage, SendPreTypeMessage, preTypeEncryptedCount }) => {

  const { AuthData } = useAuth();


  return (
    <>
      <div className="preMessage ">
        <div onClick={() => SendPreTypeMessage(data.message)} className="pre_Message_fir_section">
          <p className="my-1 text-break" >{data.message}</p>
        </div>
        <div className="d-flex align-items-center justify-content-around pre_Message_seco_section">
          <MdOutlineEdit className="PriMsgEditDltBtn" onClick={() => EditPriTypeMessage(data.id, data.message)} />
          <MdDelete className="PriMsgEditDltBtn" onClick={() => DeletePriTypeMsg(data.id)} />
        </div>
      </div>

      {!AuthData.is_premium && (() => {
        const PretypeFakeData = [];
        for (let index = 0; index < preTypeEncryptedCount.encrypted_count; index++) {
          PretypeFakeData.push(
            <div className="preMessage message_encrypted ">
              <div className="pre_Message_fir_section">
                <p className="my-1 text-break ">hii</p>
              </div>
              <div className="d-flex align-items-center justify-content-around pre_Message_seco_section">
                <MdOutlineEdit className="PriMsgEditDltBtn" />
                <MdDelete className="PriMsgEditDltBtn" />
              </div>
            </div>
          );
        }
        return PretypeFakeData;
      })()}
    </>
  )
}

const StickerListComponent = ({ data, sendStickerFun }) => {
  return (
    <>
      <Col md={4}>
        <img alt="Sticker" src={data.image} className="stickerImg" onClick={() => sendStickerFun(data.url)} />
      </Col>
    </>
  )
}