import React from 'react'
import { Container, Col, Row, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import hukaplogo from "../../images/hukaplogo.png";
import playstore from "../../images/playstore.png"
import applestore from "../../images/applestore.png"
import { useAuth } from '../../middleware/AuthProvider';


const FooterSecond = () => {
  const { siteSetting, footerList } = useAuth();

  return (
    <>
    {/* ------------------ with out login footer ---------------- */}
      <Container fluid className="footer_main ">
        <Container className="">
          <Row className="align-items-center">
            <Col xs={12} sm={6} md={8} lg={6} xl={6} className="order-last order-sm-last order-lg-first order-md-first order-xl-first">
              <Row className="">
                <Col className="px-0">
                  <div className="d-flex footer_content_like_section">
                    <a href={"https://blog.hukup.net/faqs/"} className="footer_link text-decoration-none" target="_blank">FAQ</a>
                    {footerList.map((e) => {
                      return (
                        <Nav.Link as={Link} to={"/" + e.slug} className="footer_link">{e.title}</Nav.Link>
                      )
                    })}
                  </div>
                </Col>
              </Row>
              <Row className="">
                <Col className="mb-0 px-0">
                  <p className="footer_Rights">Copyright <AiOutlineCopyrightCircle /> {new Date().getFullYear()} {siteSetting?.siteSetting?.site_name ? siteSetting?.siteSetting?.site_name : "Hukup"} All Rights Reserved</p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6} md={4} lg={6} xl={6} className="px-0">
              <Row className="mx-0 align-items-center">
                <Col xs={12} sm={6} md={3} lg={6} xl={6} className="d-none d-sm-none d-md-none d-lg-block  px-0">
                  <img src={siteSetting?.siteSetting?.site_footer_logo ? siteSetting?.siteSetting?.site_footer_logo : hukaplogo} height={"auto"} width={70} alt="Hukup Australia Logo" />
                </Col>
                <Col xs={12} sm={6} md={12} lg={6} xl={6} className="px-0 pt-1 pt-sm-2 pt-md-0 pt-lg-0 pt-xl-0">
                  <div className="footer_apple_play   px-0  ">
                    <a href={siteSetting?.siteSetting?.play_store_app_link} target="_blank"><img alt='Play Store' src={playstore} className="playstore_img " /></a>
                    <a href={siteSetting?.siteSetting?.app_store_app_link} target="_blank"><img alt='App Store' src={applestore} className="applestore_img ml-2 " /></a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default FooterSecond