import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import "../LoginandReg/LoginPage.css";
import gay from "../../images/Loginpage/1.gay.png";
import user from "../../images/logo.png";
import lesbian from "../../images/Loginpage/2.lesbian.png";
import bisexual from "../../images/Loginpage/3.bisexual.png";
import transgender from "../../images/Loginpage/4.transgender.png";
import roundimg from "../../images/Loginpage/Avatar.png"
import layer from "../../images/Loginpage/Rectangle 4.png"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InfluencerLoginData, LoginPageLinksApi } from "../../api/Api";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useAuth } from "../../middleware/AuthProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import DataLoader from "../../components/ScreenLoader/DataLoader";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import HeaderSecond from "../CommonComponet/HeaderSecond";
import { useSocket } from "../../middleware/SocketProvider";


const InfluencerLogin = () => {
  const { setAuthData, siteSetting } = useAuth();
  const { connectSocket } = useSocket();
  const [isDataLoader, setisDataLoader] = useState(false);
  const [genderIcon, SetGenderIcon] = useState([gay, lesbian, bisexual, transgender])
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [pageLinks, setPageLinks] = useState([])
  const [termandconditions, setTermandconditions] = useState(false)
  const [termandconditionsError, setTermandconditionsError] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); 

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 2500 });
    loginPageLinks();
    document.title = "Hukup Australia";
  }, []);


  const validationemail = () => {
    Errorclearemail();
    var isvalid = true

    if (email != "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError("Email is not valid")
        isvalid = false;
      }
    } else {
      setEmailError("Please Enter Email")
    }
    if(password.trim() == ""){
        setPasswordError("Please Enter Password");
        isvalid = false;
    }
    if (!termandconditions) {
      setTermandconditionsError("You must agree with the terms and conditions");
      isvalid = false;
    }
    return isvalid;
  }

  const Errorclearemail = (e) => {
    setEmailError("");
    setTermandconditionsError(false)
  };


  const loginPageLinks = () => {
    LoginPageLinksApi().then((response) => {
      if (response.data.status) {
        setPageLinks(response.data.data)
      }
    })
  }

  const handleEmailotp = (e) => {
    setEmail(e.target.value);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  const signin = () => {
    if (disableLoginBtn) {
      return false;
    }
    setDisableLoginBtn(true);
      if (!validationemail()) {
        setDisableLoginBtn(false);
        return false;
      }
      var data = {
        email: email,
        password: password,
        device_token:"",
        device_name:"",
        device_type:"web"
      }

      InfluencerLoginData(data).then(function (response) {
        setDisableLoginBtn(false);
        console.log("---------------", response.data.status );
        console.log(response.data);
        if (response.data.status) {
            var userData = response.data.token.userData;
            userData.token = response.data.token.token;
            userData.description = encodeURIComponent(userData.description)
            var encryptData = btoa(JSON.stringify({
              token: userData.token,
              id: userData.id
            }));
            localStorage.setItem('hukup-info', encryptData);
            // connectSocket(userData.id)
            // setAuthData(userData);
            window.location.reload();
        }
        else {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
        .catch(function (error) {
          setDisableLoginBtn(false);
          console.log(error);
          Store.addNotification({
            message: "Something goes wrong, please try again later!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        });
  }

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={"Hukup Australia"} />
      </Helmet>

      <HeaderSecond loginbtnremove="loginbtnremove" />

      <div className="container-fluid bg-white with_out_login_page_header position-relative">
        <Row className="loginmodalcenter">       
          <Col className="logindetalis" md={6} >
            <Row >
              <Col md={8} lg={8} xl={7} className="mx-auto">
                <div className="divcenter">
                  <div className='mb-3 mb-md-5'>
                    <img alt="Hukup Australia Logo" src={siteSetting?.siteSetting?.site_logo ? siteSetting?.siteSetting?.site_logo : user} className="sidebar_icon_login" />
                  </div>
                  <p className="Welcoming_title_login text-center">{siteSetting?.siteSetting?.tag_line}</p>
                  <div className="loginfont">LOGIN</div>
                      <div className="Claima_email">
                        <Form.Control
                          className="border-remove"
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) => handleEmailotp(e)}
                          value={email}
                        />         
                      </div>
                      <span className="errorClass">{emailError}</span>
                      <div className="Claima_email mt-4">
                        <Form.Control
                          className="border-remove"
                          type="password"
                          placeholder="Enter Password"
                          onChange={(e) => handlePassword(e)}
                          value={password}
                        />         
                      </div>
                      <span className="errorClass">{passwordError}</span>
                      <div className="mx-auto Term_conditions_mainsection" >
                        <div className="mt-3 Term_conditions_main_login">
                          <input
                            type="checkbox"
                            id="Termandconditions"
                            value={termandconditions}
                            checked={termandconditions}
                            onChange={(e) => setTermandconditions(!termandconditions)} className="form-check-input" />
                          <label for="Termandconditions" className="mb-0 ml-4" >
                            I agree with <a target="_blank" href="/privacy-policy" > Privacy Statement</a> and <a target="_blank" href="/terms-conditions">Terms and Conditions</a> of Hukup Australia
                          </label>
                        </div>
                      </div>
                      <span className="errorClass">{termandconditionsError}</span>
                      <div id="recaptcha-container" ></div>
                      <div className="login-button-colour mt-3 btncustom" onClick={() => signin()}>
                        <div className="d-flex align-items-center justify-content-center">
                          <div>Let’s Start</div>
                          <div className="">
                            <MdOutlineKeyboardArrowRight size={30} />
                            {disableLoginBtn && (
                              <Spinner animation="border" size="sm" />
                            )}
                          </div>
                        </div>
                      </div>
              

                  {/* ==== */}
                  <div className="mt-3">
                    <a target="_blank" href="https://blog.hukup.net/create-new-ticket/" className="mb-0 cursor_pointer_common">Trouble signing in?</a>
                  </div>
                  <div className="mt-4 text-center">
                    <a className="text-decoration-none" target="_blank" href={"https://blog.hukup.net/faqs/"} ><span className="mx-1 mx-md-2 fonthere FAQ_Terms_of_Use_title">FAQ</span></a>
                    {
                      pageLinks.map((ele) => {
                        return (
                          <>
                            {" | "}<a className="text-decoration-none" target="_blank" href={"/" + ele.slug} ><span className="mx-1 mx-md-2 fonthere FAQ_Terms_of_Use_title">{ele.title}</span></a>
                          </>
                        )
                      })
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="login-second-bg d-none d-md-block" md={6}>
            <div>
              <img alt="layer" className="layerclass" src={layer} />
              <img alt="roundimg" className="roundimg" src={roundimg} />
              <div className="row">
                {genderIcon.map((e) => {
                  return (
                    <Col className="mx-2"><img alt="gender" className="genderimg" src={e} /></Col>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row >
      </div >


    </>
  );
};

export default InfluencerLogin;
