import React from 'react'
import Header from '../../CommonComponet/Header'
import Footer from '../../CommonComponet/Footer'
import { FooterPageDataApi } from '../../../api/Api'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import "./DynamicPage.css"
import { Helmet } from "react-helmet";
import { useAuth } from '../../../middleware/AuthProvider'
import HeaderSecond from '../../CommonComponet/HeaderSecond'
import FooterSecond from '../../CommonComponet/FooterSecond'
import FooterMobile from '../../CommonComponet/FooterMobile'

const DynamicPage = () => {
  const { isAuthenticated } = useAuth();
  var navigate = useNavigate()
  const [pageData, setPageData] = useState({});
  var { page } = useParams();

  useEffect(() => {
    FooterPageDataApi(page).then(function (response) {
      if (!response.data.data) {
        navigate("/home")
      }
      setPageData(response.data.data);
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [page])

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.meta_description} />
        <meta name="keywords" content={pageData.meta_keywords}></meta>
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img alt='Whitedot' src={require("../../../images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_first" />
          <img alt='Whitedot' src={require("../../../images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_second" />
          <img alt='smileemoji' src={require("../../../images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
          <img alt='CurveBlackline' src={require("../../../images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
          <img alt='orangeline' src={require("../../../images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        {
          isAuthenticated ? (
            <Header />
          ) : (<HeaderSecond />)
        }


        <div className="container DynamicPage_content_main">
          <div className="row  position-relative ql-editor">
            <h2 className='text-center' dangerouslySetInnerHTML={{ __html: pageData?.title }}></h2>
            <div className='mt-2' dangerouslySetInnerHTML={{ __html: pageData?.description }} ></div>
          </div>
          <div className="row">
            {
              isAuthenticated ? (<FooterMobile />) :
                ""
            }
          </div>
        </div>
        {
          isAuthenticated ? (
            <Footer />
          ) : (<FooterSecond />)
        }
      </div>

    </>
  )
}

export default DynamicPage