import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { removeSnackbar } from "../../redux/actions/Snackbar";
import SnackbarWrapper from "./Snackbar.style";

const Snackbar = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const snackbarOpen = useSelector(
    (state) => state.SnackbarReducer.snackbarOpen
  );
  const snackbarType = useSelector(
    (state) => state.SnackbarReducer.snackbarType
  );
  const snackbarMessage = useSelector(
    (state) => state.SnackbarReducer.snackbarMessage
  );

  const Message = () => {
    return (
      <>
        <SnackbarWrapper>
          <div className="snackbar">
            <p>{snackbarMessage}</p>
          </div>
        </SnackbarWrapper>
      </>
    );
  };

  useEffect(() => {
    if (snackbarOpen) {
      enqueueSnackbar(<Message />, {
        variant: snackbarType,
        persist: false,
        autoHideDuration: 7000,
        preventDuplicate: false,
      });
    }

    return () => {
      dispatch(removeSnackbar(false, snackbarType, snackbarMessage));
    };
  }, [snackbarOpen]);

  return <></>;
};

export default Snackbar;
