import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./LogOutModal.css"
import { LogOutApi } from '../../../api/Api'

const LogOutModal = (props) => {

  const Logout = () =>{
    LogOutApi().then(function (response) {
      if(response.status){
       var data = localStorage.getItem("locatione_allowed")
        localStorage.clear();
        localStorage.setItem("locatione_allowed",data);
        window.location.href = '/';
      }
    })
    .catch(function (error) {
      console.log(error);
    }); 
  }
  
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" "
      >
        <div className="DeleteAccModal_modal_main_section">
          <div className="text-center px-3">
            <img alt='Logout' className="mx-auto LogOutModal_page_warning_icon" src={require("../../../images/Setting/warning_icon.png")} />
          <p  className="LogOutModal_first_para my-4">Are you sure you want to logout from Hukup ?</p>
          </div>
          <div className=" text-center mb-3">
            <Button className="DeleteAccModal_Delete_btn" onClick={(e)=>Logout()}>Logout</Button>
          </div>
          <div className=" text-center">
            <Button onClick={props.onHide} className="DeleteAccModal_Cancel_btn">Cancel</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LogOutModal