import React, { useEffect, useState } from 'react'
import "../../css/ConfirmedPayment.css"
import CheckIcon from '../../images/SelectPaymentMethod/Vector.png'
import Line from "../../images/SelectPaymentMethod/Line.png"
import Header from '../CommonComponet/Header'
import Footer from '../CommonComponet/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { paymentHistoryDetailsApi } from '../../api/Api'


const ConfirmedPayment = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [paymentData, SetPaymentData] = useState({})

    useEffect(() => {
        paymentHistoryDetailsApi(id).then(function (response) {
            if (!response.data.status) {
                navigate("/home")
                return false
            }
            SetPaymentData(response.data.data)
        }).catch(function (error) {
            console.log(error);
        });

        document.title = "Hukup Confirmed Payment"
    }, [])

    return (
        <>
           
            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                <Header />
                <div className="Confirmed_Payment_main_section">
                    <div className="container">
                        <div className="row py-2">
                            <div className="col-12 text-center pt-3 pt-sm-5	pt-md-5	pt-lg-5	pt-xl-5 pb-2 pb-sm-2 pb-md-4 pb-lg-4 pb-xl-4">
                                <h1 className="Payment_Confirmation">Payment Confirmed  </h1>
                            </div>
                        </div>
                    </div>
                    <div className="container pb-4 px-sm-5 px-md-4 px-lg-4 px-xl-4">
                        <div className="row">
                            <div className="col-10 col-sm-10 col-md-6 col-lg-4 col-xl-3 mx-auto px-0" >
                                <div className="mx-auto card_main_section" >
                                    <div className="mx-auto round_main_section">
                                        <div className="round_inner_scetion">
                                            <img alt='Check' src={CheckIcon} />
                                        </div>
                                    </div>
                                    <div className="Payment_detailes_card pt-5 pb-4 text-center">
                                        <h1 className="pt-3 Great_title">Great !</h1>
                                        <h1 className="px-2 confirmed_payment_information">Payment information Confirmed</h1>
                                        <p className="px-3 started_switching">We have started switching you over!</p>
                                        <img alt='Line' src={Line} className="card_line" />
                                        <p className="px-3 your_summary" >Your summary</p>
                                        <div className="d-flex justify-content-between align-items-center my-2">
                                            <h4 className="px-3 total_paid my-0">Total paid</h4>
                                            <h1 className="px-3 price_dollar my-0">${paymentData.amount}</h1>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center my-2">
                                            <h4 className="px-3 total_paid my-0">Plan expiry</h4>
                                            <h1 className="px-3 expiry_date my-0">{paymentData.end_date}</h1>
                                        </div>
                                        <p className="px-2 using_card_detailes pt-2">Using {paymentData.card_name} ****<span>{paymentData.last_four_digit}</span> {paymentData.created_at}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mb-4">
                        <button className="explore_hukup_btn" onClick={() => navigate("/home")}>Explore hukup</button>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ConfirmedPayment