import React from 'react'
import { Container, Col, Row, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import playstore from "../../images/playstore.png"
import applestore from "../../images/applestore.png"
import { useAuth } from '../../middleware/AuthProvider';

const FooterMobile = () => {
    const { siteSetting, footerList } = useAuth();

    return (
        <div className="d-lg-none p-0 footer_mobile_main_section">
            <Container fluid className="">
                <Row className="align-items-center px-md-3">
                    <Col xs={12} sm={6} md={8} lg={6} xl={6} className="">
                        <Row className="my-2">
                            <Col className="px-0">
                                <div className="d-flex footer_content_like_section">
                                <a href={"https://blog.hukup.net/faqs/"}className="footer_link text-decoration-none" target="_blank">FAQ</a>
                                    {footerList.map((e) => {
                                        return (
                                            <Nav.Link as={Link} to={"/" + e.slug} className="footer_link text-center">{e.title}</Nav.Link>
                                        )
                                    })}
                                    {/* <a className="text-decoration-none footer_link" href="https://blog.hukup.net/" target="_blank" >Blog</a> */}
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="mb-0 px-0 mb-sm-2">
                                <p className="footer_Rights">Copyright <AiOutlineCopyrightCircle /> {new Date().getFullYear()} {siteSetting?.siteSetting?.site_name ? siteSetting?.siteSetting?.site_name : "Hukup"} All Rights Reserved</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={6} xl={6} className="px-0">
                        <Row className="mx-0 align-items-center"> 
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="px-0 pt-1 pt-sm-2 pt-md-0 pt-lg-0 pt-xl-0">
                                <div className="footer_apple_play px-0 mt-1 mb-2">
                                    <a href={siteSetting?.siteSetting?.play_store_app_link} target="_blank" ><img alt='Play Store' src={playstore} className="playstore_img_mobile " /></a>
                                    <a href={siteSetting?.siteSetting?.app_store_app_link} target="_blank" ><img alt='App Store' src={applestore} className="applestore_img_mobile ml-2 " /></a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterMobile