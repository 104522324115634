import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./middleware/AuthProvider";
import { SocketProvider } from "./middleware/SocketProvider";
import { Loader } from "./middleware/AuthProvider";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <SocketProvider>
            <Loader>
              <ReactNotifications />
              <App />
            </Loader>
          </SocketProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
