import React, { useEffect, useState } from "react";
import Header from "../../CommonComponet/Header";
import Footer from "../../CommonComponet/Footer";
import { PrivacySetting, PrivacyView } from "../../../api/Api";
import AccountSettingsidebar from "./AccountSettingsidebar.js"
import RespoAccSettingsidebar from "./RespoAccSettingsidebar.js"
import FooterMobile from "../../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";

const Privacy = () => {

  const [myProfile, setMyProfile] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [iLikeAndVisit, setILikeAndVisit] = useState(false);
  const [privacyData, setPrivacyData] = useState(null);

  useEffect(() => {
    PrivacyViewApi();
    document.title = "Private Me On Hukup Australia"
  }, [])

  useEffect(() => {
    if (privacyData) {
      PrivacySettingApi();
    }
  }, [myProfile, onlineStatus, iLikeAndVisit]);

  const PrivacyViewApi = () => {
    PrivacyView()
      .then(function (response) {
        setMyProfile(response.data.data?.share_profile !== 0 ? true : false);
        setOnlineStatus(
          response.data.data?.online_status !== 0 ? true : false
        );
        setILikeAndVisit(
          response.data.data?.like_and_visit !== 0 ? true : false
        );
        setPrivacyData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const PrivacySettingApi = () => {
    var data = {
      share_profile: myProfile,
      online_status: onlineStatus,
      like_and_visit: iLikeAndVisit,
    };

    PrivacySetting(data)
      .then(function (response) { })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>

      <Helmet>
        <meta name="description" content={"Private Me On Hukup Australia"} />
      </Helmet>
      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            alt="smileemoji"
            src={require("../../../../src/images/Visitors/smileemoji.png")}
            className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block"
          />
          <img
            alt="CurveBlackline"
            src={require("../../../../src/images/Visitors/CurveBlackline.png")}
            className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block"
          />
          <img
            alt="orangeline"
            src={require("../../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />
        <div className="container-fluid content_main px-0">

          <div className="Notifications_main_section">
            <div className="container">
              <div className="row">
                <div className="col-3  Settingnotifications_siderbar_main pl-lg-3 pl-xl-4 d-none d-lg-block ">
                  <AccountSettingsidebar />
                </div>

                {/*. === second section =====  */}
                <div className="col-12 col-lg-8 offset-0 offset-lg-1  ">
                  <div className="text-center  mt-5 mb-4 d-none d-lg-block">
                    <h1 className="Settingnotifications_Setting_title">
                      Private Me
                    </h1>
                  </div>

                  <div className="d-flex justify-content-between align-items-center my-3 d-block d-lg-none ">
                    <div>
                      <span className="notifiction_setting_mobile_title">
                        Private Me
                      </span>
                    </div>
                    <div>
                      <RespoAccSettingsidebar />
                    </div>
                  </div>

                  <div className="Settingnotifications_Allow_notification_main mt-4">
                    <h4 className="my-3 Settingnotifications_E_mail_Privacy">
                      Private Me
                    </h4>
                    <div className="d-flex align-items-center justify-content-between my-3 ">
                      <label className="form-check-label Settingnotifications_label_name">
                        Allow profile share
                      </label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={myProfile}
                          onChange={(e) => {
                            setMyProfile(!myProfile);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-3 ">
                      <label className="form-check-label Settingnotifications_label_name">
                        Show my online status
                      </label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={onlineStatus}
                          onChange={(e) => {
                            setOnlineStatus(!onlineStatus);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-3 ">
                      <label className="form-check-label Settingnotifications_label_name">
                        Show me only to people I like
                      </label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={iLikeAndVisit}
                          onChange={(e) => {
                            setILikeAndVisit(!iLikeAndVisit);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;
