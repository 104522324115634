import React, { useEffect } from 'react'
import "./DeleteAccModal.css"
import { Button, Modal } from 'react-bootstrap';
import ReactInputVerificationCode from "react-input-verification-code";
import { useState } from 'react';
import { DeleteProfile, VerifyDeleteOtp, SendDeleteOtp } from '../../../api/Api';
// import { firebase, auth } from '../../../firebase/Firebase';
import { useAuth } from '../../../middleware/AuthProvider';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import DataLoader from '../../../components/ScreenLoader/DataLoader';


const DeleteAccModal = (props) => {
  const { AuthData, setAuthData } = useAuth();
  const [isDataLoader, setisDataLoader] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [sendCodeButton, setSendCondButton] = useState(false);
  // const [firebaseLogin, setFirebaseLogin] = useState(true);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [buttonClick, setButtonClick] = useState(1);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [final, setfinal] = useState('');
  const [verify, setVerify] = useState("");
  const [number, setNumber] = useState(AuthData.mobile_number)
  const [countrycode, setCountrycode] = useState(AuthData.country_code ? AuthData.country_code : 91)

  // ======== otp send count =======
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const setOpenModelOtp = () => {
    setSendCondButton(true);
    sendOTP()
  }
  // --- send otp api call to for user -------
  const sendOTP = () => {
    setMinutes(1);
    SendDeleteOtp().then(function (response) {
      if (response.data.status) {
        setisDataLoader(false)
        setDeleteBtn(true);
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }

    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
        Store.addNotification({
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      });
  };

  // ----- verifyotp api call --
  const VerifyOTP = () => {
    setisDataLoader(true)
    if (verificationCode.indexOf("·") != -1) {
      setisDataLoader(false)
      return false;
    }
    setisDataLoader(true)
    var data = {
      otp: verificationCode
    }
    VerifyDeleteOtp(data).then(function (response) {
      if (response.data.status) {
        setisDataLoader(false)
        setAuthData(null)
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
        Store.addNotification({
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      });
  }

  const resendOTP = () => {
    // setSeconds(59);
    setButtonClick(buttonClick + 1)
    sendOTP()
  };

  const Close = () => {
    setisDataLoader(false)
    props.onHide()
    setSendCondButton(false);
    setMinutes(0);
    setSeconds(0);
    setDeleteBtn(false);
    setVerify("");
    setVerificationCode("");
  }

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" "
      >
        <div className="DeleteAccModal_modal_main_section">
          <div className="text-center px-3">
            <img alt='Broken Heart' className="mx-auto DeleteAccModal_broken_heart_icon" src={require("../../../images/Setting/broken_heart.png")} />
            <p className="DeleteAccModal_Are_YouSure">Are You Sure ?</p>
            <p className="DeleteAccModal_first_para">If you delete your account , you will lose all your profile, messages, photos and your matches.</p>
            <p className="DeleteAccModal_second_para">Are you sure you want  to delete your account ?</p>
          </div>

          {sendCodeButton ?
            <>
              <ReactInputVerificationCode autoFocus={true} length={6} onChange={setVerificationCode} value={verificationCode} />

              <div className="countdown_text text-center mt-3">
                {seconds > 0 || minutes > 0 ? (
                  <p className="mb-0 ">
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds} after
                  </p>
                ) : (
                  <p className="mb-0">Didn't recieve code?</p>
                )}
              </div>
              <div className="text-center">
                <button
                  className='set_otp_name_center mb-0 mt-1'
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#fe97b1" : "#EF3672",
                    cursor: seconds > 0 || minutes > 0 ? "not-allowed" : "pointer",
                    pointerEvents: seconds > 0 || minutes > 0 ? "none" : "auto",
                  }}
                  onClick={resendOTP}
                >Re-send code</button>
              </div>
            </>
            :
            ''
          }

          <div id="recaptcha-container-delete-account"></div>
          <div className=" text-center my-3">
            {deleteBtn ?
              <Button className="DeleteAccModal_Delete_btn" onClick={VerifyOTP}>Delete Account</Button>
              :
              <>
                <Button className="DeleteAccModal_Delete_btn" onClick={setOpenModelOtp}>Delete Account</Button>
              </>
            }
          </div>

          <div className=" text-center">
            <Button onClick={Close} className="DeleteAccModal_Cancel_btn">Cancel</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeleteAccModal