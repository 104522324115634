import React, { useEffect, useRef, useState } from 'react'
import "./UserEditPage.css"
import "../../Componet/Setting/DeleteAccModal.css"
import Header from '../../CommonComponet/Header'
import Footer from '../../CommonComponet/Footer'
import { HereToUpdateApi, MyProfileData, ProfilPageLocationDataApi, ProfileEditFilter, ProfileEditFilterPartner, ProfilePartnerFilterPost, ProfileSecondPageDataApi, ProfileUserInfoFilterPost, UserCategoryUpdate, UserDetailsUpdateApi, UserInterestsUpadteApi } from '../../../api/Api'
import { useAuth } from '../../../middleware/AuthProvider';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import DataLoader from '../../../components/ScreenLoader/DataLoader'
import CropImageModal from '../../CommonComponet/CropImageModal'
import calendar from '../../../images/Calendar.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import FooterMobile from '../../CommonComponet/FooterMobile'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const UserEditPage = () => {

  const { AuthData, setAuthData } = useAuth()
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });
  const [image, setImage] = useState(null);
  const [isDataLoader, setisDataLoader] = useState(false);
  const [profileImg, SetProfileImg] = useState("")
  const [defaultUserCoverImage, setDefaultUserCoverImage] = useState(AuthData.thumbnail_image);
  const [countryIdToVerify, setCountryIdToVerify] = useState(false)

  const [activeTab, setActiveTab] = useState('yourInfo');


  useEffect(() => {
    document.title = "Edit My Profile On Hukup Australia"
  }, [])

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const coverImageRef = useRef();
  const showOpenCoverFileDialog = () => {
    coverImageRef.current.click();
  };

  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(file?.type)) {
      SetProfileImg("");
      setDefaultUserCoverImage(AuthData.thumbnail_image)
      Store.addNotification({
        message: "Only JPG and PNG Image allowed",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return;
    }
    setCrop({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });
    setSrc(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (profileImg) {
      const objectURL = URL.createObjectURL(profileImg);
      setDefaultUserCoverImage(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [profileImg]);

  const formatDate = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = date.getDate().toString().padStart(2, '0');

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  useEffect(() => {
    MyProfileEditFiterData();
    MyProfileApi();
  }, []);

  const [userName, setUserName] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [about, SetAbout] = useState("");
  const [categoriesfiledvalue, setCategoriesfiledvalue] = useState("");

  const [userNameError, setUserNameError] = useState('');
  const [partnerNameError, setPartnerNameError] = useState('');
  const [locationError, setLocationError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [aboutError, SetAboutError] = useState("");

  const [editfilter, setEditfilter] = useState([])
  const [editpartnerfilter, setEditpartnerfilter] = useState([])

  const [userInfoFilterdata, setUserInfoFilterdata] = useState({})
  const [userInfoFilterdataError, setUserInfoFilterdataError] = useState({})

  const [userPartnerFilterdata, setUserPartnerFilterdata] = useState({})
  const [userPartnerFilterdataError, setUserPartnerFilterdataError] = useState({})

  const [categoryId, setCategoryId] = useState("")
  const [isCouple, SetIsCouple] = useState("")

  const [interest, setinterest] = useState([]);
  const [selectinterest, setSelectinterest] = useState([]);

  const [category, setCategory] = useState([]);
  const [makeanewfriend, setmakeanewfriend] = useState([]);

  const [selectmakeanewfriend, setSelectmakeanewfriend] = useState(AuthData.i_am_here_to ? AuthData.i_am_here_to?.split(", ") : []);
  const [selectmakeanewfriendError, setSelectmakeanewfriendError] = useState('');

  const [locationdata, setLocationdata] = useState([]);
  const [locplaceholder, SetLocplaceholder] = useState("")


  const HereToUpdate = () => {

    setSelectmakeanewfriendError("");
    var isvalid = true
    if (selectmakeanewfriend.length === 0) {
      setSelectmakeanewfriendError("Please at least One Select");
      isvalid = false;
    }

    if (isvalid) {
      setisDataLoader(true)
      setSelectmakeanewfriendError("");
      var data = {
        i_am_here_to: selectmakeanewfriend.join(', ')
      }
      HereToUpdateApi(data).then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          return false;
        }
      })
        .catch(function (error) {
          setisDataLoader(false)
          console.log(error);
        });
    }

  }

  const MyProfileApi = () => {
    MyProfileData()
      .then(function (response) {
        setDefaultUserCoverImage(response.data.data.thumbnail_image)
        setUserName(response.data.data.name ? response.data.data.name : "")
        setPartnerName(response.data.data.partner_details ? response.data.data.partner_details.partner_name : "")
        SetIsCouple(response.data.data.is_couple == 1 ? true : false)
        if (response.data.data.is_couple) {
          myprofileFilterPartnerData()
        }
        setDateOfBirth(new Date(response.data.data.date_of_birth))
        setEmail(response.data.data.email ? response.data.data.email : "")


        setLocation(response.data.data.location ? response.data.data.location : response.data.data.country_name)
        SetAbout(response.data.data.description ? response.data.data.description : "")
        setCategoriesfiledvalue(response.data.data.gender_id);
        setCategoryId(response.data.data.category)
        setSelectinterest(response.data.data.interest.map((e) => {
          return e.id
        }))
        setSelectmakeanewfriend(response.data.data.i_am_here_to ? response.data.data.i_am_here_to?.split(", ") : [])



        if (response.data.data.is_show_suburb) {
          setCountryIdToVerify(false)
          SetLocplaceholder("Suburb*");
        } else {
          setCountryIdToVerify(true)
          SetLocplaceholder("Location*");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const validation = () => {
    Errorclear();
    var isvalid = true
    if (userName == "" || !userName || userName?.trim() == "") {
      setUserNameError("Please Enter Name");
      isvalid = false;
    }
    if (AuthData.is_couple) {
      if (partnerName == "" || !partnerName || partnerName?.trim() == "") {
        setPartnerNameError("Please Enter Partner Name");
        isvalid = false;
      }
    }
    if (location == "" || !location || location?.trim() == "") {
      setLocationError("Please Enter Location");
      isvalid = false;
    }
    if (!email == "") {
      const emailRegex =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
      if (!emailRegex) {
        setEmailError("Please Enter Vaild Email");
        isvalid = false;
      }
    }

    if (dateOfBirth == "" || !dateOfBirth) {
      setDateOfBirthError("Please Enter Date of Birth");
      isvalid = false;
    } else {
      const currentDate = new Date();
      const selectedDateObj = new Date(dateOfBirth);
      currentDate.setHours(0)
      currentDate.setMinutes(0)
      currentDate.setSeconds(0)
      selectedDateObj.setHours(0)
      selectedDateObj.setMinutes(0)
      selectedDateObj.setSeconds(0)

      // Calculate the minimum date of birth for an 18-year-old
      const minDateOfBirth = new Date(currentDate);
      minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - 18);
      minDateOfBirth.setDate(minDateOfBirth.getDate() - 1);

      if (selectedDateObj > minDateOfBirth) {
        // Check if the selected date is at least 18 years ago
        setDateOfBirthError("Age is must be 18+");
        isvalid = false;
      }
    }
    return isvalid;
  }

  const Errorclear = (e) => {
    setUserNameError("");
    setPartnerNameError("");
    setLocationError("");
    setEmailError("");
    setDateOfBirthError("");
    SetAboutError("");
  };

  const userUpdateData = () => {
    setisDataLoader(true)
    if (!validation()) {
      setisDataLoader(false)
      return false;
    }
    const formData = new FormData()
    formData.append("name", userName)
    if (isCouple) {
      formData.append("partner_name", partnerName)
    }
    formData.append("location", location)
    formData.append("email", email)
    formData.append("date_of_birth", formatDate(dateOfBirth))
    formData.append("about", about?.trim())
    if (profileImg != "") {
      formData.append("profile", profileImg, "user-" + AuthData.id + ".png")
    }
    UserDetailsUpdateApi(formData).then(function (response) {
      setisDataLoader(false)
      if (response.data.status == true) {
        setAuthData(response.data.data)
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      })
  }

  const [selectinterestError, setSelectinterestError] = useState('');

  const secondPageValidation = () => {
    secondPageErrorclear();
    var isvalid = true
    if (selectinterest.length === 0) {
      setSelectinterestError("Please select at least one interest");
      isvalid = false;
    }
    return isvalid;
  }

  const secondPageErrorclear = (e) => {
    setSelectinterestError("");
  };


  useEffect(() => {
    ProfileSecondPageDataApi().then(function (response) {
      setinterest(response.data.data.interest)
      setCategory(response.data.data.categories)
      setmakeanewfriend(response.data.data.purpose)

    })
      .catch(function (error) {
        console.log(error);
      });

    ProfilPageLocationData()
  }, [])


  const ProfilPageLocationData = () => {
    ProfilPageLocationDataApi().then(function (response) {
      setLocationdata(response.data.data);
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const interestClick = (type) => {
    if (selectinterest.find((ele) => ele == type)) {
      setSelectinterest(selectinterest.filter((ele) => type != ele));
    } else {
      setSelectinterest(selectinterest.concat([type]));
    }
  };

  const userInterests = () => {
    setisDataLoader(true)
    if (!secondPageValidation()) {
      setisDataLoader(false)
      return false;
    }
    var data = {
      interest: selectinterest,
    }
    UserInterestsUpadteApi(data).then(function (response) {
      setisDataLoader(false)
      setAuthData(response.data.data)
      if (response.data.status == true) {
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        // handle error
        console.log(error);
      })
  }

  const userCategory = () => {

    setisDataLoader(true)
    var data = {
      category: categoryId
    }
    UserCategoryUpdate(data).then(function (response) {
      setisDataLoader(false)
      if (response.data.status == true) {
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        // handle error
        console.log(error);
      })
  }


  const MyProfileEditFiterData = () => {
    ProfileEditFilter()
      .then(function (response) {
        var oldData = {};
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index];
          if (element.field_value != '') {
            oldData[element.id] = element.field_value.split(",")
          }
          else {
            oldData[element.id] = []
          }
        }
        setUserInfoFilterdata(oldData);
        setEditfilter(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const myprofileFilterPartnerData = () => {
    ProfileEditFilterPartner()
      .then(function (response) {
        var oldData = {};
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index];
          if (element.field_value != '') {
            oldData[element.id] = element.field_value.split(",")
          }
          else {
            oldData[element.id] = []
          }
        }
        setUserPartnerFilterdata(oldData);
        setEditpartnerfilter(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ======== user info data =========

  const userInfoFilterPost = () => {
    var newFilter = [];
    for (const key in userInfoFilterdata) {
      if (userInfoFilterdata[key].length) {
        newFilter.push({ field_id: key, field_value: userInfoFilterdata[key] })
      }
    }
    var isValid = true;
    var errorMessages = {};

    let firstErrorField = null;

    for (let index = 0; index < editfilter.length; index++) {
      const element = editfilter[index];
      if (element.validation) {
        var checkRequired = newFilter.find((ele) => {
          return ele.field_id == element.id;
        });
        if (!checkRequired) {
          isValid = false;
          errorMessages = { ...errorMessages, [element.id]: "Please select this field" };
          if (firstErrorField === null) {
            firstErrorField = element.id;
          }
        }
      }
    }

    // Scroll to and focus on the first required field, if it exists
    if (firstErrorField !== null) {
      const firstErrorElement = document.getElementById(firstErrorField);
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    setUserInfoFilterdataError(errorMessages);
    if (!isValid) {
      Store.addNotification({
        message: "Fill all the required fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return false;
    }

    setisDataLoader(true)
    var data = {
      user_detail: newFilter,
    };

    ProfileUserInfoFilterPost(data)
      .then(function (response) {
        setisDataLoader(false)
        if (response.data.status == true) {
          Store.addNotification({
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });

  }


  const handleCheckboxChange = (e) => {
    let { checked, name, value } = e.target;
    value = value.trim();

    if (checked) {
      if (userInfoFilterdata[name]) {
        setUserInfoFilterdata({ ...userInfoFilterdata, [name]: userInfoFilterdata[name].concat([value]) })
      }
      else {
        setUserInfoFilterdata({ ...userInfoFilterdata, [name]: [value] })
      }
    } else {
      if (userInfoFilterdata[name]) {
        var data = userInfoFilterdata[name]?.filter((ele) => {
          return ele != value
        })
        setUserInfoFilterdata({ ...userInfoFilterdata, [name]: data })
      }
      else {
        setUserInfoFilterdata({ ...userInfoFilterdata, [name]: [] })
      }
    }
  };

  const handleRadioChange = (e) => {
    let { name, value } = e.target;
    value = value.trim();
    setUserInfoFilterdata({ ...userInfoFilterdata, [name]: [value] })
  }

  const handleRangeChange = (field_id, value) => {
    value = value.trim();
    setUserInfoFilterdata({ ...userInfoFilterdata, [field_id]: [value] })
  }

  const getValue = (id, min_range) => {
    if (userInfoFilterdata[id]) {
      return userInfoFilterdata[id][0];
    } else {
      return min_range
    }
  }

  const handleSelectChange = (value, field_id) => {
    value = value.trim();
    if (value == "") {
      setUserInfoFilterdata({ ...userInfoFilterdata, [field_id]: [] });
    }
    else {
      setUserInfoFilterdata({ ...userInfoFilterdata, [field_id]: [value] });
    }
  }

  const handleTextChange = (e) => {
    let { value, id } = e.target;
    value = value.trim();
    userInfoFilterdata[id] = [value];
    setUserInfoFilterdata({ ...userInfoFilterdata, [id]: [value] })
  }

  const ckeckIfChecked = (field_id, value) => {
    value = value.trim();
    if (userInfoFilterdata[field_id]) {
      return userInfoFilterdata[field_id]?.includes(value)
    }
    return false;
  }

  const cropImageNow = () => {
    if (!crop.width || !crop.height) {
      return false;
    }

    if (crop.height < 20 || crop.width < 20) {
      Store.addNotification({
        message: "Notification",
        message: "Please select large area",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return false;
    }

    const canvas = document.createElement('canvas');
    // console.log("image.naturalWidth", image.naturalWidth);
    // console.log("image.naturalHeight", image.naturalHeight);
    // console.log("image.width", image.width);
    // console.log("image.height", image.height);
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = scaleX * crop.width * pixelRatio;
    canvas.height = scaleY * crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    setDefaultUserCoverImage(URL.createObjectURL(blob))
    SetProfileImg(blob);
    setSrc(null);
  };

  const handleMultiSelect = (id, e) => {
    const selectedValues = e.map(option => option.value);
    setUserInfoFilterdata({ ...userInfoFilterdata, [id]: selectedValues })
  }

  const multiSelectSelected = (id, value) => {
    if (userInfoFilterdata[id]) {
      return userInfoFilterdata[id]?.includes(value);
    }
    return false;
  }

  const clearAllSelectValue = () => {
    editfilter.map((ele) => {
      setUserInfoFilterdata((pre) => {
        return { ...pre, [ele.id]: [] }
      })
    })
  }

  // ===  partner info ========

  const userpartnerFilterPost = () => {

    var newFilter = [];
    for (const key in userPartnerFilterdata) {
      if (userPartnerFilterdata[key].length) {
        newFilter.push({ field_id: key, field_value: userPartnerFilterdata[key] })
      }
    }
    var isValid = true;
    var errorMessages = {};

    let firstErrorFieldpartner = null;

    for (let index = 0; index < editpartnerfilter.length; index++) {
      const element = editpartnerfilter[index];
      if (element.validation) {
        var checkRequired = newFilter.find((ele) => {
          return ele.field_id == element.id;
        });
        if (!checkRequired) {
          isValid = false;
          errorMessages = { ...errorMessages, [element.id]: "Please select this field" };
          if (firstErrorFieldpartner === null) {
            firstErrorFieldpartner = "partner_" + element.id;
          }
        }
      }
    }

    if (firstErrorFieldpartner !== null) {
      const firstErrorElementpartner = document.getElementById(firstErrorFieldpartner);
      if (firstErrorElementpartner) {
        firstErrorElementpartner.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    setUserPartnerFilterdataError(errorMessages);

    if (!isValid) {
      Store.addNotification({
        message: "Fill all the required fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return false;
    }

    setisDataLoader(true)

    var data = {
      user_detail: newFilter,
    };

    ProfilePartnerFilterPost(data)
      .then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
      .catch(function (error) {
        setisDataLoader(false)
        console.log(error);
      });
  }

  const handleCheckboxChangePartner = (e) => {
    let { checked, name, value } = e.target;
    value = value.trim();

    if (checked) {
      if (userPartnerFilterdata[name]) {
        setUserPartnerFilterdata({ ...userPartnerFilterdata, [name]: userPartnerFilterdata[name].concat([value]) })
      }
      else {
        setUserPartnerFilterdata({ ...userPartnerFilterdata, [name]: [value] })
      }
    } else {
      if (userPartnerFilterdata[name]) {
        var data = userPartnerFilterdata[name]?.filter((ele) => {
          return ele != value
        })
        setUserPartnerFilterdata({ ...userPartnerFilterdata, [name]: data })
      }
      else {
        setUserPartnerFilterdata({ ...userPartnerFilterdata, [name]: [] })
      }
    }
  };

  const handleRadioChangePartner = (e, id) => {
    let { value } = e.target;
    value = value.trim();
    setUserPartnerFilterdata({ ...userPartnerFilterdata, [id]: [value] })
  }

  const handleRangeChangePartner = (field_id, value) => {
    value = value.trim();
    setUserPartnerFilterdata({ ...userPartnerFilterdata, [field_id]: [value] })
  }

  const handleSelectChangePartner = (value, field_id) => {
    value = value.trim();
    if (value == "") {
      setUserPartnerFilterdata({ ...userPartnerFilterdata, [field_id]: [] });
    }
    else {
      setUserPartnerFilterdata({ ...userPartnerFilterdata, [field_id]: [value] });
    }
  }

  const handleMultiSelectPartner = (id, e) => {
    const selectedValues = e.map(option => option.value);
    setUserPartnerFilterdata({ ...userPartnerFilterdata, [id]: selectedValues })
  }

  const multiSelectSelectedPartner = (id, value) => {
    if (userPartnerFilterdata[id]) {
      return userPartnerFilterdata[id]?.includes(value);
    }
    return false;
  }


  const ckeckIfCheckedPartner = (field_id, value) => {
    value = value.trim();
    if (userPartnerFilterdata[field_id]) {
      return userPartnerFilterdata[field_id]?.includes(value)
    }
    return false;
  }

  const clearAllPartnerSelectValue = () => {
    editpartnerfilter.map((ele) => {
      setUserPartnerFilterdata((pre) => {
        return { ...pre, [ele.id]: [] }
      })
    })
  }

  const makeanewfriendClick = (type) => {
    if (selectmakeanewfriend?.find((ele) => ele == type)) {
      setSelectmakeanewfriend(
        selectmakeanewfriend?.filter((ele) => type != ele)
      );
    } else {
      setSelectmakeanewfriend(selectmakeanewfriend?.concat([type.toString()]));
    }
  };


  return (
    <>

      <Helmet>
        <meta name="description" content={"My Edit Profile On Hukup Australia"} />
      </Helmet>

      {isDataLoader && (
        <DataLoader />
      )}

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img alt='orangeline' src={require("../../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../../src/images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        <Header />
        <div className="user_Detailspage_main_section">
          <div className="container ">
            <div className="row mt-4">

              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4  ">
                <div className="row">
                  <div className="col-12 position-relative  ">
                    <div className="mx-auto user_Detailspage_user_img_section">
                      <img alt='Profile' src={defaultUserCoverImage} className="user_Detailspage_user_img" />
                    </div>
                    <div className="camera_icon_main_section  ">
                      <div className="position-relative  ">
                        <input
                          type="file"
                          accept="image/*"
                          ref={coverImageRef}
                          onChange={handleCoverChange}
                          className="d-none"
                        />
                        <button
                          className="p-0 userEditPage_camera_btn"
                          onClick={showOpenCoverFileDialog}
                        >
                          <img alt='Edit' src={require("../../../images/UserViewAndEdit/CameraIconEdit.png")} className="camera_icon_img" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12  ">
                    <div className="text-center mx-auto mt-4" >
                      <div className="row">
                        <div className="col-9 mx-auto">
                          <div className="form-group mb-2 mb-md-3">
                            <input type="text" placeholder=" " id="useredit_page_input" value={userName} onChange={(e) => setUserName(e.target.value?.trimStart())} />
                            <label for="name" id="useredit_page_input_lable">Name *</label>
                            <span className="errorclassNew">{userNameError}</span>
                          </div>
                          {
                            isCouple && (
                              <div className="form-group mb-2 mb-md-3">
                                <input type="text" placeholder=" " id="useredit_page_input" value={partnerName} onChange={(e) => setPartnerName(e.target.value?.trimStart())} />
                                <label for="name" id="useredit_page_input_lable">Partner name *</label>
                                <span className="errorclassNew">{partnerNameError}</span>
                              </div>
                            )
                          }
                          <div className="form-group mb-2 mb-md-3">
                            <DatePicker
                              disabled
                              type="date"
                              showIcon
                              icon={
                                <img alt='Calander' src={calendar} className='new_calender'></img>
                              }
                              selected={dateOfBirth}
                              dateFormat="dd/MM/yyyy"
                              placeholderText=""
                              id="useredit_page_input_date"
                              className='add_new_height'
                            />
                            <span className="errorclassNew">{dateOfBirthError}</span>
                          </div>
                          {/* <div className="form-group mb-2 mb-md-3">
                            <input type="text" placeholder=" " id="useredit_page_input" value={email} onChange={(e) => setEmail(e.target.value?.trimStart())} />
                            <label for="Email" id="useredit_page_input_lable">Email</label>
                            <span className="errorclassNew">{emailError}</span>
                          </div> */}

                          <div className="form-group mb-2 mb-md-3 ">
                            <input type="text"
                              placeholder=" "
                              list="browsers"
                              id="useredit_page_location"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              // disabled={countryIdToVerify ? true : false}
                              disabled={true}
                            />
                            <label for="Location" id="useredit_page_location_lable">{locplaceholder}</label>
                            <datalist id="browsers">
                              {locationdata.map((item) => {
                                return (
                                  <option value={item.name} />
                                )
                              })}
                            </datalist>
                            <span className="errorclassNew">{locationError}</span>
                          </div>
                          <div className="form-group mb-2 mb-md-3">
                            <textarea className="form-control" id="useredit_page_location" rows="2" placeholder=" "
                              value={about}
                              // onChange={(e) => SetAbout(e.target.value?.trimStart())}
                              onChange={(e) => {
                                const aboutValue = e.target.value?.trimStart();
                                if (aboutValue.length <= 2000) {
                                  SetAbout(aboutValue);
                                } else {
                                  Store.addNotification({
                                    message: "Enter only 2000 letters",
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animate__animated", "animate__zoomIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                      duration: 3000,
                                      onScreen: false
                                    }
                                  });
                                }
                              }}
                            ></textarea>
                            <label for="About" id="useredit_page_location_lable">About</label>
                            <span className="errorclassNew">{aboutError}</span>
                          </div>
                          <button className='save_update_data mb-auto mb-md-3' onClick={(e) => userUpdateData()}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ======= user details section ============= */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8  pr-0 pl-0 pl-sm-0 pl-md-0 pl-lg-5 pl-xl-5 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                <div className="user_all_Details_secondsection  user_edit_details_pageseco">

                  <h1 className="user_Detailspage_About user_edit_page_interest_color">Category</h1>
                  <div className="d-flex flex-wrap">
                    {category.filter((item) => item.gender_id == categoriesfiledvalue).map((e) => {
                      return (
                        <div className="form-check mr-3" key={e.id}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="e"
                            id={e.name}
                            value={categoryId}
                            checked={parseInt(categoryId) == e.id}
                            onChange={(ele) => setCategoryId(e.id)}
                          />
                          <label className="form-check-label" for={e.name}>
                            {e.name}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                  <div className='text-center my-2'>
                    <button className='save_update_data' onClick={(e) => userCategory()}>
                      Save
                    </button>
                  </div>

                  <hr />

                  <div className="">
                    <h5 className="user_Detailspage_About user_edit_page_interest_color">You are here to</h5>
                    <div className="mb-3 ">
                      <div className="d-flex justify-content-center">
                        <Col
                          xs={12}
                          className="d-block d-md-flex p-0 "
                        >
                          <Row className='mx-0'>
                            {makeanewfriend.map((item) => {
                              return (
                                <div
                                  className={
                                    selectmakeanewfriend?.includes(item.id.toString())
                                      ? "borderclass mx-1 my-1 cursor-pointer selecteditem"
                                      : "borderclass mx-1 my-1 cursor-pointer unselecteditem"
                                  }
                                  onClick={(e) => makeanewfriendClick(item.id)}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="mx-1 mr-2">{item.name}</div>
                                  </div>
                                </div>
                              );
                            })}
                            <span className="errorclassNew">{selectmakeanewfriendError}</span>

                          </Row>
                        </Col>
                      </div>
                      <div className='text-center mt-2'>
                        <button className='save_update_data' onClick={() => HereToUpdate()} >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <h1 className="user_Detailspage_About user_edit_page_interest_color" >Interests</h1>
                  <div className="d-flex flex-wrap">
                    {interest.map((item) => {
                      return (
                        <div
                          className={
                            selectinterest?.includes(item.id)
                              ?
                              "borderclass mr-2 my-1 cursor-pointer selecteditem"
                              :
                              "borderclass mr-2 my-1 cursor-pointer unselecteditem"
                          }
                          onClick={(e) => interestClick(item.id)}
                        >
                          <div className="d-flex align-items-center">
                            {selectinterest?.includes(item.id) ? (
                              <img alt='Interest' className="iconintrest" src={item.image} />
                            ) : (
                              <img alt='Interest' className="iconintrest" src={item.image} />
                            )}
                            <span className="mx-1 mr-2">{item.name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <span className="errorclassNew">{selectinterestError}</span>
                  <div className='text-center mt-2'>
                    <button className='save_update_data' onClick={(e) => userInterests()}>
                      Save
                    </button>
                  </div>

                  <hr />

                  {/* ======== Partner info ==========  */}
                  {
                    AuthData.is_couple ?
                      <div className="mt-4 container px-0 ">
                        <div className="tab_container_main">
                          <div className="tab_header">
                            <div
                              className={`tab_item ${activeTab === 'yourInfo' ? 'active' : ''}`}
                              onClick={() => handleTabClick('yourInfo')}
                            >
                              {AuthData.is_mf ? "Male Info" : "Your Info"}
                            </div>
                            <div
                              className={`tab_item ${activeTab === 'partnerInfo' ? 'active' : ''}`}
                              onClick={() => handleTabClick('partnerInfo')}
                            >
                              {AuthData.is_mf ? "Female Info" : "Partner Info"}
                            </div>
                          </div>
                          <div className="tab_content">
                            <div className={`tab_panel ${activeTab === 'yourInfo' ? 'active' : ''}`}>
                              <div className={"container"}>
                                <div className="row">
                                  {
                                    editfilter.map((item) => {
                                      if (item.type == "checkbox") {
                                        return (<>
                                          <div className="px-0" >
                                            <h5 className="mt-3 user_edit_filter_cheakbox_header">
                                              {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                            </h5>
                                            <div className=" my-1">
                                              <div className="d-flex flex-wrap">
                                                {

                                                  item.options.split(",").map((newdata) => {
                                                    return (
                                                      <>
                                                        <div className={`form-check d-flex align-items-center user_edit_filter_cheakbox_main_section`} id={item.id} >
                                                          <input
                                                            className="form-check-input cursor_pointer_common"
                                                            type="checkbox"
                                                            value={newdata}
                                                            name={item.id}
                                                            id={newdata + "_" + item.id}
                                                            onChange={handleCheckboxChange}
                                                            checked={userInfoFilterdata[item.id]?.includes(newdata)}
                                                          />
                                                          <label className="form-check-label cursor_pointer_common mt-1" for={newdata + "_" + item.id}>
                                                            {newdata}
                                                          </label>
                                                        </div>
                                                      </>
                                                    )
                                                  })
                                                }
                                              </div>
                                            </div>
                                            <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                          </div>
                                        </>)
                                      }

                                      if (item.type == "radio_button") {
                                        return (
                                          <>
                                            <div className="px-0" id={item.id}>
                                              <h5 className="mt-4 mb-0 user_edit_filter_cheakbox_header">
                                                {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                              </h5>
                                              <div className="d-flex ">
                                                <div className="my-1">
                                                  <div className="d-flex flex-wrap">
                                                    {
                                                      item.options.split(",").map((radiodata) => {
                                                        return (
                                                          <>
                                                            <div className="form-check ml-0 mr-3 d-flex align-items-center">
                                                              <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name={item.id}
                                                                id={radiodata + "_" + item.id}
                                                                value={radiodata}
                                                                onChange={handleRadioChange}
                                                                checked={userInfoFilterdata[item.id]?.includes(radiodata)}
                                                              />
                                                              <label className="form-check-label mt-1" for={radiodata + "_" + item.id} >
                                                                {radiodata}
                                                              </label>
                                                            </div>
                                                          </>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                              <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                            </div>
                                          </>
                                        )
                                      }
                                      if (item.type == "range") {
                                        return (
                                          <>
                                            {
                                              <>
                                                <div className="px-0" id={item.id}>
                                                  <h5 className="mt-3 user_edit_filter_cheakbox_header">
                                                    {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                                  </h5>
                                                  <div className="">
                                                    <p className="mb-0 offcanvas_height_value">{userInfoFilterdata[item.id]?.length ? userInfoFilterdata[item.id]?.[0] : item.min_range} {item.name}</p>
                                                    <input type="range" min={item.min_range} max={item.max_range} className="w-100"
                                                      onChange={(e) => handleRangeChange(item.id, e.target.value)} value={userInfoFilterdata[item.id]?.length != "" ? userInfoFilterdata[item.id]?.[0] : item.min_range}
                                                    />
                                                  </div>
                                                  <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                                </div>
                                              </>
                                            }
                                          </>
                                        )
                                      }

                                      if (item.type == "textbox") {
                                        return (
                                          <>
                                            <div className="">
                                              <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                                                {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                              </h5>
                                              <div className="mx-3 my-3">
                                                <input type="text" id={item.id} placeholder="Enter Religion"
                                                  onChange={handleTextChange} value={userInfoFilterdata[item.id]?.[0]} className="form-control offcanvas_filter_input " />
                                              </div>
                                              <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                            </div>
                                          </>
                                        )
                                      }
                                      if (item.type == "select" && item.multi_select) {
                                        const dynamicOptions = []
                                        var labelvalue = item.options.split(",")
                                        labelvalue.map((newitem) => {
                                          var data = { id: item.id, value: newitem, label: newitem }
                                          dynamicOptions.push(data)
                                        })
                                        return (
                                          <>
                                            <div className="col-12 col-md-12 col-lg-8 px-0 user_edit_multi_select" id={item.id}>
                                              <div className="d-flex flex-column mt-3">
                                                <h5 className="mb-2  user_edit_filter_cheakbox_header">
                                                  {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}</h5>
                                                <Select
                                                  options={dynamicOptions}
                                                  placeholder="Select Option"
                                                  value={dynamicOptions.filter(option => multiSelectSelected(item.id, option.value))}
                                                  onChange={(e) => handleMultiSelect(item.id, e)}
                                                  isSearchable={false}
                                                  isMulti
                                                  isClearable={false}
                                                />
                                                <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                      if (item.type == "select" && !item.multi_select) {
                                        return (
                                          <>
                                            <div className="col-6 col-md-6 col-lg-4 px-0" id={item.id}>
                                              <div className="d-flex flex-column mt-3">
                                                <h5 className="mb-2  user_edit_filter_cheakbox_header">
                                                  {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}</h5>
                                                <select onChange={(e) => handleSelectChange(e.target.value, item.id)} className="form-select offcanvas_filter_selector">
                                                  <option value="">Select Option</option>
                                                  {
                                                    item.options.split(",").map((seldata) => {
                                                      return (
                                                        <>
                                                          <option
                                                            value={seldata}
                                                            selected={ckeckIfChecked(item.id, seldata)}
                                                          >
                                                            {seldata}
                                                          </option>
                                                        </>
                                                      )
                                                    })
                                                  }
                                                </select>
                                                <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                  }
                                </div>
                                <div className="row my-4">
                                  <div className="col-12 text-center">
                                    <button className="save_update_data" onClick={(e) => userInfoFilterPost()}>
                                      Save
                                    </button>
                                    <button className="save_update_data ml-4" onClick={clearAllSelectValue}>
                                      Clear All
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={`tab_panel ${activeTab === 'partnerInfo' ? 'active' : ''}`}>
                              <div className={"container"}>
                                <div className="row">
                                  {
                                    editpartnerfilter.map((item) => {
                                      if (item.type == "checkbox") {
                                        return (<>
                                          <div className="px-0" >
                                            <h5 className="mt-3 user_edit_filter_cheakbox_header">
                                              {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                            </h5>
                                            <div className=" my-1">
                                              <div className="d-flex flex-wrap">
                                                {

                                                  item.options.split(",").map((newdata) => {
                                                    return (
                                                      <>
                                                        <div className={`form-check d-flex align-items-center user_edit_filter_cheakbox_main_section`} id={"partner_" + item.id} >
                                                          <input
                                                            className="form-check-input cursor_pointer_common"
                                                            type="checkbox"
                                                            value={newdata}
                                                            name={item.id}
                                                            key={newdata + "_partner_" + item.id}
                                                            id={newdata + "_partner_" + item.id}
                                                            onChange={handleCheckboxChangePartner}
                                                            checked={userPartnerFilterdata[item.id]?.includes(newdata)}
                                                          />
                                                          <label className="form-check-label cursor_pointer_common mt-1" for={newdata + "_partner_" + item.id}>
                                                            {newdata}
                                                          </label>
                                                        </div>
                                                      </>
                                                    )
                                                  })
                                                }
                                              </div>
                                            </div>
                                            <span className="errorclassNew">{userPartnerFilterdataError[item.id]}</span>
                                          </div>
                                        </>)
                                      }

                                      if (item.type == "radio_button") {
                                        return (
                                          <>
                                            <div className="px-0" id={"partner_" + item.id} key={"partner_" + item.id}>
                                              <h5 className="mt-4 mb-0 user_edit_filter_cheakbox_header">
                                                {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                              </h5>
                                              <div className="d-flex ">
                                                <div className="my-1">
                                                  <div className="d-flex flex-wrap">
                                                    {
                                                      item.options.split(",").map((rdata) => {
                                                        var ids = rdata + "_partner_" + item.id;
                                                        return (
                                                          <div className="form-check ml-0 mr-3 d-flex align-items-center" key={rdata + "_partner_" + item.id} >
                                                            <input
                                                              className="form-check-input"
                                                              type="radio"
                                                              name={"partner_" + item.id}
                                                              key={rdata + "_partner_" + item.id}
                                                              id={ids}
                                                              value={rdata}
                                                              onChange={
                                                                (e) =>
                                                                  handleRadioChangePartner(e, item.id)}
                                                              checked={userPartnerFilterdata[item.id]?.includes(rdata)}
                                                            />
                                                            <label className="form-check-label mt-1" for={ids}>
                                                              {rdata}
                                                            </label>
                                                          </div>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                              <span className="errorclassNew">{userPartnerFilterdataError[item.id]}</span>
                                            </div>
                                          </>
                                        )
                                      }
                                      if (item.type == "range") {
                                        return (
                                          <>
                                            {
                                              <>
                                                <div className="px-0" id={"partner_" + item.id}>
                                                  <h5 className="mt-3 user_edit_filter_cheakbox_header">
                                                    {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                                  </h5>
                                                  <div className="">
                                                    <p className="mb-0 offcanvas_height_value">{userPartnerFilterdata[item.id]?.length != '' ? userPartnerFilterdata[item.id]?.[0] : item.min_range} {item.name}</p>
                                                    <input type="range" min={item.min_range} max={item.max_range} className="w-100"
                                                      onChange={(e) => handleRangeChangePartner(item.id, e.target.value)} value={userPartnerFilterdata[item.id]?.length ? userPartnerFilterdata[item.id]?.[0] : item.min_range}
                                                    />
                                                  </div>
                                                  <span className="errorclassNew">{userPartnerFilterdataError[item.id]}</span>
                                                </div>
                                              </>
                                            }
                                          </>
                                        )
                                      }

                                      if (item.type == "textbox") {
                                        return (
                                          <>
                                            <div className="">
                                              <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                                                {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                              </h5>
                                              <div className="mx-3 my-3">
                                                <input type="text" id={"partner_" + item.id} placeholder="Enter Religion"
                                                  onChange={handleTextChange} value={userPartnerFilterdata[item.id] ?? [0]} className="form-control offcanvas_filter_input " />
                                              </div>
                                              <span className="errorclassNew">{userPartnerFilterdataError[item.id]}</span>
                                            </div>
                                          </>
                                        )
                                      }
                                      if (item.type == "select" && !item.multi_select) {
                                        return (
                                          <>
                                            <div className="col-6 col-md-6 col-lg-4 px-0" id={"partner_" + item.id}>
                                              <div className="d-flex flex-column mt-3">
                                                <h5 className="mb-2  user_edit_filter_cheakbox_header">
                                                  {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}</h5>
                                                <select onChange={(e) => handleSelectChangePartner(e.target.value, item.id)} className="form-select offcanvas_filter_selector">
                                                  <option value="">Select Option</option>
                                                  {
                                                    item.options.split(",").map((seldata) => {
                                                      return (
                                                        <>
                                                          <option
                                                            value={seldata}
                                                            selected={ckeckIfCheckedPartner(item.id, seldata, 'partner')}
                                                          >
                                                            {seldata}
                                                          </option>
                                                        </>
                                                      )
                                                    })
                                                  }
                                                </select>
                                                <span className="errorclassNew">{userPartnerFilterdataError[item.id]}</span>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                      if (item.type == "select" && item.multi_select) {
                                        const dynamicOptions = []
                                        var labelvalue = item.options.split(",")
                                        labelvalue.map((newitem) => {
                                          var data = { id: item.id, value: newitem, label: newitem }
                                          dynamicOptions.push(data)
                                        })
                                        return (
                                          <>
                                            <div className="col-12 col-md-12 col-lg-8 px-0 user_edit_multi_select" id={"partner_" + item.id}>
                                              <div className="d-flex flex-column mt-3">
                                                <h5 className="mb-2  user_edit_filter_cheakbox_header">
                                                  {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}</h5>
                                                <Select
                                                  options={dynamicOptions}
                                                  placeholder="Select Option"
                                                  value={dynamicOptions.filter(option => multiSelectSelectedPartner(item.id, option.value))}
                                                  onChange={(e) => handleMultiSelectPartner(item.id, e)}
                                                  isSearchable={false}
                                                  isMulti
                                                  isClearable={false}
                                                />
                                                <span className="errorclassNew">{userPartnerFilterdataError[item.id]}</span>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }

                                    })
                                  }
                                </div>
                                <div className="row my-4">
                                  <div className="col-12 text-center">
                                    <button className="save_update_data" onClick={(e) => userpartnerFilterPost()}>
                                      Save
                                    </button>
                                    <button className="save_update_data ml-4" onClick={clearAllPartnerSelectValue}>
                                      Clear All
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      //  == single info ============
                      <div className="mt-0 container">
                        <div className="row">
                          <h1 className="user_Detailspage_About p-0 user_edit_page_interest_color text-break mb-0 mt-1">Your Info</h1>
                        </div>
                        <div className="row">
                          {
                            editfilter.map((item) => {
                              if (item.type == "checkbox") {
                                return (<>
                                  <div className="px-0" >
                                    <h5 className="mt-3 user_edit_filter_cheakbox_header">
                                      {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                    </h5>
                                    <div className=" my-1">
                                      <div className="d-flex flex-wrap">
                                        {

                                          item.options.split(",").map((newdata) => {
                                            return (
                                              <>
                                                <div className={`form-check d-flex align-items-center user_edit_filter_cheakbox_main_section`} id={item.id} >
                                                  <input
                                                    className="form-check-input cursor_pointer_common"
                                                    type="checkbox"
                                                    value={newdata}
                                                    name={item.id}
                                                    id={newdata + "_" + item.id}
                                                    onChange={handleCheckboxChange}
                                                    checked={userInfoFilterdata[item.id]?.includes(newdata)}
                                                  />
                                                  <label className="form-check-label cursor_pointer_common mt-1" for={newdata + "_" + item.id}>
                                                    {newdata}
                                                  </label>
                                                </div>
                                              </>
                                            )
                                          })
                                        }
                                      </div>
                                    </div>
                                    <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                  </div>
                                </>)
                              }

                              if (item.type == "radio_button") {
                                return (
                                  <>
                                    <div className="px-0" id={item.id}>
                                      <h5 className="mt-4 mb-0 user_edit_filter_cheakbox_header">
                                        {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                      </h5>
                                      <div className="d-flex ">
                                        <div className="my-1">
                                          <div className="d-flex flex-wrap">
                                            {
                                              item.options.split(",").map((radiodata) => {
                                                return (
                                                  <>
                                                    <div className="form-check ml-0 mr-3 d-flex align-items-center">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={item.id}
                                                        id={radiodata + "_" + item.id}
                                                        value={radiodata}
                                                        onChange={handleRadioChange}
                                                        checked={userInfoFilterdata[item.id]?.includes(radiodata)}
                                                      />
                                                      <label className="form-check-label mt-1" for={radiodata + "_" + item.id} >
                                                        {radiodata}
                                                      </label>
                                                    </div>
                                                  </>
                                                )
                                              })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                    </div>
                                  </>
                                )
                              }
                              if (item.type == "range") {
                                return (
                                  <>
                                    {
                                      <>
                                        <div className="px-0" id={item.id}>
                                          <h5 className="mt-3 user_edit_filter_cheakbox_header">
                                            {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                          </h5>
                                          <div className="">
                                            <p className="mb-0 offcanvas_height_value">{userInfoFilterdata[item.id]?.length ? userInfoFilterdata[item.id]?.[0] : item.min_range} {item.name}</p>
                                            <input type="range" min={item.min_range} max={item.max_range}
                                              className="w-100"
                                              onChange={(e) => handleRangeChange(item.id, e.target.value)}
                                              value={userInfoFilterdata[item.id]?.length != '' ? userInfoFilterdata[item.id]?.[0] : item.min_range}
                                            />

                                          </div>
                                          <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                        </div>
                                      </>
                                    }
                                  </>
                                )
                              }

                              if (item.type == "textbox") {
                                return (
                                  <>
                                    <div className="">
                                      <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                                        {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}
                                      </h5>
                                      <div className="mx-3 my-3">
                                        <input type="text" id={item.id} placeholder="Enter Religion"
                                          onChange={handleTextChange} value={userInfoFilterdata[item.id]?.[0]} className="form-control offcanvas_filter_input " />
                                      </div>
                                      <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                    </div>
                                  </>
                                )
                              }
                              if (item.type == "select" && item.multi_select) {
                                const dynamicOptions = []
                                var labelvalue = item.options.split(",")
                                labelvalue.map((newitem) => {
                                  var data = { id: item.id, value: newitem, label: newitem }
                                  dynamicOptions.push(data)
                                })
                                return (
                                  <>
                                    <div className="col-12 col-md-12 col-lg-8 px-0 user_edit_multi_select" id={item.id}>
                                      <div className="d-flex flex-column mt-3">
                                        <h5 className="mb-2  user_edit_filter_cheakbox_header">
                                          {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}</h5>
                                        <Select
                                          options={dynamicOptions}
                                          placeholder="Select Option"
                                          value={dynamicOptions.filter(option => multiSelectSelected(item.id, option.value))}
                                          onChange={(e) => handleMultiSelect(item.id, e)}
                                          isSearchable={false}
                                          isMulti
                                          isClearable={false}
                                        />
                                        <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              if (item.type == "select" && !item.multi_select) {
                                return (
                                  <>
                                    <div className="col-6 col-md-6 col-lg-4 px-0" id={item.id}>
                                      <div className="d-flex flex-column mt-3">
                                        <h5 className="mb-2  user_edit_filter_cheakbox_header">
                                          {item.label}{item.validation ? <span className="userEdit_required_filde">*</span> : ""}</h5>
                                        <select onChange={(e) => handleSelectChange(e.target.value, item.id)} className="form-select offcanvas_filter_selector">
                                          <option value="">Select Option</option>
                                          {
                                            item.options.split(",").map((seldata) => {
                                              return (
                                                <>
                                                  <option
                                                    value={seldata}
                                                    selected={ckeckIfChecked(item.id, seldata)}
                                                  >
                                                    {seldata}
                                                  </option>
                                                </>
                                              )
                                            })
                                          }
                                        </select>
                                        <span className="errorclassNew">{userInfoFilterdataError[item.id]}</span>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            })
                          }
                        </div>
                        <div className="row my-4">
                          <div className="col-12 text-center">
                            <button className="save_update_data" onClick={(e) => userInfoFilterPost()}>
                              Save
                            </button>
                            <button className="save_update_data ml-4" onClick={clearAllSelectValue}>
                              Clear All
                            </button>
                          </div>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
      <CropImageModal
        src={src}
        setSrc={setSrc}
        crop={crop}
        setCrop={setCrop}
        image={image}
        setImage={setImage}
        cropImageNow={cropImageNow}
      />
    </>
  )
}

export default UserEditPage