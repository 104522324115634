import React, { useCallback, useRef, useState } from 'react'
import "../../css/Matched.css"
import Header from '../CommonComponet/Header'
import Footer from '../CommonComponet/Footer'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { FavoritesByYouApi, FavoritesYouApi } from '../../api/Api'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import defaultImg from "../../images/user-profile-default-image.png";
import { useAuth } from '../../middleware/AuthProvider';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Vector from "../../images/Subscriptionplan/Vector.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSocket } from '../../middleware/SocketProvider'
import MembershipModel from '../CommonComponet/MembershipModel'
import FooterMobile from '../CommonComponet/FooterMobile'
import { LuSend } from "react-icons/lu";
import { Helmet } from 'react-helmet'
import Userdetailcard from '../CommonComponet/Userdetailcard'


const Favourites = () => {
  const { AuthData } = useAuth();
  const { Socket } = useSocket();

  const [favYouData, SetFavYouData] = useState([])
  const [favYouEncryptedData, SetFavYouEncryptedData] = useState(false)

  const [favByYouData, SetFavByYouData] = useState([])
  const [favByYouEncryptedData, SetFavByYouEncryptedData] = useState(false)

  const [favMeTotal, SetFavMeTotal] = useState("")
  const [totalFavYou, SetTotalFavYou] = useState("")

  const [pageFavorites, setPageFavorites] = useState(1);
  const [pageFavoritesMy, setPageFavoritesMy] = useState(1);

  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingpvt, setIsLoadingpvt] = useState(false);

  const [hasMoreRecord, setHasMoreRecord] = useState(true);
  const [hasMoreRecordPvt, setHasMoreRecordPvt] = useState(true);

  const [membershipModelShow, setMembershipModelShow] = useState(false);


  const divRef = useRef(null);
  const divRefpvt = useRef(null);

  var girdItem = 0
  var girdItemMe = 0
  const [data, setData] = useState([])

  useEffect(() => {
    document.title = "Favourites Users On Hukup Australia"
  }, [])

  useEffect(() => {
    // Me
    favoritesYouApiFun()
    favoritesByYouApiFun()
    // YOU 

    return () => {
      Socket.off("favorite-change-" + AuthData.id)
    }
  }, [])

  // --- call to favoritesYou api  
  const favoritesYouApiFun = (page = pageFavorites) => {
    if (page != 1) {
      if (!hasMoreRecord || isLoading) {
        return false;
      }
    }
    var data = {
      page: page,
    };
    setIsLoading(true)
    FavoritesYouApi(data).then(function (response) {
      SetFavYouEncryptedData(response.data.encryptedData)
      SetFavMeTotal(response.data.totalFavourites)
      setIsLoading(false)
      setCardSkeleton(false)
      if (page == 1) {
        SetFavYouData(response.data.data)
      }
      else {
        SetFavYouData(favYouData.concat(response.data.data))
      }
      setPageFavorites(page + 1)
      var userDatas = response.data.data;
      if (!userDatas.length) {
        setHasMoreRecord(false);
      }
      else {
        setHasMoreRecord(true);
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  // --- call to favoritesByYou api  
  const favoritesByYouApiFun = (page = pageFavoritesMy) => {
    if (page != 1) {
      if (!hasMoreRecordPvt || isLoadingpvt) {
        return false;
      }
    }
    var data = {
      page: page,
    };
    setIsLoadingpvt(true)
    FavoritesByYouApi(data).then(function (response) {
      SetTotalFavYou(response.data.totalFavourites)
      SetFavByYouEncryptedData(response.data.encryptedData)
      setPageFavoritesMy(page + 1)
      setCardSkeletonMeUser(false)
      if (page == 1) {
        SetFavByYouData(response.data.data)
      } else {
        SetFavByYouData(favByYouData.concat(response.data.data))
      }
      setIsLoadingpvt(false)
      var userDatas = response.data.data;
      if (!userDatas.length) {
        setHasMoreRecordPvt(false);
      }
      else {
        setHasMoreRecordPvt(true);
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  //  handle to scroll to card --------
  const handleScroll = useCallback(() => {
    if (
      divRef.current &&
      divRef.current.scrollTop + divRef.current.clientHeight >=
      divRef.current.scrollHeight - 200 &&
      !isLoading
    ) {
      favoritesYouApiFun();
    }

  }, [isLoading, pageFavorites, favYouData, hasMoreRecord]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  // __________________________________
  const handleScrollFevImg = useCallback(() => {
    if (
      divRefpvt.current &&
      divRefpvt.current.scrollTop + divRefpvt.current.clientHeight >=
      divRefpvt.current.scrollHeight - 200 &&
      !isLoadingpvt
    ) {
      favoritesByYouApiFun();
    }

  }, [isLoadingpvt, pageFavoritesMy, favByYouData, hasMoreRecordPvt]);

  useEffect(() => {
    if (divRefpvt.current) {
      divRefpvt.current.addEventListener("scroll", handleScrollFevImg);
    }
    return () => {
      if (divRefpvt.current) {
        divRefpvt.current.removeEventListener("scroll", handleScrollFevImg);
      }
    };
  }, [handleScrollFevImg]);


  var FavouritesYouCount = <div className="Favourited_tab_title_name">Favourited Me<span className='pinkcustom'> {favMeTotal}</span></div>;
  var FavouritesByYouCount = <div className="Favourited_tab_title_name">My Favourite<span className='pinkcustom'> {totalFavYou}</span></div>;
  const [key, setKey] = useState('Favouritesyou');

  return (
    <>

      <Helmet>
        <meta name="description" content={"Favourites Users On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_first" />
          <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_second" />
          <img alt='smileemoji' src={require("../../../src/images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
          <img alt='CurveBlackline' src={require("../../../src/images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
          <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        <Header />

        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              {/* ====== User Name And User Image section ======== */}
              <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                <Userdetailcard /> 
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8   matched_section_contain">
                <div className="row  ">

                  <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="my-2 px-1"
                  >
                    <Tab eventKey="Favouritesyou" title={FavouritesYouCount} className="px-0 tab_liked_you">
                      <div className="col-12 Liked_section_slider" ref={divRef}>
                        {cardSkeleton && (
                          <SkeletonTheme >
                            <Row className="">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                                return (
                                  <Col xs={6} md={4} lg={3}>
                                    <Skeleton height={200} count={1} />
                                  </Col>
                                )
                              })}
                            </Row>
                          </SkeletonTheme>
                        )}
                        <div className="row row-cols-2 row-cols-lg-3 row-cols-md-3 row-cols-xl-5 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                          {
                            favYouData.map((item, index) => {
                              var gridClass = "";
                              if (window.innerWidth < 768) {
                                if (index == 0) {
                                  gridClass = "slider_img";
                                  girdItem = 0;
                                }
                                else {
                                  girdItem = girdItem + 1;
                                  if (girdItem == 3 || girdItem == 4) {
                                    gridClass = "slider_img";
                                  }
                                  if (girdItem == 4) {
                                    girdItem = 0;
                                  }
                                }
                              }
                              else {
                                gridClass = index % 2 === 0 ? "" : "slider_img";
                              }
                              return (
                                <FavProfileCard favoritesByYouApiFun={favoritesByYouApiFun} gridClass={gridClass} item={item} index={index} />
                              )
                            })
                          }
                          {/* ========== Favourited Me Fake Data =========== */}
                          {favYouEncryptedData && (() => {
                            const renderedFakeData = [];
                            for (let index = 0; index < favMeTotal - favYouData.length; index++) {
                              renderedFakeData.push(
                                <div key={index} className="card_main" onClick={() => setMembershipModelShow(true)}>
                                  <Fakedatacard setMembershipModelShow={setMembershipModelShow} />
                                </div>
                              );
                            }
                            return renderedFakeData;
                          })()}
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="FavouritesByyou" title={FavouritesByYouCount}>
                      <div className="col-12 Liked_section_slider" ref={divRefpvt}>
                        {cardSkeletonMeUser && (
                          <SkeletonTheme >
                            <Row className="">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                                return (
                                  <Col xs={6} md={4} lg={3}>
                                    <Skeleton height={200} count={1} />
                                  </Col>
                                )
                              })}
                            </Row>
                          </SkeletonTheme>
                        )}
                        <div className="row row-cols-2 row-cols-lg-3 row-cols-md-3 row-cols-xl-5 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                          {
                            favByYouData.map((item, index) => {
                              var gridClass = "";
                              if (window.innerWidth < 768) {
                                if (index == 0) {
                                  gridClass = "slider_img";
                                  girdItemMe = 0;
                                }
                                else {
                                  girdItemMe = girdItemMe + 1;
                                  if (girdItemMe == 3 || girdItemMe == 4) {
                                    gridClass = "slider_img";
                                  }
                                  if (girdItemMe == 4) {
                                    girdItemMe = 0;
                                  }
                                }
                              }
                              else {
                                gridClass = index % 2 === 0 ? "" : "slider_img";
                              }
                              return (
                                <ProfileCard gridClass={gridClass} item={item} index={index} favByYouData={favByYouData} SetFavByYouData={SetFavByYouData} totalFavYou={totalFavYou} SetTotalFavYou={SetTotalFavYou} />
                              )
                            })
                          }
                          {/* == My Favourite Fake Data ========  */}
                          {favByYouEncryptedData && (() => {
                            const UserrenderedFakeData = [];
                            for (let index = 0; index < totalFavYou - favByYouData.length; index++) {
                              UserrenderedFakeData.push(
                                <div key={index} className="card_main" onClick={() => setMembershipModelShow(true)}>
                                  <Fakedatacard setMembershipModelShow={setMembershipModelShow} />
                                </div>
                              );
                            }
                            return UserrenderedFakeData;
                          })()}
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>

      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />
    </>
  )
}

export default Favourites

// ==== Favourited Me =====
const FavProfileCard = (props) => {
  const { Socket } = useSocket();
  const { AuthData } = useAuth();
  const [isFav, SetIsFav] = useState(props.item.is_favourites)
  const navigate = useNavigate();
  const [isLike, SetIsLike] = useState(props.item.is_like)


  useEffect(() => {
    Socket.on("favorite-change-" + AuthData.id, (data) => {
      if (data.favourite_user_id == props.item.user_id) {
        SetIsFav(data.is_favourite)
      }
      props.favoritesByYouApiFun(1)
    })

    return () => {
      Socket.off("favorite-change-" + AuthData.id)
    }
  }, [props])

  const FavApi = () => {
    var favouriteData = {
      favourite_user_id: props.item.user_id,
    };
    Socket.off("favourites-response-" + props.item.user_id);
    Socket.emit("favourite", favouriteData);
    Socket.on("favourites-response-" + props.item.user_id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        SetIsFav(!isFav)
        props.favoritesByYouApiFun(1)
      }
    });
  };

  useEffect(() => {
    Socket.on("like-change-" + AuthData.id, (data) => {
      if (data.liked_user_id == props.item.user_id) {
        SetIsLike(data.is_like)
      }
    })
  }, [props])

  const LikeApi = () => {
    var likeData = {
      liked_user_id: props.item.user_id,
    };
    Socket.off("liked-response-" + props.item.user_id);
    Socket.emit("like", likeData);
    Socket.on("liked-response-" + props.item.user_id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        SetIsLike(!isLike)
      }
    });
  };

  return (
    <div className="card_main">
      <div className="card_section_main_sidebar_favo">
        <div className="card_section_sidebar_center_main">

          {/*== fav section  =====*/}
          <div className="card_section_chiled" onClick={FavApi}>
            {
              isFav ? (
                <img alt='Favourite' src={require("../../images/UserDetailspage/staruserdetailes.png")} className="fav_icon_heart" />
              ) : (
                <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
              )
            }
          </div>

          {/*== like section  =====*/}
          <div className="card_section_chiled" onClick={LikeApi}>
            {
              isLike ? (
                <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
              ) : (
                <img alt='Unlike' src={require("../../images/Heartnotlike.png")} className="icon_heart_sidebar" />
              )
            }
          </div>

          {/*=== chat icon ====*/}
          <div className="card_section_chiled">
            <LuSend className="card_Chatpage_luSend_sidebar" onClick={() => navigate("/messages?username=" + props.item.username)} />
          </div>

        </div>
      </div>

      <div className="col px-0 slide_main_section">
        <Link to={"/user/" + props.item.username} className='linkover'>
          <div className="card_content_section"  >
            <img src={props.item.thumbnail_image} className="card_background_img img-fluid" alt="Profile" />
            <div className={props.gridClass} ></div>
            <div className="content_bottom card_content_bottom">
              <div className="user_name_width_set">
                <h4 className="my-0 content_slider_name">{props.item.name}</h4>
                <p className="my-0 content_slider_description"><span className="content_slider_description_color">{props.item?.distance < 1 ? ` ${(props.item?.distance * 1000)} M` : ` ${props.item?.distance} KM`}</span></p>
              </div>
            </div>
          </div>
        </Link>
      </div>

    </div>
  )
}

//  ====== My Favourite Function componet ======
const ProfileCard = (props) => {
  const { Socket } = useSocket();
  const { AuthData } = useAuth();
  const [isFav, SetIsFav] = useState(props.item.is_favourites)
  const [isLike, SetIsLike] = useState(props.item.is_like)
  const navigate = useNavigate();

  useEffect(() => {
    Socket.on("favorite-change-" + AuthData.id, (data) => {
      if (data.favourite_user_id == props.item.user_id) {
        SetIsFav(data.is_favourite)
      }
    })

    return () => {
      Socket.off("favorite-change-" + AuthData.id)
    }
  }, [props])

  const FavApi = () => {
    var favouriteData = {
      favourite_user_id: props.item.user_id,
    };
    Socket.off("favourites-response-" + props.item.user_id);
    Socket.emit("favourite", favouriteData);
    Socket.on("favourites-response-" + props.item.user_id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        SetIsFav(!isFav)
        props.SetFavByYouData(props.favByYouData?.filter((item) => {
          return item.user_id != props.item.user_id
        }))

        props.SetTotalFavYou(props.totalFavYou - 1)
      }
    });
  };

  useEffect(() => {
    Socket.on("like-change-" + AuthData.id, (data) => {
      if (data.liked_user_id == props.item.user_id) {
        SetIsLike(data.is_like)
      }
    })
  }, [props])

  const LikeApi = () => {
    var likeData = {
      liked_user_id: props.item.user_id,
    };
    Socket.off("liked-response-" + props.item.user_id);
    Socket.emit("like", likeData);
    Socket.on("liked-response-" + props.item.user_id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        SetIsLike(!isLike)
      }
    });
  };
  return (
    <div className="card_main">


      <div className="card_section_main_sidebar_favo">
        <div className="card_section_sidebar_center_main" >
          {/*== fav section  =====*/}
          <div className="card_section_chiled" onClick={FavApi}>
            {
              <img alt='Favorite' src={require("../../images/UserDetailspage/staruserdetailes.png")} className="fav_icon_heart" />
            }
          </div>

          {/*== like section  =====*/}
          <div className="card_section_chiled" onClick={LikeApi}>
            {
              isLike ? (
                <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
              ) : (
                <img alt='Unike' src={require("../../images/Heartnotlike.png")} className="icon_heart_sidebar" />
              )
            }
          </div>

          {/*=== chat icon ====*/}
          <div className="card_section_chiled">
            <LuSend className="card_Chatpage_luSend_sidebar" onClick={() => navigate("/messages?username=" + props.item.username)} />
          </div>
        </div>
      </div>

      <div className="col px-0 slide_main_section">
        <Link to={"/user/" + props.item.username} className='linkover'>
          <div className="card_content_section"  >
            <img src={props.item.thumbnail_image} className="card_background_img img-fluid" alt="Profile" />
            <div className={props.gridClass} ></div>
            <div className="content_bottom card_content_bottom">
              <div className="user_name_width_set">
                <h4 className="my-0 content_slider_name"> {props.item.name}</h4>
                <p className="my-0 content_slider_description"><span className="content_slider_description_color">{props.item?.distance < 1 ? ` ${(props.item?.distance * 1000)} M` : ` ${props.item?.distance} KM`}</span></p>
              </div>
            </div>
          </div>
        </Link>
      </div>

    </div>
  )
}

const Fakedatacard = (props) => {
  return (
    <>
      <div className="card_section_main_sidebar_liked blur_img_fake_contain">
        <div className="card_section_sidebar_center_main" >
          {/*== fav section  =====*/}
          <div className="card_section_chiled">
            <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
          </div>

          {/*== like section  =====*/}
          <div className="card_section_chiled">
            <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
          </div>

          {/*=== chat icon ====*/}
          <div className="card_section_chiled">
            <LuSend className="card_Chatpage_luSend_sidebar" />
          </div>
        </div>
      </div>

      <div className="col px-0 slide_main_section">
        <Link className='linkover'>
          <div className="card_content_section">
            <img src={require("../../images/nophoto.png")} className="card_background_img img-fluid blur_img_fake_contain" alt="Favourite"></img>
            {/* <div className={props.gridClass} ></div> */}
            <div className="fake_slider_img" ><img alt='Premium' src={Vector} className="fake_private_image" onClick={() => props.setMembershipModelShow(true)} /></div>
            <div className="content_bottom card_content_bottom">
              <div className="user_name_width_set">
                <h4 className="my-0 content_slider_name blur_name_like_fake_contain">prit</h4>
                <p className="my-0 content_slider_description blur_name_like_fake_contain"><span className="content_slider_description_color">10.5km</span></p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}