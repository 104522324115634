import React, { useEffect, useState } from "react";
import { FaSliders } from "react-icons/fa6";
import { GrFormClose } from "react-icons/gr";
import { HiCamera } from "react-icons/hi2";
import { AiFillStar } from "react-icons/ai";
import { VscClose } from "react-icons/vsc";
import Offcanvas from "react-bootstrap/Offcanvas";
import '../CommonComponet/Privtemodal.css';
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { LuSend } from "react-icons/lu";
import { Helmet } from "react-helmet";

import Location from "../../images/Location.png";
import "../../css/modal.css";
import "../../css/offcanvas.css";
import Header from "./Header";
import Footer from "./Footer";
import { useAuth } from "../../middleware/AuthProvider";
import defaultImg from "../../images/user-profile-default-image.png";
import { HomePageFilter, HomeUserListApi, filterInterestCategory } from "../../api/Api";
import { useRef } from "react";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import heartLike from "../../images/Heartlike.png"
import heartUnLike from "../../images/Heartnotlike.png"
import Vector from "../../images/Subscriptionplan/Vector.png";
import errorImg from "../../images/nophoto.png";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import MembershipModel from "./MembershipModel";

import offLocation from "../../images/off_location.png"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Row } from "react-bootstrap";
import { useSocket } from "../../middleware/SocketProvider";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import axios from 'axios'
import FooterMobile from "./FooterMobile";
import "./Feedbackmodal.css"
import Userdetailcard from "./Userdetailcard";

const Content = () => {
  const { AuthData } = useAuth();
  const { Socket } = useSocket();
  const [show, setShow] = useState(false);
  const [locationAllowed, setLocationAllowed] = useState(true);
  const [filertdata, setFilertdata] = useState({});
  const [heartid, setHeartid] = useState([]);
  const [favoritesid, setfavoritesid] = useState([]);
  const [offshow, setoffShow] = useState(false);
  const [addClass, setAddClass] = useState({
    regular: false,
    model: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [hasMoreRecord, setHasMoreRecord] = useState(true);
  const divRef = useRef(null);
  const [effectClassId, setEffectClassId] = useState([]);
  const [page, setPage] = useState(1);
  const [userData, setUserData] = useState([]);

  const [cardSkeleton, setCardSkeleton] = useState(true);

  const [membershipModelShow, setMembershipModelShow] = useState(false);
  const [offcafilter, setOffcafilter] = useState([])

  const [canvasfilterdata, setCanvasfilterdata] = useState([])
  const [onlyImageUploaded, setOnlyImageUploaded] = useState(false);

  const [onlineStatus, setOnlineStatus] = useState({ value: 'all_seeker', label: 'All Seekers' });

  const [usersearchfilter, setUsersearchfilter] = useState("")
  const [genderListfilter, setGenderListfilter] = useState([]);
  const [categoryfilter, setCategoryfilter] = useState([]);
  const [interestfilter, setInterestfilter] = useState([]);

  const [agefilter, setAgefilter] = useState({ min: 0, max: 0 });

  const [encryptedData, setEncryptedData] = useState(false)
  const [fakedata, setFakedata] = useState([
    {
      // icon: icon1,
      name: "anna",
      distance: "10.5",
      heartlike: true
    },
    {
      // icon: icon2,
      name: "anna",
      distance: "10.5",
      heartlike: false
    },
    {
      // icon: icon3,
      name: "anna",
      distance: "10.5",
      heartlike: true
    },
    {
      // icon: icon4,
      name: "anna",
      distance: "10.5",
      heartlike: false
    },
    {
      // icon: icon5,
      name: "anna",
      distance: "10.5",
      heartlike: true
    }
  ])

  const [genderList, setGenderList] = useState([]);
  const [category, setCategory] = useState([]);
  const [interest, setInterest] = useState([]);


  // ==== Filter Count with local storge ==== 
  var onlyImageUploadedcount = onlyImageUploaded ? 1 : 0;
  var onlineStatuscount = onlineStatus?.value != "all_seeker" && onlineStatus?.value != null ? 1 : 0;
  var genderListfiltercount = genderListfilter.length > 0 ? 1 : 0;
  var categoryfiltercount = categoryfilter.length > 0 ? 1 : 0;
  var interestfiltercount = interestfilter.length > 0 ? 1 : 0;
  var usersearchfiltercount = usersearchfilter.trim().length > 0 ? 1 : 0;
  var canvasfilterdatacount = canvasfilterdata.length ? canvasfilterdata.length : 0;
  var agefilterdatacount = agefilter.min == 0 && agefilter.max == 0 ? 0 : 1

  var allcountstatic = canvasfilterdatacount + categoryfiltercount + onlyImageUploadedcount + onlineStatuscount + interestfiltercount + usersearchfiltercount + genderListfiltercount + agefilterdatacount;

  const [allcountfilter, setAllcountfilter] = useState(allcountstatic ? allcountstatic : "")

  localStorage.setItem('allcountstatic', allcountstatic);

  //  get to localStorage and set to state 
  useEffect(() => {
    const myItem = localStorage.getItem('allcountstatic');

    if (myItem != null && myItem != "") {
      setAllcountfilter(myItem);
    } else {
      setAllcountfilter(allcountstatic);
    }

  }, [userData]);

  useEffect(() => {
    document.title = "Hukup Home";
  }, []);

  const navigate = useNavigate();
  // ==== intrest selector data
  const optionList = [];
  interest.map((item) => {
    var data = { id: item.id, value: item.name, label: item.name }
    optionList.push(data)
  })

  // ==== category selector data
  const dynamicOptions = []
  category.map((newitem) => {
    var data = { id: newitem.id, value: newitem.name, label: newitem.name }
    dynamicOptions.push(data)
  })

  // ==== All Seekers selector data ========

  // const onlineStatusOptions = [
  //   { value: 'all_seeker', label: 'All Seekers' },
  //   { value: 'online', label: 'Online Seekers' },
  //   AuthData.is_premium ?
  //     { value: 'online_now', label: 'Online Now Seekers' } : null,
  // ]

  const onlineStatusOptions = [
    { value: 'all_seeker', label: 'All Seekers' },
    { value: 'online', label: 'Online Seekers' },
  ]
  const filteredOnlineStatusOptions = onlineStatusOptions.filter(option => option !== null);

  var girdItem = 0
  const privteMembership = () => {
    setMembershipModelShow(true);
  }

  // ----- modal ====
  const handleClose = () => {

    setAddClass({ model: false, regular: false });
    setShow(false);
  };

  // --- open modal to user detiale ---
  const handleShow = (id) => {
    setAddClass({ model: false, regular: false });

    setShow(true);

    const filetrdatanew = userData.find((item) => item.id === id);
    setFilertdata(filetrdatanew);
  };

  //  Offcanvas =====
  const handleCloseoff = () => setoffShow(false);
  const handleShowoff = () => setoffShow(true);

  //  heartanimation =====
  const heartanimation = (id) => {
    let ids = [];
    setEffectClassId([id]);
    if (heartid.includes(id)) {
      ids = heartid.filter((item) => item !== id);
    } else {
      ids = [...heartid, id];
      if (show) {
        setAddClass({ model: true, regular: false });
      } else {
        setAddClass({ model: false, regular: true });
      }
    }
    setHeartid(ids);
  };

  const filterApicall = () => {
    var cancelToken = axios.CancelToken.source();
    UserDataListApi({ page: 1 }, cancelToken);
    handleCloseoff()
  }
  // == call to all UserDetaile data api  =====
  const UserDataListApi = (filterData = {}, cancelToken, setLocalStorage = true) => {
    if (filterData['page'] != 1) {
      if (!hasMoreRecord || isLoading) {
        return false;
      }
    }
    else {
      setHasMoreRecord(true)
    }

    var data = {
      page: page,
      filter: canvasfilterdata.map((e) => {
        var data = e.field_value.map((ele) => {
          if (ele.value) {
            return ele.value.toString().trim()
          }
          else {
            return ele.toString().trim()
          }
        })
        return {
          field_id: e.field_id,
          field_value: data
        }
      }),
      only_image_uploaded: onlyImageUploaded,
      online_status: onlineStatus?.value,
      search: usersearchfilter.trim(),
      gender: genderListfilter.map(ele => Number(ele.id)),
      category: categoryfilter.map((ele) => ele.id),
      interest: interestfilter.map(ele => ele.id),
      age: agefilter.min == 0 && agefilter.max == 0 ? [] : [agefilter.min, agefilter.max],
    };

    data = { ...data, ...filterData };

    // localstorage data condition ----
    if (setLocalStorage) {
      localStorage.setItem('newfilterdata', JSON.stringify(canvasfilterdata));
      localStorage.setItem('only_image_uploaded', data.only_image_uploaded);
      localStorage.setItem('onlineStatus', JSON.stringify(onlineStatus));
      localStorage.setItem('genderListfilter', JSON.stringify(genderListfilter));
      localStorage.setItem('categoryfilter', JSON.stringify(categoryfilter));
      localStorage.setItem('interestfilter', JSON.stringify(interestfilter));
      localStorage.setItem('usersearchfilter', data.search);
      localStorage.setItem('agefilterdata', JSON.stringify(agefilter));
    }

    if (data.page == 1) {
      divRef.current.scrollTop = 0;
    }

    setIsLoading(true)
    HomeUserListApi(data, cancelToken)
      .then(function (response) {
        setIsFirstLoading(false)


        setIsLoading(false)
        if (!response.data.status) {
          setCardSkeleton(false);
          if (navigator.geolocation) {

            if (navigator.permissions && navigator.permissions.query) {
              navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                  if (result.state !== "granted") {
                    setLocationAllowed(false)
                  }
                  else {
                    setLocationAllowed(false)
                  }

                });
            }

            // navigator.permissions
            //   .query({ name: "geolocation" })
            //   .then(function (result) {
            //     if (result.state === "granted") {
            //       navigator.geolocation.getCurrentPosition(function (position) {
            //         if (!locationAllowed) {
            //           window.location.reload();
            //         }
            //       }, function (error) {
            //         setLocationAllowed(false)
            //       })
            //     }
            //     else {
            //       setLocationAllowed(false)
            //     }
            //   });
            
          }
          else {
            setLocationAllowed(false)
          }
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          return false;
        }

        var userDatas = response.data.data;
        setEncryptedData(response.data.encryptedData)
        setCardSkeleton(false)

        if (!userDatas.length) {
          setHasMoreRecord(false);
        }
        else {
          setHasMoreRecord(true);
        }
        var liked_users = userDatas.map((ele) => {
          if (ele.is_like) {
            return ele.id;
          }
        });
        var fav_users = userDatas.map((ele) => {
          if (ele.is_favourites) {
            return ele.id;
          }
        });
        if (data.page == 1) {
          setHeartid(liked_users);
          setfavoritesid(fav_users);
          setUserData(userDatas);
        }
        else {
          setHeartid(heartid.concat(liked_users));
          setfavoritesid(favoritesid.concat(fav_users));
          setUserData(userData.concat(userDatas));
        }
        setPage(data.page + 1)

      })
      .catch(function (error) {
        setCardSkeleton(false)
        setIsLoading(false)
        console.log(error);
      });
    localStorage.setItem('allcountstatic', allcountfilter);
  };

  // ---- get to filter data in localstorge with page load ------- 
  useEffect(() => {
    let filter = [];
    try {
      filter = JSON.parse(localStorage.getItem('newfilterdata'))
    } catch (error) {
      filter = []
      console.log(error);
      localStorage.setItem('newfilterdata', JSON.stringify([]))
    }
    if (!filter) {
      filter = [];
    }
    setCanvasfilterdata(filter)
    filter = filter.map((e) => {
      var data = e.field_value.map((ele) => {
        if (ele.value) {
          return ele.value.toString().trim()
        }
        else {
          return ele.toString().trim()
        }
      })
      return {
        field_id: e.field_id,
        field_value: data
      }
    })
    let only_image_uploaded = false;
    try {
      only_image_uploaded = localStorage.getItem('only_image_uploaded')
      if (only_image_uploaded == 'true') {
        only_image_uploaded = true;
      }
      else {
        only_image_uploaded = false;
      }
    } catch (error) {
      console.log(error);
      only_image_uploaded = false
      localStorage.setItem('only_image_uploaded', 'false')
    }
    setOnlyImageUploaded(only_image_uploaded);

    let online_status = "";
    try {
      online_status = JSON.parse(localStorage.getItem('onlineStatus'))
      setOnlineStatus(online_status);
    } catch (error) {
      console.log(error);
      online_status = { value: 'all_seeker', label: 'All Seekers' }
      localStorage.setItem('onlineStatus', online_status)
    }
    if (!online_status) {
      online_status = { value: 'all_seeker', label: 'All Seekers' }
    }
    setOnlineStatus(online_status);

    let age = "";
    try {
      age = JSON.parse(localStorage.getItem('agefilterdata'))
      setAgefilter(age);
    } catch (error) {
      console.log(error);
      age = { min: 0, max: 0 }
      localStorage.setItem('agefilterdata', age)
    }
    if (!age) {
      age = { min: 0, max: 0 }
    }
    setAgefilter(age);

    var search = localStorage.getItem('usersearchfilter') ? localStorage.getItem('usersearchfilter') : "";
    setUsersearchfilter(search);

    let gender = [];
    try {
      gender = JSON.parse(localStorage.getItem('genderListfilter'))
    } catch (error) {
      gender = []
      console.log(error);
      localStorage.setItem('genderListfilter', JSON.stringify([]))
    }
    if (!gender) {
      gender = [];
    }
    setGenderListfilter(gender);

    let category = [];
    try {
      category = JSON.parse(localStorage.getItem('categoryfilter'))
    } catch (error) {
      console.log(error);
      category = []
      localStorage.setItem('categoryfilter', JSON.stringify([]))
    }
    if (!category) {
      category = [];
    }
    setCategoryfilter(category);
    let interest = [];
    try {
      interest = JSON.parse(localStorage.getItem('interestfilter'))
    } catch (error) {
      interest = []
      console.log(error);
      localStorage.setItem('interestfilter', JSON.stringify([]))
    }
    if (!interest) {
      interest = [];
    }
    setInterestfilter(interest);
    var cancelToken = axios.CancelToken.source();
    gender = gender.map((ele) => ele.id);
    interest = interest.map((ele) => ele.id);
    category = category.map((ele) => ele.id);
    UserDataListApi({ page: 1, filter, only_image_uploaded, online_status: online_status.value, age: age.min == 0 && age.max == 0 ? [] : [age.min, age.max], search, gender, category, interest }, cancelToken, false);
  }, []);

  useEffect(() => {
    Socket.on("like-change-" + AuthData.id, (likeData) => {
      var checkIfUserExists = userData.find((ele => {
        return ele.id == likeData.liked_user_id
      }))
      if (checkIfUserExists) {
        if (likeData.is_like) {
          var checkLiked = heartid.find((ele) => {
            return ele == likeData.liked_user_id
          });
          if (!checkLiked) {
            setHeartid(heartid.concat([likeData.liked_user_id]))
          }
        }
        else {
          var checkLiked = heartid.find((ele) => {
            return ele == likeData.liked_user_id
          });
          if (checkLiked) {
            setHeartid(heartid.filter((ele) => {
              return ele != likeData.liked_user_id
            }))
          }
        }
      }
    })

    return () => {
      Socket.off("like-change-" + AuthData.id)
    }
  }, [userData, heartid])

  useEffect(() => {
    Socket.on("favorite-change-" + AuthData.id, (fevData) => {
      var checkIfUserExists = userData.find((ele => {
        return ele.id == fevData.favourite_user_id
      }))
      if (checkIfUserExists) {
        if (fevData.is_favourite) {
          var checkFev = favoritesid.find((ele) => {
            return ele == fevData.favourite_user_id
          });
          if (!checkFev) {
            setfavoritesid(favoritesid.concat([fevData.favourite_user_id]))
          }
        }
        else {
          var checkFev = favoritesid.find((ele) => {
            return ele == fevData.favourite_user_id
          });
          if (checkFev) {
            setfavoritesid(favoritesid.filter((ele) => {
              return ele != fevData.favourite_user_id
            }))
          }
        }
      }
    })

    return () => {
      Socket.off("favorite-change-" + AuthData.id)
    }
  }, [userData, favoritesid])

  // -------- filter section statice filter data ---------
  useEffect(() => {
    filterInterestCategory().then(function (response) {
      setGenderList(response.data.data.genders)
      setInterest(response.data.data.interest)
      setCategory(response.data.data.categories)
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const AddFav = (id) => {
    var favouriteData = {
      favourite_user_id: id,
    };
    Socket.off("favourites-response-" + id);
    Socket.emit("favourite", favouriteData);
    Socket.on("favourites-response-" + id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Favoritechek(id)
      }
    });
  };

  const Favoritechek = (id) => {
    if (favoritesid.find((ele) => ele == id)) {
      setfavoritesid(favoritesid.filter((e) => e !== id));
    } else {
      setfavoritesid(favoritesid.concat([id]));
    }
  };

  // === User Like Api ====
  const LikeApi = (id) => {
    var likeData = {
      liked_user_id: id,
    };
    Socket.off("liked-response-" + id);
    Socket.emit("like", likeData);
    Socket.on("liked-response-" + id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          // title: data.message,
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        heartanimation(id)
      }
    });
  };

  // --------------- Scroll paginatione and user data load   -----------------------

  const handleScroll = useCallback(() => {
    var cancelToken = null;
    if (
      divRef.current &&
      divRef.current.scrollTop + divRef.current.clientHeight >=
      divRef.current.scrollHeight - 200 &&
      !isLoading
    ) {
      cancelToken = axios.CancelToken.source();
      UserDataListApi({ page: page }, cancelToken);
    }

    return () => {
      if (cancelToken) {
        try {
          cancelToken.cancel("Request canceled by user");
        } catch (error) {
          console.log("error", error);
        }
      }
    }

  }, [isLoading, hasMoreRecord, canvasfilterdata, onlyImageUploaded, page, heartid, favoritesid, userData, genderListfilter, categoryfilter, interestfilter, usersearchfilter]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  // --------------------------------------

  //  Filter api Call ====
  useEffect(() => {
    HomePageFilter()
      .then(function (response) {
        setOffcafilter(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const handleCheckboxshowprofileChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setOnlyImageUploaded(true)
    } else {
      setOnlyImageUploaded(false)
    }
  }

  // === Checkbox  Onchange Function====
  const handleCheckboxChange = (e) => {
    let { checked, name, value } = e.target;
    value = value.trim();
    if (checked) {
      const index = canvasfilterdata.findIndex(item => item.field_id == name);
      if (index !== -1) {
        const updatedData = [...canvasfilterdata];
        updatedData[index].field_value.push(value);
        setCanvasfilterdata(updatedData);
      } else {
        const newObject = { "field_id": name, "field_value": [value] };
        setCanvasfilterdata([...canvasfilterdata, newObject]);
      }
    } else {
      var array = [...canvasfilterdata];
      const index = canvasfilterdata.findIndex(item => item.field_id == name);
      if (index !== -1) {
        const removeItemPosition = array[index].field_value.indexOf(value);
        array[index].field_value.splice(removeItemPosition, 1)
        if (array[index].field_value.length == 0) {
          array.splice(index, 1);
          setCanvasfilterdata(array)
        } else {
          setCanvasfilterdata(array);
        }
      }
    }
  };
  // === Radio Button Onchange Function====
  const handleRadioChange = (e) => {
    let { name, value } = e.target;
    value = value.trim();
    const index = canvasfilterdata.findIndex(item => item.field_id === name);
    if (index !== -1) {

      const updatedData = [...canvasfilterdata];
      updatedData[index].field_value = [value];
      setCanvasfilterdata(updatedData);
    } else {
      const newObject = { "field_id": name, "field_value": [value] };
      setCanvasfilterdata([...canvasfilterdata, newObject]);
    }
  }

  // === Selector Onchange Function====
  const handleMultiSelect = (id, e) => {
    const selectedValues = e.map(option => String(option.value).trim());

    if (selectedValues == "") {
      setCanvasfilterdata(canvasfilterdata.filter((ele) => ele.field_id != id))
    }
    else {


      const index = canvasfilterdata.findIndex(item => item.field_id == id);
      if (index !== -1) {
        const updatedData = [...canvasfilterdata];
        updatedData[index].field_value = e;
        setCanvasfilterdata(updatedData);
      } else {
        const newObject = { "field_id": id, "field_value": e };
        setCanvasfilterdata([...canvasfilterdata, newObject]);
      }


    }
  }

  // === Range Onchange Function ====
  const handleRangeChange = (field_id, value) => {
    const index = canvasfilterdata.findIndex(item => item.field_id === field_id);
    if (index !== -1) {
      const updatedData = [...canvasfilterdata];
      updatedData[index].field_value = [value.min, value.max];
      if (updatedData[index].field_value.includes('0')) {
        updatedData.splice(index, 1);
        setCanvasfilterdata(updatedData)
      } else {
        setCanvasfilterdata(updatedData);
      }
    } else {
      const newObject = { "field_id": field_id, "field_value": [value.min, value.max] };
      setCanvasfilterdata([...canvasfilterdata, newObject]);

    }
  }

  const getValue = (id) => {
    const data = canvasfilterdata.find((iddata) => iddata.field_id == id);
    if (data !== undefined) {
      return {
        min: data.field_value[0],
        max: data.field_value[1]
      };
    } else {
      return {
        min: 0,
        max: 0,
      }
    }
  }

  // === text Onchange Function ====
  const handleTextChange = (e) => {
    let { value, id } = e.target;
    value = value.trim();
    const index = canvasfilterdata.findIndex(item => item.field_id === id);
    if (index !== -1) {
      const updatedData = [...canvasfilterdata];
      updatedData[index].field_value = [value];
      setCanvasfilterdata(updatedData);
    } else {
      const newObject = { "field_id": id, "field_value": [value] };
      setCanvasfilterdata([...canvasfilterdata, newObject]);
    }
  }

  //  cheack box check and uncheck check and fileup value in filter ==
  const ckeckIfChecked = (field_id, value) => {
    value = value.trim();
    var check = canvasfilterdata.find((ele) => {
      return ele.field_id == field_id
    });
    if (check) {
      return check.field_value?.includes(value)
    }
    return false;
  }

  const multiSelectSelected = (id) => {
    var check = canvasfilterdata.find((ele) => {
      return ele.field_id == id;
    });
    if (check) {
      return check?.field_value;
    }
    return [];
  }

  // == Clear Filter Function ====
  const ClearFilterall = () => {
    clearalldata()
  }

  // == Clear Filter Function Main ====
  const clearalldata = () => {
    setCanvasfilterdata([])
    setOnlineStatus("all_seeker")
    setOnlyImageUploaded(false)
    setGenderListfilter([])
    setCategoryfilter([])
    setInterestfilter([])
    setUsersearchfilter("")
    setAgefilter({ min: 0, max: 0 })
    localStorage.removeItem('newfilterdata')
    localStorage.removeItem('onlineStatus')
    localStorage.removeItem('only_image_uploaded')
    localStorage.removeItem('categoryfilter')
    localStorage.removeItem('genderListfilter')
    localStorage.removeItem('interestfilter')
    localStorage.removeItem('usersearchfilter')
    localStorage.removeItem('allcountstatic')
    localStorage.removeItem('agefilterdata');

    var cancelToken = axios.CancelToken.source();
    UserDataListApi({
      page: 1,
      filter: [],
      only_image_uploaded: false,
      online_status: "all_seeker",
      gender: [],
      category: [],
      search: "",
      interest: [],
      age: [],
    }, cancelToken, false)

    handleCloseoff()
  }

  // staic gender cheak box  
  const handleCheckBoxChangeGender = (e) => {
    const { checked, id } = e.target;
    if (checked) {
      const newObject = { id };
      setGenderListfilter((prevData) => [...prevData, newObject]);
    } else {
      setGenderListfilter((current) =>
        current.filter((ele) => ele.id != id)
      );
    }
  };
//  -- cheak if to checked and unchecked ----
  const ckeckIfCheckedGender = (id) => {
    var check = genderListfilter.find((ele) => {
      return ele.id == id
    });

    if (check) {
      return check.id?.includes(id)
    }
    return false;
  }

  function handleSelect(data) {
    setInterestfilter(data);
  }

  function filteronlineSeekersChanges(data) {
    setOnlineStatus(data)
  }

  function CategoryhandleMultieSelect(data) {
    setCategoryfilter(data)
  }

  // statice age filter onchang functione ========
  const agefilterhandleChange = (data) => {
    var newdata = { min: data.min, max: data.max }
    setAgefilter(newdata);
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={"Home Page On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>

          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          {
            locationAllowed ?
              <img
                src={require("../../../src/images/Visitors/CurveBlackline.png")}
                alt="CurveBlackline"
                className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block"
              />
              : ""
          }
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="content_main px-0">
          <div className="container">
            <div className="row">
              {/* ====== User Name And User Image section ======== */}
              <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                <Userdetailcard /> 
              </div>

              {/* ============ Card Section Main ============ */}
              <div ref={divRef} className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 content_second">
                {/* ==== Filter Button ======= */}
                {locationAllowed ? (
                  <div className="fssliders_section" onClick={handleShowoff}>
                    {allcountfilter > 0 ? <div className="home_filter_count_dot_main_section"><span className="home_filter_count">{allcountfilter}</span></div> : ""}
                    <FaSliders className="fssliders_icon" />
                  </div>)
                  : ""}

                {/* ============ User Detaile Card Section ============ */}
                <div>
                  {cardSkeleton && (
                    <SkeletonTheme>
                      <Row className="">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                          return (
                            <Col xs={6} md={4} lg={3}>
                              <Skeleton height={200} count={1} />
                            </Col>
                          )
                        })}
                      </Row>
                    </SkeletonTheme>
                  )}
                  {/* ------- not allow to location show the icon and allow to location show user detaile card  --------- */}
                  {!locationAllowed ? (
                    <>
                      <div className="text-center h-100 mt-5">
                        <img src={offLocation} className="offLocation_in_home_page" />
                        <p className="mt-5 offLocation_para_home">To display matches near you, please allow location access.</p>
                        <p><a href="https://blog.hukup.net/faq/browser-location-access/" target="blank">Click here </a>if need help</p>
                      </div>
                    </>
                  ) : (
                    <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                      {userData.map((item, index) => {
                        var gridClass = "";
                        if (window.innerWidth < 768) {
                          if (index == 0) {
                            gridClass = "slider_img";
                            girdItem = 0;
                          }
                          else {
                            girdItem = girdItem + 1;
                            if (girdItem == 3 || girdItem == 4) {
                              gridClass = "slider_img";
                            }
                            if (girdItem == 4) {
                              girdItem = 0;
                            }
                          }
                        }
                        else {
                          gridClass = index % 2 === 0 ? "" : "slider_img";
                        }

                        const distance = item?.distance;
                        const displayValue = distance < 1 ? `${(distance * 1000)} M` : `${distance} KM`;

                        return (
                          <>
                            <div className="card_main">
                              <div className="col px-0 slide_main_section">
                                <div className="card_content_section ">
                                  <img
                                    src={item.thumbnail_image == null ? defaultImg : item.thumbnail_image}
                                    className="card_background_img img-fluid"
                                    alt="Profile"
                                    onError={(e) => { e.target.src = errorImg; }}
                                    onClick={() => handleShow(item.id)}
                                  />
                                  <div className={gridClass} onClick={() => handleShow(item.id)} ></div>
                                  <div className="card_section_main_sidebar">
                                    <div className="card_section_sidebar_center_main" >

                                      {/* === favorite ======*/}
                                      <div className="card_section_chiled">
                                        {favoritesid.includes(item.id) ? (
                                          <AiFillStar
                                            className="card_fav_icon_sidebar"
                                            onClick={(e) => AddFav(item.id)}
                                          />
                                        ) : (
                                          <AiFillStar
                                            className="card_unfav_icon_sidebar"
                                            onClick={(e) => AddFav(item.id)}
                                          />
                                        )}
                                      </div>
                                      {/*== like section  =====*/}
                                      <div className="card_section_chiled">
                                        {heartid.includes(item.id) ? (
                                          <img
                                            src={heartLike}
                                            alt="Like"
                                            className={`icon_heart_red_sidebar ${effectClassId.includes(item.id) &&
                                              addClass.regular
                                              ? `likeheart`
                                              : ``
                                              }`}
                                            onClick={() => LikeApi(item.id)}
                                          />
                                        ) : (
                                          <img
                                            src={heartUnLike}
                                            alt="Unlike"
                                            className="icon_heart_sidebar"
                                            onClick={() => LikeApi(item.id)}
                                          />
                                        )}
                                      </div>

                                      {/*=== chat icon ====*/}
                                      <div className="card_section_chiled" onClick={() => navigate("/messages?username=" + item.username)}>
                                        <LuSend className="card_Chatpage_luSend_sidebar" />
                                      </div>

                                    </div>
                                  </div>

                                  <div className="content_bottom card_content_bottom" onClick={() => handleShow(item.id)}>
                                    <div className="user_name_width_set">
                                      <h4 className="my-0 content_slider_name">
                                        {item.name}
                                      </h4>
                                      <p className="my-0 content_slider_description">
                                        <span className="content_slider_description_color" >
                                          {displayValue}
                                        </span>
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </>
                        );
                      })}
                      {/* ---------  when aplly filter and not get data on home page show this section -------- */}
                      {
                        userData.length || isLoading ? "" :
                          (allcountstatic) ? (
                            <div className="w-100 text-center">
                              <p className="mt-5">No profiles found. Try some different search criteria</p>
                              <div className=" w-100 mt-2">
                                <span className="mr-5 Go_To_Filter_Clear" onClick={() => handleShowoff()} >Go To Filter</span>
                                <span className="ml-5 Go_To_Filter_Clear" onClick={() => ClearFilterall()} >Clear Filter</span>
                              </div>
                            </div>
                          ) : (
                            <div className="w-100 text-center">
                              <p className="mt-5">No profiles found. Try some different search criteria</p>
                              <div className=" w-100 mt-2">
                                <span className="mr-5 Go_To_Filter_Clear" onClick={() => handleShowoff()} >Go To Filter</span>
                                <span className="ml-5 Go_To_Filter_Clear" onClick={() => ClearFilterall()} >Clear Filter</span>
                              </div>
                            </div>
                          )
                      }

                      {/* ======= Fake Data Section For Non Premium User======== */}
                      {encryptedData == true ? (fakedata.map((item, index) => {
                        return (
                          <>
                            <div key={index} className="card_main" onClick={() => setMembershipModelShow(true)}>
                              <div className="card_section_main_sidebar_liked blur_img_fake_contain">
                                <div className="card_section_sidebar_center_main" >
                                  {/*== fav section  =====*/}
                                  <div className="card_section_chiled">
                                    <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
                                  </div>

                                  {/*== like section  =====*/}
                                  <div className="card_section_chiled">
                                    <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
                                  </div>

                                  {/*=== chat icon ====*/}
                                  <div className="card_section_chiled">
                                    <LuSend className="card_Chatpage_luSend_sidebar" />
                                  </div>
                                </div>
                              </div>

                              <div className="col px-0 slide_main_section">
                                <Link className='linkover'>
                                  <div className="card_content_section">
                                    <img src={require("../../images/nophoto.png")} className="card_background_img img-fluid blur_img_fake_contain" alt="Favourite"></img>
                                    {/* <div className={props.gridClass} ></div> */}
                                    <div className="fake_slider_img" ><img alt='Premium' src={Vector} className="fake_private_image" onClick={() => setMembershipModelShow(true)} /></div>
                                    <div className="content_bottom card_content_bottom">
                                      <div className="user_name_width_set">
                                        <h4 className="my-0 content_slider_name blur_name_like_fake_contain">{item.name}</h4>
                                        <p className="my-0 content_slider_description blur_name_like_fake_contain"><span className="content_slider_description_color">{item.distance}</span></p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </>
                        );
                      })
                      ) : ("")}
                    </div>
                  )}
                  {isLoading && (
                    <SkeletonTheme>
                      <Row className="">
                        {[1, 2, 3, 4].map((ele) => {
                          return (
                            <Col xs={6} md={4} lg={3}>
                              <Skeleton height={200} count={1} />
                            </Col>
                          )
                        })}
                      </Row>
                    </SkeletonTheme>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*===== mobile and ipad footer ======== */}
          {!hasMoreRecord && (
            <FooterMobile />
          )}
        </div>
        {/* ========= user detaile modal ======= */}
        <Modal show={show} onHide={handleClose} className={show ? "modal_open" : "close_modal"} centered>

          <div className="main_blur_section">
            <GrFormClose className="close_icon" onClick={handleClose} />
          </div>

          <div className="main_content_section">
            <div className="first_section">
              <img alt="Profile" src={filertdata.image == null ? defaultImg : filertdata.image} onError={(e) => { e.target.src = errorImg; }} />
            </div>
            <div className="second_section">
              <div className="title_section">
                <div className="title_section_name_title_main_section">
                  <h1 className="title_section_name_title"><Link to={"/user/" + filertdata.username}>{filertdata.name}  </Link></h1>
                </div>
                <div className="title_section_like_unlike_main">
                  {heartid.includes(filertdata.id) ? (
                    <img
                      src={heartLike}
                      alt="Like"
                      className={`icon_heart_red ${addClass.model ? `likeheart` : ``
                        }`}
                      onClick={() => { LikeApi(filertdata.id) }}
                    />
                  ) : (
                    <img
                      src={heartUnLike}
                      alt="Unlike"
                      className="icon_heart"
                      onClick={() => { LikeApi(filertdata.id) }}
                    />
                  )}
                </div>
              </div>
              <div className="location_section">
                <img alt="Location" src={Location} />
                <h5>{filertdata?.distance < 1 ? `${(filertdata?.distance * 1000)} M` : `${filertdata?.distance} KM`}</h5>
              </div>
              {filertdata.description ? (
                <div className="introduction_section">
                  <h4>About me</h4>
                  <p className="bio_two_line">{filertdata.description}</p>
                </div>
              ) : (
                ""
              )}
              {filertdata.interest ? (
                <div className="intersts_section">
                  <h4>Interests</h4>
                  <div className="d-flex flex-wrap">
                    {filertdata.interest.slice(0, 4).map((e) => {
                      return (
                        <div className="music_section mr-1 my-1">
                          <img alt="Interest" className="iconintrest" src={e.image} />{" "}
                          <span className="music_name">{e.name}</span>
                        </div>
                      );
                    })}
                    {filertdata.interest.length > 4 && (
                      <div className="music_section mr-1 my-1">
                        <Link to={"/user/" + filertdata.username} className="Show_More_Interests">View all</Link>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="star_section ">
                <div className="second_star mr-2 border">
                  {favoritesid.includes(filertdata.id) ? (
                    <AiFillStar
                      className="fav_icon"
                      onClick={(e) => AddFav(filertdata.id)}
                    />
                  ) : (
                    <AiFillStar
                      className="star_icon"
                      onClick={(e) => AddFav(filertdata.id)}
                    />
                  )}
                </div>
                <Link to={"/usergallery/" + filertdata.username}>
                  <div className="third_star">
                    <HiCamera className="camera_icon" />
                  </div>
                </Link>
                <div className="third_star" onClick={() => navigate("/messages?username=" + filertdata.username)}>
                  <LuSend className="modal_Chatpage_luSend" />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* ====== Filter section ======== */}
        {["end"].map((placement) => (
          <Offcanvas
            show={offshow}
            onHide={handleCloseoff}
            className="offcanvas_main"
            placement={placement}
            name={placement}
          >
            <div className="offcanvas_header d-flex justify-content-between align-items-center">
              <h1 className="mb-0 offcanvas_filter_name text-left">Filter</h1>
              <div className="filter_header_all_seekers_main">
                <Select
                  options={filteredOnlineStatusOptions}
                  placeholder="Select Option"
                  value={onlineStatus}
                  onChange={filteronlineSeekersChanges}
                  isSearchable={false}
                  isClearable={false}
                />
              </div>
              {/* <select onChange={(e) => setOnlineStatus(e.target.value)} value={onlineStatus} className="form-select offcanvas_main_selector">
                <option value="all_seeker">All Seekers</option>
                <option value="online">Online Seekers</option>
                {AuthData.is_premium != 0 ? <option value="online_now">Online Now Seekers</option> : ""}
              </select> */}
              <VscClose
                className="offcanvas_close_icon cursor_pointer_common"
                onClick={handleCloseoff}
              />
            </div>

            <div className="text-center">
              <button className="save_update_data my-3 mx-1" onClick={clearalldata}>Clear</button>
              <button className="save_update_data my-3 mx-1" onClick={filterApicall}>Filter</button>
            </div>

            <div className={`row px-2 pb-4 offcanvas_filter_content_section`}>
              <div className="offcanvas_Relationship_main">
                <div className="mx-3 my-3">
                  <div className={`form-check d-flex align-items-center offcanvas_checkbox_commoan`}
                    onClick={() => (!AuthData.is_premium) ? privteMembership() : ""}>
                    <input
                      className="form-check-input cursor_pointer_common"
                      type="checkbox"
                      name="Only show profile with photos"
                      onChange={handleCheckboxshowprofileChange}
                      id="Only show profile with photos"
                      disabled={(!AuthData.is_premium) ? true : false}
                      checked={onlyImageUploaded}
                    />
                    <label className="form-check-label cursor_pointer_common mt-1" for="Only show profile with photos">
                      Only show profile with photos
                    </label><img alt="Filter" src={Vector} width={20} height={20} className="ml-2" />
                  </div>
                </div>
              </div>

              <div>
                <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                  Search
                </h5>
                <div className="mx-3 my-1" >
                  <input type="text" placeholder="Search"
                    value={usersearchfilter}
                    onChange={(e) => setUsersearchfilter(e.target.value)} className="form-control offcanvas_filter_input"
                  />
                </div>
              </div>

              <div>
                <h5 className="mx-3 mt-4 mb-0 offcanvas_cheakbox_header">
                  Gender<img alt="Gender" src={Vector} width={20} height={20} className="ml-2" />
                </h5>
                <div className="d-flex ">
                  <div className="ml-2 my-1">
                    <div className="d-flex flex-wrap" onClick={() => (!AuthData.is_premium) ? privteMembership() : ""}>
                      {
                        genderList.map((radiodata) => {
                          return (
                            <>
                              <div className="form-check mx-2 d-flex align-items-center">
                                <input
                                  className="form-check-input cursor_pointer_common"
                                  type="checkbox"
                                  name="genderfilter"
                                  id={radiodata.id}
                                  value={radiodata.name}
                                  onChange={handleCheckBoxChangeGender}
                                  checked={ckeckIfCheckedGender(radiodata.id)}
                                  disabled={(!AuthData.is_premium) ? true : false}
                                />
                                <label className="form-check-label cursor_pointer_common" for={radiodata.id}  >
                                  {radiodata.name}
                                </label>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 px-0">
                <div className="mt-3 ml-4 px-0 offcanvas_Interest_selector">
                  <h5 className="mb-2 offcanvas_cheakbox_header">Category<img alt="Category" src={Vector} width={20} height={20} className="ml-2" /></h5>
                  <div className="position-relative">
                    <Select
                      options={dynamicOptions}
                      placeholder="Select Option"
                      value={categoryfilter}
                      onChange={CategoryhandleMultieSelect}
                      isSearchable={false}
                      isMulti
                      isClearable={false}
                    />
                    {(!AuthData.is_premium) ? <div className="filter_disable_section" onClick={() => privteMembership()}></div> : ""}
                  </div>
                </div>
              </div>

              <div className="mt-3 ml-4 px-0 offcanvas_Interest_selector">
                <h5 className="mb-2 offcanvas_cheakbox_header">Interest<img alt="Interesr" src={Vector} width={20} height={20} className="ml-2" /></h5>
                <div className="position-relative">
                  <Select
                    options={optionList}
                    placeholder="Select Option"
                    value={interestfilter}
                    onChange={handleSelect}
                    isSearchable={false}
                    isMulti
                    isClearable={false}
                  />
                  {(!AuthData.is_premium) ? <div className="filter_disable_section" onClick={() => privteMembership()}></div> : ""}
                </div>
              </div>

              <div className="px-3">
                <h5 className="range_custom mt-4 offcanvas_cheakbox_header ">
                  Age<img alt="Filter" src={Vector} width={20} height={20} className="ml-2" />
                </h5>
                <div className="mx-3 mt-3" onClick={() => (!AuthData.is_premium) ? privteMembership() : ""}>
                  <InputRange
                    disabled={(!AuthData.is_premium) ? true : false}
                    maxValue={150}
                    minValue={18}
                    value={agefilter}
                    onChange={agefilterhandleChange}
                  />
                </div>
              </div>


              {/* ===== Dynamic Filter ====== */}
              {
                offcafilter.map((filterItem) => {
                  return (
                    <>
                      <div className="mt-3">
                        <fieldset className="filter_fieldset_border">
                          <legend className="float-none  filter_group_name">{filterItem.group_name}</legend>
                        </fieldset>
                      </div>

                      {filterItem.fields.map((item) => {
                        if (item.type == "checkbox") {
                          return (<>
                            <div className="offcanvas_Relationship_main" >
                              <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                                {item.label} {item.is_paid_filter == 1 ? <img alt="Filter" src={Vector} width={20} height={20} className="ml-2" /> : ""}
                              </h5>
                              <div className="mx-3 my-1">
                                <div className="d-flex flex-wrap">
                                  {
                                    item.options.split(",").map((newdata) => {
                                      return (
                                        <>
                                          <div className={newdata == "Only show profiles with photos" ?
                                            `form-check d-flex align-items-center offcanvas_checkbox_commoan`
                                            :
                                            `form-check d-flex align-items-center offcanvas_checkbox_primeam`
                                          } onClick={() => (!AuthData.is_premium && item.is_paid_filter) ? setMembershipModelShow(true) : ""}>
                                            <input
                                              className="form-check-input cursor_pointer_common"
                                              disabled={(!AuthData.is_premium && item.is_paid_filter) ? true : false}
                                              type="checkbox"
                                              value={newdata}
                                              name={item.id}
                                              id={newdata + "_" + item.id}
                                              onChange={handleCheckboxChange}
                                              checked={ckeckIfChecked(item.id, newdata)}
                                            />
                                            <label className="form-check-label cursor_pointer_common mt-1" for={newdata + "_" + item.id}>
                                              {newdata}
                                            </label>
                                          </div>
                                        </>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </>)
                        }

                        if (item.type == "radio_button") {
                          return (<>
                            <div className="offcanvas_Relationship_main" >
                              <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                                {item.label} {item.is_paid_filter == 1 ? <img alt="Filter" src={Vector} width={20} height={20} className="ml-2" /> : ""}
                              </h5>
                              <div className="mx-3 my-1">
                                <div className="d-flex flex-wrap">
                                  {
                                    item.options.split(",").map((newdata) => {
                                      return (
                                        <>
                                          <div className={newdata == "Only show profiles with photos" ?
                                            `form-check d-flex align-items-center offcanvas_checkbox_commoan`
                                            :
                                            `form-check d-flex align-items-center offcanvas_checkbox_primeam`
                                          } onClick={() => (!AuthData.is_premium && item.is_paid_filter) ? setMembershipModelShow(true) : ""}>
                                            <input
                                              className="form-check-input cursor_pointer_common"
                                              disabled={(!AuthData.is_premium && item.is_paid_filter) ? true : false}
                                              type="checkbox"
                                              value={newdata}
                                              name={item.id}
                                              id={newdata + "_" + item.id}
                                              onChange={handleCheckboxChange}
                                              checked={ckeckIfChecked(item.id, newdata)}
                                            />
                                            <label className="form-check-label cursor_pointer_common mt-1" for={newdata + "_" + item.id}>
                                              {newdata}
                                            </label>
                                          </div>
                                        </>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </>)
                        }

                        if (item.type == "select") {
                          const dynamicOptions = []
                          var labelvalue = item.options.split(",")
                          labelvalue.map((newitem) => {
                            var data = { id: item.id, value: newitem, label: newitem }
                            dynamicOptions.push(data)
                          })
                          return (
                            <>
                              <div className="mt-3 ml-4 px-0 offcanvas_Interest_selector">
                                <h5 className="mb-2 offcanvas_cheakbox_header"> {item.label}{item.is_paid_filter ? <img alt="Filter" src={Vector} width={20} height={20} className="ml-2" /> : ""}</h5>
                                <div className="position-relative">
                                  <Select
                                    options={dynamicOptions}
                                    placeholder="Select Option"
                                    value={multiSelectSelected(item.id)}
                                    onChange={(e) => handleMultiSelect(item.id, e)}
                                    isSearchable={false}
                                    isMulti
                                    isClearable={false}
                                  />
                                  {(!AuthData.is_premium && item.is_paid_filter) ? <div className="filter_disable_section " onClick={() => privteMembership()}></div> : ""}
                                </div>
                              </div>
                            </>
                          )
                        }

                        if (item.type == "range") {
                          return (
                            <>
                              {
                                <>
                                  <div className="" >
                                    <h5 className="range_custom mt-4 offcanvas_cheakbox_header ">
                                      {item.label}{item.is_paid_filter == 1 ? <img alt="Filter" src={Vector} width={20} height={20} className="ml-2" /> : ""}
                                    </h5>
                                    <div className="mx-3 mt-3" onClick={() => (!AuthData.is_premium && item.is_paid_filter) ? privteMembership() : ""}>
                                      <InputRange
                                        disabled={(!AuthData.is_premium && item.is_paid_filter) ? true : false}
                                        maxValue={item.max_range}
                                        minValue={0}
                                        value={getValue(item.id)}
                                        onChange={(e) => {
                                          handleRangeChange(item.id, e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              }
                            </>
                          )
                        }

                        if (item.type == "textbox") {
                          return (
                            <>
                              <div className="">
                                <h5 className="mx-3 mt-4 offcanvas_cheakbox_header">
                                  {item.label}{item.is_paid_filter == 1 ? <img alt="Filter" src={Vector} width={20} height={20} className="ml-2" /> : ""}
                                </h5>
                                <div className="mx-3 my-3" onClick={() => (!AuthData.is_premium && item.is_paid_filter) ? privteMembership() : ""}>
                                  <input type="text" id={item.id} placeholder="Enter Religion"
                                    onChange={handleTextChange} className="form-control offcanvas_filter_input"
                                    disabled={(!AuthData.is_premium && item.is_paid_filter) ? true : false} />
                                </div>
                              </div>
                            </>
                          )
                        }
                      })}
                    </>
                  )
                })
              }
            </div>
          </Offcanvas >
        ))}
        {/* ===== desktop footer ========= */}
        <Footer />
      </div>

      {/* ==== Membershipmodel Componet ======== */}
      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />
    </>
  );
};

export default Content;
