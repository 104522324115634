import React, { useCallback, useRef } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import "../../css/Gallery.css";
import { useState } from "react";
import Showgallery from "./Showgallery";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import {
  addUserGalleryApi,
  GallaryImageDeleteApi,
  UserPrivateGallery,
  UserPublicGallery,
} from "../../api/Api";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { useAuth } from "../../middleware/AuthProvider";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataLoader from "../../components/ScreenLoader/DataLoader";
import CropImageModal from "../CommonComponet/CropImageModal";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Gallery = () => {

  const { AuthData } = useAuth();
  const [currentTarget, setCurrentTarget] = useState(null);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });
  const [image, setImage] = useState(null);

  const [key, setKey] = useState("Gallery");

  const [isPrivate, setIsPrivate] = useState(false);
  const [isDataLoader, setisDataLoader] = useState(false);
  const [publicGallery, setPublicGallery] = useState([]);
  const [pvtGallery, setPvtGallery] = useState([]);
  const [pagePublic, setPagePublic] = useState(1);
  const [pagePvt, setPagePvt] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingpvt, setIsLoadingpvt] = useState(false);
  const [hasMoreRecord, setHasMoreRecord] = useState(true);
  const [hasMoreRecordPvt, setHasMoreRecordPvt] = useState(true);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(true);
  const divRef = useRef(null);
  const divRefpvt = useRef(null);

  
  useEffect(() => {
    UserPrivateGalleryApi();
    UserPublicGalleryApi();
    document.title = "My Gallery On Hukup Australia"
  }, []);

  const UserPublicGalleryApi = (page = pagePublic) => {
    if (page != 1) {
      if (!hasMoreRecord || isLoading) {
        return false;
      }
    }
    var data = {
      page: page,
    };
    setIsLoading(true)
    UserPublicGallery(data)
      .then(function (response) {
        setIsLoading(false)
        setCardSkeleton(false);
        if (page == 1) {
          setPublicGallery(response.data.data)
        }
        else {
          setPublicGallery(publicGallery.concat(response.data.data))
        }
        setPagePublic(page + 1)
        var userDatas = response.data.data;
        if (!userDatas.length) {
          setHasMoreRecord(false);
        }
        else {
          setHasMoreRecord(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const UserPrivateGalleryApi = (page = pagePvt) => {
    if (page != 1) {
      if (!hasMoreRecordPvt || isLoadingpvt) {
        return false;
      }
    }
    var data = {
      page: page,
    };
    setIsLoadingpvt(true)
    UserPrivateGallery(data)
      .then(function (response) {

        setPagePvt(page + 1)
        if (page == 1) {
          setPvtGallery(response.data.data)
        }
        else {
          setPvtGallery(pvtGallery.concat(response.data.data))
        }
        setIsLoadingpvt(false)
        setCardSkeletonMeUser(false)
        var userDatas = response.data.data;
        if (!userDatas.length) {
          setHasMoreRecordPvt(false);
        }
        else {
          setHasMoreRecordPvt(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

// --- scroll and load data ------
  const handleScroll = useCallback(() => {
    if (
      divRef.current &&
      divRef.current.scrollTop + divRef.current.clientHeight >=
      divRef.current.scrollHeight - 200 &&
      !isLoading
    ) {
      UserPublicGalleryApi();
    }

  }, [isLoading, pagePublic, publicGallery, hasMoreRecord]);


  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  // __________________________________
  const handleScrollPvtImg = useCallback(() => {
    if (
      divRefpvt.current &&
      divRefpvt.current.scrollTop + divRefpvt.current.clientHeight >=
      divRefpvt.current.scrollHeight - 200 &&
      !isLoadingpvt
    ) {
      UserPrivateGalleryApi();
    }

  }, [isLoadingpvt, pagePvt, pvtGallery, hasMoreRecordPvt]);

  const coverImagePrivetRef = useRef();
  const showOpenPrivetCoverFileDialog = () => {
    coverImagePrivetRef.current.click();
  };

  const coverImagePublicRef = useRef();
  const showOpenPublicCoverFileDialog = () => {
    coverImagePublicRef.current.click();
  };

  const handleGalleryUpload = (event, is_private) => {
    if (!event.target.files.length) {
      setCurrentTarget(null);
      return false;
    }

    setCurrentTarget(event);
    const files = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (files?.type.includes("image")) {
      if (!allowedTypes.includes(files?.type)) {
        setCurrentTarget(null);
        Store.addNotification({
          message: "Only JPG JPEG and PNG Image allowed",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return;
      }
    }
    setCrop({ aspect: 1.5 / 2, unit: "px", x: 0, y: 0, width: 150, height: 200 });


    if (files.type.includes("image")) {
      setSrc(URL.createObjectURL(files));
      setIsPrivate(is_private)
      event.target.value = null;
    }
    else {
      setisDataLoader(true);
      const formData = new FormData()
      formData.append("image[]", files)
      formData.append("is_private", is_private.toString())
      addUserGalleryApi(formData).then(function (response) {
        setisDataLoader(false)
        if (response.data.status) {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          return false;
        }
        event.target.value = null;
        if (is_private.toString() == "true") {
          UserPrivateGalleryApi(1)
        }
        else {
          UserPublicGalleryApi(1)
        }
      })
        .catch(function (error) {
          setisDataLoader(false)
          // handle error
          console.log(error);
        })
    }
  }

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('tab');
    if (paramValue == "private") {
      setKey("Private Gallery")
    }
    else {
      setKey("Gallery")
    }
  }, [location.search]);
  

  useEffect(() => {
    if (divRefpvt.current) {
      divRefpvt.current.addEventListener("scroll", handleScrollPvtImg);
    }
    return () => {
      if (divRefpvt.current) {
        divRefpvt.current.removeEventListener("scroll", handleScrollPvtImg);
      }
    };
  }, [handleScrollPvtImg]);
  // __________________________________

  // ------- gallery image delete call api ---------- 
  const DeleteImagebutton = (id) => {
    var data = {
      post_id: id
    }
    GallaryImageDeleteApi(data).then(function (response) {
      if (response.data.status == true) {
        Store.addNotification({
          // title: response.data.message,
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          // title: response.data.message,
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
      setPublicGallery(publicGallery.filter((ele) => { return ele.id != id }))
      setPvtGallery(pvtGallery.filter((ele) => { return ele.id != id }))
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ----- crop image function --------
  const cropImageNow = () => {
    if (!crop.width || !crop.height) {
      return false;
    }

    if (crop.height < 20 || crop.width < 20) {
      Store.addNotification({
        title: "Notification",
        message: "Please select large area",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false
        }
      });
      return false;
    }

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = scaleX * crop.width * pixelRatio;
    canvas.height = scaleY * crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    setSrc(null);
    setisDataLoader(true);
    const formData = new FormData()
    formData.append("image[]", blob, "user-" + AuthData.id + ".png")
    formData.append("is_private", isPrivate.toString())
    addUserGalleryApi(formData).then(function (response) {
      setisDataLoader(false)
      if (response.data.status == true) {
        Store.addNotification({
          // title: response.data.message,
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          // title: response.data.message,
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
      if (currentTarget) {
        currentTarget.target.value = null;
      }
      if (isPrivate.toString() == "true") {
        UserPrivateGalleryApi(1)
      }
      else {
        UserPublicGalleryApi(1)
      }
    })
      .catch(function (error) {
        setisDataLoader(false)
        // handle error
        console.log(error);
      })
  };

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={"My Gallery On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="container-fluid gallery_main_section text-center px-0">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 gallery_image_padding">
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="my-2 px-1"
                >
                  <Tab
                    eventKey="Gallery"
                    title="Gallery"
                    className="px-0 tab_liked_you"
                  >
                    <div ref={divRef} className="gallery_tab_main_section ">
                      {cardSkeleton && (
                        <SkeletonTheme >
                          <Row className="m-0">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                              return (
                                <Col xs={6} md={3} lg={2} xxl={2}>
                                  <Skeleton height={220} count={1} />
                                </Col>
                              )
                            })}
                          </Row>
                        </SkeletonTheme>
                      )}
                      <div className="gallery">
                        {publicGallery.map((image, index) => {
                          return <Showgallery key={image.id} userDetail={AuthData} image={image} DeleteImagebutton={DeleteImagebutton} />;
                        })}

                        {
                          cardSkeletonMeUser ? " "
                            :
                            <div className={publicGallery.length ? "upload_img_video_gallery" : "upload_img_video_gallery upload_img_video_gallery_no_data "}
                              onClick={showOpenPublicCoverFileDialog}
                            >
                              <input
                                type="file"
                                accept="image/*, video/*"
                                onChange={(e) => handleGalleryUpload(e, "false")}
                                ref={coverImagePublicRef}
                                className="d-none"
                                multiple
                              />
                              <button
                                className="p-0 gallerypage_plus_btn"
                              >
                                <FaPlus className="fssliders_icon" />
                              </button>
                            </div>
                        }
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="Private Gallery"
                    title="Private Gallery"
                    className="px-0 tab_liked_you"
                  >
                    <div ref={divRefpvt} className="gallery_tab_main_section">
                      {cardSkeletonMeUser && (
                        <SkeletonTheme >
                          <Row className="m-0">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                              return (
                                <Col xs={6} md={3} lg={2} xxl={2}>
                                  <Skeleton height={200} count={1} />
                                </Col>
                              )
                            })}
                          </Row>
                        </SkeletonTheme>
                      )}
                      <div className="gallery ">
                        {pvtGallery.map((image, index) => {
                          return <Showgallery key={image.id} userDetail={AuthData} image={image} DeleteImagebutton={DeleteImagebutton} />;
                        })}

                        {
                          cardSkeletonMeUser ? " "
                            :

                            <div className={pvtGallery.length ? "upload_img_video_gallery" : "upload_img_video_gallery upload_img_video_gallery_no_data"}
                              onClick={showOpenPrivetCoverFileDialog}
                            >
                              <input
                                type="file"
                                accept="image/*, video/*"
                                onChange={(e) => handleGalleryUpload(e, "true")}
                                ref={coverImagePrivetRef}
                                className="d-none"
                                multiple
                              />
                              <button
                                className="p-0 gallerypage_plus_btn"
                              >
                                <FaPlus className="fssliders_icon" />
                              </button>
                            </div>
                        }

                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>

          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
      <CropImageModal
        src={src}
        setSrc={setSrc}
        crop={crop}
        setCrop={setCrop}
        image={image}
        setImage={setImage}
        cropImageNow={cropImageNow}
      />
    </>
  );
};

export default Gallery;
