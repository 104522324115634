const initialState = {
  width: "",
};

const PanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_LEFT_PANEL":
      return {
        ...state,
        width: "230px",
      };
    case "CLOSE_LEFT_PANEL":
      return {
        ...state,
        width: "0px",
      };

    default:
      return state;
  }
};

export default PanelReducer;
