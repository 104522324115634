import styled from "styled-components";

const SnackbarWrapper = styled.div`
  .snackbar {
    p {
      color: white;
    }
  }
`;

export default SnackbarWrapper;
