import React, { useState } from "react";
import "../../css/Subscriptionplan.css";
import { Button, Form, Modal, Col, Container, Row } from 'react-bootstrap';
import Footer from "../CommonComponet/Footer";
import Header from "../CommonComponet/Header";
import { Creditfreeplan, freePlanPurchaseApi, MembershipPlan, PaymentCreateSession, userCouponsApplyApi, UsercreditApply } from "../../api/Api";
import { useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import DataLoader from "../../components/ScreenLoader/DataLoader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAuth } from "../../middleware/AuthProvider";
import { Store } from "react-notifications-component";
import Slider from "react-slick";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";

const Subscriptionplan = () => {
  const { AuthData, siteSetting, membershipPlans } = useAuth();
  const formatDate = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);

    const day = date.getDate(); // Get the day of the month
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });
    const formattedDate = `${getOrdinalSuffix(day)} ${month}, ${year}`;

    return formattedDate;
  };

  function getOrdinalSuffix(day) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  const [stripePromise, setStripePromise] = useState(null);
  const [isDataLoader, setisDataLoader] = useState(false);
  const [cardSkeleton, setCardSkeleton] = useState(false);
  const [couponsUsedModal, setCouponsUsedModal] = useState(false);
  const [couponsError, setCouponsError] = useState('');
  const [coupons, SetCoupons] = useState('');
  const [isCouponSwitch, setIsCouponsSwitch] = useState(false);
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const [planId, setPlanId] = useState('');
  const [couponsData, SetCouponsData] = useState('');
  const [planExpiryDate, setPlanExpiryDate] = useState(formatDate(AuthData.expiry_date));
  const [usercreditSwitch, setUsercreditSwitch] = useState(false);
  const [creditdata , setCreditData] = useState("")

  useEffect(() => {
    document.title = "Membership Plan On Hukup Australia"
  }, []);


  const handleBlockUserClose = () => {
    setCouponsUsedModal(false)
    setIsCouponsSwitch(false)
    SetCoupons("")
    setCouponsError("")
    SetCouponsData("")
    setIsCouponUsed(false)
    setUsercreditSwitch(false)
    setCreditData("")
  }

  useEffect(() => {
    if (siteSetting?.paymentSetting?.stripe_client_secret_key) {
      setStripePromise(loadStripe(siteSetting?.paymentSetting?.stripe_client_secret_key));
    }
  }, [siteSetting])

  const validation = () => {
    Errorclear();
    var isvalid = true
    if (coupons == "" || !coupons || coupons.trim() == "") {
      setCouponsError("Coupon is requeued");
      isvalid = false;
    }
    return isvalid;
  }

  const Errorclear = (e) => {
    setCouponsError("");
  };

  useEffect(() =>{
    if (usercreditSwitch) {
      UserCreditApply();
    }
  }, [usercreditSwitch])

  const UserCreditApply = () => {
    setisDataLoader(true)
    var bodyData = {
      plan_id: planId,
    }
    UsercreditApply(bodyData).then(function (response) {
      console.log(response.data);
      setisDataLoader(false)
      if (!response.data.status) {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setUsercreditSwitch(false);
        setCreditData("");
        return false
      }
      setCreditData(response.data.data)
    }).catch(function (error) {
      console.log(error);
    });
  }

  const CouponsCheckAndApply = () => {
    setisDataLoader(true)
    setIsCouponUsed(false)
    setCouponsError("")
    var bodyData = {
      plan_id: planId,
      coupons_code: coupons
    }
    validation()
    userCouponsApplyApi(bodyData).then(function (response) {
      setisDataLoader(false)
      if (!response.data.status) {
        SetCouponsData('')
        setIsCouponUsed(false)
        setCouponsError(response.data.message)
        return false
      }
      SetCouponsData(response.data.data)
      setIsCouponUsed(true)
      setCouponsError("")
    }).catch(function (error) {
      console.log(error);
    });
  }

  const chooseplan = () => {
    if(usercreditSwitch && creditdata.is_free) {
      var bodyData = {
        plan_id: planId
      }
      Creditfreeplan(bodyData).then(function (response) {
        console.log("response.data", response.data);
        if (!response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        } else {
          setCouponsUsedModal(false)
          Store.addNotification({
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    }
    else if (isCouponSwitch && couponsData.is_free) {
      var bodyData = {
        coupons_id: couponsData.coupons_id,
        plan_id: planId
      }
      freePlanPurchaseApi(bodyData).then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        } else {
          setCouponsUsedModal(false)
          setIsCouponsSwitch(false)
          SetCoupons("")
          setCouponsError("")
          SetCouponsData("")
          setIsCouponUsed(false)
          Store.addNotification({
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      if (!stripePromise) {
        return false;
      }
      setisDataLoader(true)
      var bodyData = {
        plan_id: planId,
        is_coupons_used: isCouponUsed,
        is_credit_used: usercreditSwitch,
      }
      if (isCouponUsed && isCouponSwitch) {
        bodyData.coupons_id = couponsData.coupons_id
      }
      if (isCouponSwitch) {
        if (!validation()) {
          setisDataLoader(false)
          return false;
        }
      }
      PaymentCreateSession(bodyData).then(async function (response) {
        if (response.data.sessionId) {
          const stripe = await stripePromise;
          setisDataLoader(false)
          const { error } = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
          });

          if (error) {
            console.error(error);
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  };


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,

        },
      },
    ],
  };

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={"Membership Plan On Hukup Australia"} />
      </Helmet>
      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />
        <div className="main_section_subscription">
          {AuthData.is_premium ?
            <div className="text-center mt-4">
              <p className="mb-0 plan_Expiry_Date">Your Premium plan would expire on {planExpiryDate}</p>
            </div> :
            ""
          }
          <div className="text-center">
            <h1 className="subscription_title">Membership Plan</h1>
          </div>
          <div className="text-center py-0 py-md-4 py-lg-4 py-xl-4">
            <img
              alt="Premium"
              src={require("../../images/Subscriptionplan/Vector.png")}
              className="Subscriptionplan_crown_icon"
            />
          </div>
          {cardSkeleton && (
            <div className="add_new_skeleton_class">
              <SkeletonTheme>
                <Row className="m-0">
                  {[1, 2, 3].map((ele) => {
                    return (
                      <Col xs={12} lg={4} className="">
                        <Skeleton height={350} />
                      </Col>
                    )
                  })}
                </Row>
              </SkeletonTheme>
            </div>
          )}
          <div className="plan_main_section">
            <Slider {...settings}>
              {membershipPlans.map((item, index) => {
                return (
                  <>
                    <div className="days_plan mx-1 mx-md-3 mx-lg-4 my-4 my-md-3 my-lg-0">
                      <div className="d-flex justify-content-center align-items-center pt-2 pt-sm-3 pt-md-1 pt-lg-0 pt-xl-0">
                        <h2 className="mb-0 plan_price">{siteSetting?.paymentSetting?.symbol}{item.price}</h2>
                        <span className="ml-2 validity_days">/{item.duration} {item.name}</span>
                      </div>
                      <h5 className="text-center mt-2 Subscriptionplan_short_title">{item.short_title}</h5>
                      <hr className="line" />
                      <div className="ql-editor Subscriptionplan_description_in_card"  >
                        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                      </div>

                      <div className="text-center my-3">
                        {
                          item.price > 0 ?
                            <button
                              className="choose_plan_button "
                              onClick={() => {
                                setCouponsUsedModal(true)
                                setPlanId(item.id)
                              }
                              }
                            >
                              Choose Plan
                            </button> :
                            <button
                              className="choose_plan_button"
                            >
                              Choose Plan
                            </button>
                        }
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>

          <FooterMobile />
        </div>
        <Footer />
      </div>
      {/* ======== coupons model ======= */}
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        show={couponsUsedModal}
        onHide={handleBlockUserClose}
      >
        <div className="Userdetails_modal_main_section text-center">
          <Form>
            <h4 className="Userdetails_modal_title pb-3">Purchase Plan</h4>

            {AuthData.eligible_for_referral === 1 && AuthData.is_referral_available && (
              <>
                <hr />
                <div className="d-flex align-items-center justify-content-between my-3  ">
                    <label className="form-check-label Settingnotifications_label_name">Use Credit</label>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                            type="checkbox" role="switch"
                            checked={usercreditSwitch}
                            disabled={AuthData.credits === 0}
                            onChange={(e) => {
                              setUsercreditSwitch(!usercreditSwitch);
                              setIsCouponsSwitch(false);
                              setCreditData("")
                            }} />
                    </div>
                </div>
                <Col>
                                      {
                                        creditdata && (
                                          <>
                                            <p className="text-start">Original Price : {siteSetting?.paymentSetting?.symbol}{creditdata.original_price}</p>
                                            <p className="text-start">Discount: <span className="discount_text">{siteSetting?.paymentSetting?.symbol}{creditdata.discount}</span></p>
                                            <p className="text-start">Discounted Price : {siteSetting?.paymentSetting?.symbol}{creditdata.discounted_price}</p>
                                          </>
                                        )
                                      }
                </Col>
              </>
            )}
            <hr />

                          <div className="d-flex align-items-center justify-content-between my-3  ">
                            <label className="form-check-label Settingnotifications_label_name">Have a coupon ?</label>
                            <div className="form-check form-switch">
                              <input className="form-check-input"
                                type="checkbox" role="switch"
                                checked={isCouponSwitch}
                                onChange={(e) => {
                                  setIsCouponsSwitch(!isCouponSwitch);
                                  setCouponsError('');
                                  SetCouponsData('');
                                  SetCoupons('');
                                  setIsCouponUsed(false)
                                  setUsercreditSwitch(false);
                                  setCreditData("")
                                }} />
                            </div>
                          </div>
                          <hr />
                          {
                            isCouponSwitch && (
                              <>
                              <Row>
                                <Col className="d-flex">
                                  <Form.Control
                                    type="text"
                                    placeholder="Add Coupons..."
                                    value={coupons}
                                    onChange={(e) => SetCoupons(e.target.value.toUpperCase())}
                                  />
                                  <Button className="coupons_apply_btn" onClick={() => CouponsCheckAndApply()}>Apply</Button>
                                </Col>
                                <p className="text-start errorClass">{couponsError}</p>
                                <Col>
                                  {
                                    couponsData && (
                                      <>
                                        <p className="text-start">Original Price : {siteSetting?.paymentSetting?.symbol}{couponsData.original_price}</p>
                                        <p className="text-start">Discount: <span className="discount_text">{siteSetting?.paymentSetting?.symbol}{couponsData.discount}</span></p>
                                        <p className="text-start">Discounted Price : {siteSetting?.paymentSetting?.symbol}{couponsData.discounted_price}</p>
                                      </>
                                    )
                                  }
                                </Col>
                              </Row>
                              <hr />
                              </>
                            )
                          }
            <div className="d-flex">
              <Button className="Userdetails_modal_btn_Report_user mr-1" onClick={() => chooseplan()}>Confirm</Button>
              <Button className="Userdetails_modal_btn_Cancel ml-1" onClick={() => handleBlockUserClose()}>Cancel</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default Subscriptionplan;
