import { combineReducers } from "redux";
import SnackbarReducer from "./SnackbarReducer";
import PanelReducer from "./PanelReducer";

const rootReducer = combineReducers({
  SnackbarReducer,
  PanelReducer,
});

export default rootReducer;
