import React, { useEffect, useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import { useAuth } from '../../middleware/AuthProvider'
import { getGeneralAnnouncementAPI } from "../../api/Api";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";



const GeneralAnnouncement = () => {
  const [dataContent, setDataContent] = useState("")

  useEffect(() => {
    GeneralAnnouncementUser();
    document.title = "What's New On Hukup Australia"
  }, []);

  // ------ genral announccement all data call api --------
  const GeneralAnnouncementUser = () => {
    getGeneralAnnouncementAPI()
      .then(function (response) {
        setDataContent(response.data.data.content)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={"What's New On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />
        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 Blocked_User_section_contain ql-editor">
                <div className="mt-3 What_new"
                  dangerouslySetInnerHTML={{ __html: dataContent }}
                >
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default GeneralAnnouncement;
