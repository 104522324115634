import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav } from 'react-bootstrap'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import hukaplogo from "../../images/hukaplogo.png";
import playstore from "../../images/playstore.png"
import applestore from "../../images/applestore.png"
import { useAuth } from '../../middleware/AuthProvider';
import { BsChatDots } from 'react-icons/bs';
import { GoHome } from "react-icons/go";
import { HiMiniUserGroup } from "react-icons/hi2";
import { IoHeartOutline } from "react-icons/io5";
import { useSocket } from '../../middleware/SocketProvider';
import { GoMegaphone } from "react-icons/go";
import { RiSecurePaymentLine } from "react-icons/ri";


const Footer = (props) => {
  const { siteSetting, footerList , AuthData } = useAuth();
  const { Socket } = useSocket();
  const [unreadCount, setUnreadCount] = useState(0);


  // === Unread Message Count Data Event===== 
  useEffect(async () => {
    if (Socket) {
      Socket.on("has_unread_message", (data) => {
        setUnreadCount(data.unreadCount);
      });
    }
  }, [])

  return (
    <>
      <Container fluid className={props.footercolor == "footercolor"? "footer_main d-none d-lg-block footer_main_chat_color" : "footer_main d-none d-lg-block" }>
        <Container className="">
          <Row className="align-items-center">
            <Col xs={12} sm={6} md={8} lg={6} xl={6} className="order-last order-sm-last order-lg-first order-md-first order-xl-first">
              <Row className="">
                <Col className="px-0">
                  <div className="d-flex footer_content_like_section">

                    {/* <Nav.Link as={Link} to={"/faq"} className="footer_link">FAQ</Nav.Link> */}
                    <a href={"https://blog.hukup.net/faqs/"} className="footer_link text-decoration-none" target="_blank">FAQ</a>

                    {footerList.map((e) => {
                      return (
                        <Nav.Link as={Link} to={"/" + e.slug} className="footer_link">{e.title}</Nav.Link>
                      )
                    })}
                    {/* <a className="text-decoration-none footer_link" href="https://blog.hukup.net/" target="_blank" >Blog</a> */}
                  </div>
                </Col>
              </Row>
              <Row className="">
                <Col className="mb-0 px-0">
                  <p className="footer_Rights">Copyright <AiOutlineCopyrightCircle /> {new Date().getFullYear()} {siteSetting?.siteSetting?.site_name ? siteSetting?.siteSetting?.site_name : "Hukup"} All Rights Reserved</p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6} md={4} lg={6} xl={6} className="px-0">
              <Row className="mx-0 align-items-center">
                <Col xs={12} sm={6} md={3} lg={6} xl={6} className="d-none d-sm-none d-md-none d-lg-block  px-0">
                  <img src={siteSetting?.siteSetting?.site_footer_logo ? siteSetting?.siteSetting?.site_footer_logo : hukaplogo} height={"auto"} width={70} alt="Profile" />
                </Col>
                <Col xs={12} sm={6} md={12} lg={6} xl={6} className="px-0 pt-3 pt-sm-3 pt-md-0 pt-lg-0 pt-xl-0">
                  <div className="footer_apple_play   px-0  ">
                    <a href={siteSetting?.siteSetting?.play_store_app_link} target="_blank" ><img alt='Play Store' src={playstore} className="playstore_img " /></a>
                    <a href={siteSetting?.siteSetting?.app_store_app_link} target="_blank" ><img alt='App Store' src={applestore} className="applestore_img ml-2 " /></a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      {/*======== Mobile And Ipad Screen Footer With Login =================== */}
      {AuthData.role == "user" ?
      <Container fluid className="footer_main_mobile_ipad d-block d-lg-none px-0">
        <div className="d-flex h-100 Mobile_Ipad_main_clr">
          <div className="footer_menu_icon_section">
            <NavLink
              to="/home"
              className={({ isActive }) => isActive
                ? "footer_menu_main_active"
                : "footer_menu_main"
              }
            >
              <GoHome className="footer_icon_menu" />
            </NavLink>
          </div>

          <div className="footer_menu_icon_section" >
            <NavLink
              to="/messages"
              className={({ isActive }) => isActive
                ? "footer_menu_main_active position-relative"
                : "footer_menu_main position-relative"
              }
            >
              {unreadCount > 0 ? <div className="header_messages_notification_dot footer_messages_notification_dot"><span>{unreadCount}</span></div> : ""}
              <BsChatDots className="footer_icon_menu" />
            </NavLink>
          </div>

          <div className="footer_menu_icon_section">
            <NavLink
              to="/visitors"
              className={({ isActive }) => isActive
                ? "footer_menu_main_active"
                : "footer_menu_main"
              }
            >
              <HiMiniUserGroup className="footer_icon_menu" />
            </NavLink>
          </div>

          <div className="footer_menu_icon_section" >
            <NavLink
              to="/likedyou"
              className={({ isActive }) => isActive
                ? "footer_menu_main_active"
                : "footer_menu_main"
              }
            >
              <IoHeartOutline className="footer_icon_menu" />
            </NavLink>
          </div>

          <div className="footer_menu_icon_section" >
            <NavLink
              to="/general-announcement"
              className={({ isActive }) => isActive
                ? "footer_menu_main_active"
                : "footer_menu_main"
              }
            >
              <GoMegaphone className="footer_icon_menu" />
            </NavLink>
          </div>

        </div>
      </Container>
      : 
      <Container fluid className="footer_main_mobile_ipad d-block d-lg-none px-0">
      <div className="d-flex h-100 Mobile_Ipad_main_clr justify-content-between">
        <div className="footer_menu_icon_section">
          <NavLink
            to="/home"
            className={({ isActive }) => isActive
              ? "footer_menu_main_active"
              : "footer_menu_main"
            }
          >
            <GoHome className="footer_icon_menu" />
          </NavLink>
        </div>

        <div className="footer_menu_icon_section">
            <NavLink
              to="/referral-users"
              className={({ isActive }) => isActive
                ? "footer_menu_main_active"
                : "footer_menu_main"
              }
            >
              <HiMiniUserGroup className="footer_icon_menu" />
            </NavLink>
          </div>

        <div className="footer_menu_icon_section">
          <NavLink
            to="/paymenthistory"
            className={({ isActive }) => isActive
              ? "footer_menu_main_active"
              : "footer_menu_main"
            }
          >
            <RiSecurePaymentLine className="footer_icon_menu" />
          </NavLink>
        </div>

      </div>
    </Container>
      }

    </>
  )
}

export default Footer