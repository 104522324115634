import React, { useEffect, useState } from 'react'
import "../../css/PaymentHistory.css"
import { IoSearch } from "react-icons/io5";
import VectorHistory from "../../images/PaymentHistory/VectorHistory.png"
import Footer from '../CommonComponet/Footer';
import Header from '../CommonComponet/Header';
import { Encashmentlist, Paymentrefreeallist, UserPaymentHistory } from '../../api/Api';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAuth } from '../../middleware/AuthProvider';
import FooterMobile from '../CommonComponet/FooterMobile';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from 'react-bootstrap';
import defaultImg from "../../images/user-profile-default-image.png";

const PaymentHistory = () => {

    const { isAuthenticated , AuthData, siteSetting } = useAuth();
    const [search, setSearch] = useState('')
    const [paymentData, setPaymentData] = useState([]);
    const [filtereddata, setFilteredData] = useState([]);
    const [cardSkeleton, setCardSkeleton] = useState(true);
    const [referralpaymentvalue, setreferralpaymentvalue] = useState("");
    const [influencersearch, setInfluencerSearch] = useState("");
    const [CardSkeletonpayemt, setCardSkeletonpayemt] = useState(true);
    const [paymentreferralData, setPaymentreferralData] = useState([]);
    const [filteredpayemtdata, setFilteredpaymetData] = useState([]);
    const [filteredpaumentdata , setfilteredpaumentdata] = useState([]);

    useEffect(() => {
        handleInputChange();
    }, [search]);

    useEffect(() => {
        handleinfluencersearchChange();
    }, [influencersearch]);

    useEffect(() => {
        handlereferralvaluechanges();
    }, [referralpaymentvalue]);

    useEffect(() => {
           PaymentHistoryApi()
           EncashmentlistApi()
           PayemtreferrallistApi();
        document.title = "Payment History Page On Hukup Australia"
    }, []);

    const handleinfluencersearchChange = () => {
        const searchTerms = influencersearch?.toLowerCase().split(' ');
        const filteredResult = paymentData?.filter((ele) => {
            return searchTerms.every(term => (
                ele.status?.toString().toLowerCase().includes(term) ||
                ele.amount?.toString().toLowerCase().includes(term)
            ));
        });
        setFilteredData(filteredResult);
    };


    const handleInputChange = () => {
        const searchTerms = search?.toLowerCase().split(' ');
        const filteredResult = paymentData?.filter((ele) => {
            return searchTerms.every(term => (
                ele.paymentId?.toString().toLowerCase().includes(term) ||
                ele.date?.toString().toLowerCase().includes(term) ||
                ele.price?.toString().toLowerCase().includes(term)
            ));
        });
        setfilteredpaumentdata(filteredResult);
    };

    const handlereferralvaluechanges = () => {
        const searchTerms = referralpaymentvalue?.toLowerCase().split(' ');
        const filteredResult = paymentreferralData?.filter((ele) => {
            return searchTerms.every(term => (
                ele.name?.toString().toLowerCase().includes(term) ||
                formatDate(ele?.created_at)?.toString().toLowerCase().includes(term) ||
                ele.amount?.toString().toLowerCase().includes(term)
            ));
        });
        setFilteredpaymetData(filteredResult);
    };

    const PaymentHistoryApi = () => {
        UserPaymentHistory()
            .then(function (response) {
                setPaymentData(response.data.data)
                setfilteredpaumentdata(response.data.data)
                setCardSkeleton(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const EncashmentlistApi = () => {
        Encashmentlist()
            .then(function (response) {
                console.log("respo", response.data);
                setPaymentData(response.data.data)
                setFilteredData(response.data.data)
                setCardSkeleton(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const PayemtreferrallistApi = () => {
        Paymentrefreeallist()
            .then(function (response) {
                setPaymentreferralData(response.data.data)
                setFilteredpaymetData(response.data.data)
                setCardSkeletonpayemt(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function formatDate(dateString) {
        // Parse the date string
        const date = new Date(dateString);
      
        // Get day, month, year, and time components
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        
        // Format the day with the appropriate suffix
        const daySuffix = (day) => {
          if (day > 3 && day < 21) return 'th';
          switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
          }
        };
      
        // Format time to 12-hour format
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two digits for minutes
      
        // Construct the formatted date string
        return `${day}${daySuffix(day)} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
      }

    var Yourvisitors = <div className="settabfontsize">Payment History<span className="visitor_page_tab_counte ml-1 ml-md-2" >{filteredpaumentdata?.length}</span></div>;
    var Profilesvisited = <div className="settabfontsize" >Referral<span className="visitor_page_tab_counte ml-1 ml-md-2" >{filteredpayemtdata?.length}</span></div>;
    var UsersideEncashment = <div className="settabfontsize" >Encashment<span className="visitor_page_tab_counte ml-1 ml-md-2" >{filtereddata?.length}</span></div>;

    const [key, setKey] = useState(AuthData.role == "user" ? 'Yourvisitors' : 'UsersideEncashment');

    return (
        <>
            <Helmet>
                <meta name="description" content={"Payment History Page On Hukup Australia"} />
            </Helmet>
            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <img alt="orangeline" src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt="greenline" src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt="Ellipse" src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt="footerblackline" src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                <Header />

                <div className="container-fluid content_main px-0">
                    <div className="Payment_History_main_section" >                       

                       {(isAuthenticated && !AuthData.is_block && AuthData.role == "influencer") ? (
                        <div className="container Payment_History_sticky_second_section">
                            <div className="row mt-0 mt-md-4 mt-lg-0">
                                <Tabs
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="my-2 px-1"
                                >
                                    <Tab eventKey="UsersideEncashment" title={UsersideEncashment} className=" ">
                                        <div className='col-12 my-1'>
                                            <div className='row justify-content-end'>
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center my-auto px-md-0">
                                                    <div class="">
                                                        <IoSearch className="search_icon" />
                                                        <input type="text"
                                                            value={influencersearch}
                                                            onChange={(event) => setInfluencerSearch(event.target.value)}
                                                            className="form-control payment_history_searchbar" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 visiter_section_slider px-0">
                                            {cardSkeleton && (
                                                <SkeletonTheme >
                                                    <div className='px-3'>
                                                        <Skeleton height={50} count={10} className='mb-2' />
                                                    </div>
                                                </SkeletonTheme>
                                            )}
                                            {
                                                filtereddata?.map((item) => {
                                                    return (
                                                        <div className="col-12 my-2 px-md-1">
                                                            <div className="history_detail_row">
                                                                <div className="history_detail_icon">
                                                                    <img alt='Premium' src={VectorHistory} />
                                                                </div>
                                                                <div className="history_detail_section">
                                                                    <div className="container-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 text-start text-sm-start text-md-center text-lg-center text-xl-center">
                                                                                <span className="history_detail_id my-auto" >{item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 d-flex justify-content-between justify-content-sm-between  justify-content-md-evenly justify-content-lg-evenly justify-content-xl-evenly">
                                                                                <span className="history_detail_date my-auto">{formatDate(item.created_at)}</span>
                                                                                <span className="history_detail_price my-auto">{siteSetting?.paymentSetting?.symbol}{item.amount}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Profilesvisited" title={Profilesvisited} className=" ">
                                      <div className='col-12 my-1'>
                                            <div className='row justify-content-end'>
                                              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center my-auto px-md-0">
                                                    <div class="">
                                                        <IoSearch className="search_icon" />
                                                        <input type="text"
                                                            value={referralpaymentvalue}
                                                            onChange={(event) => setreferralpaymentvalue(event.target.value)}
                                                            className="form-control payment_history_searchbar" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 visiter_section_slider px-0">
                                            {CardSkeletonpayemt && (
                                                <SkeletonTheme >
                                                    <div className='px-3'>
                                                        <Skeleton height={50} count={10} className='mb-2' />
                                                    </div>
                                                </SkeletonTheme>
                                            )}
                                            {
                                                filteredpayemtdata?.map((item) => {
                                                    return (
                                                        <div className="col-12 my-2 px-md-1">
                                                            <div className="history_detail_row">
                                                                <div className="newpaymentrefrralicon">
                                                                    <img alt='Premium' src={item.image} className='payemtuserimage'/>
                                                                </div>
                                                                <div className="history_detail_section">
                                                                    <div className="container-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-start text-sm-start text-md-center text-lg-center text-xl-center d-flex justify-content-between justify-content-md-center">
                                                                                <span className="history_detail_id my-auto" >{item.name}</span>
                                                                                <span className="history_detail_date my-auto d-block d-md-none">{item.type}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-between justify-content-sm-between  justify-content-md-evenly justify-content-lg-evenly justify-content-xl-evenly">
                                                                                <span className="history_detail_date my-auto d-none d-md-block">{item.type}</span>
                                                                                <span className="history_detail_date my-auto">{formatDate(item.created_at)}</span>
                                                                                <span className="history_detail_price my-auto">{siteSetting?.paymentSetting?.symbol}{item.amount}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     )
                                                })
                                            } 
                                    </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div> 
                       ) 
                       : 
                        ((AuthData.eligible_for_referral === 1 && AuthData.is_referral_available) ? (
                            <div className="container Payment_History_sticky_second_section setuserencashmentclass">
                            <div className="row mt-0 mt-md-4 mt-lg-0">
                                <Tabs
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="my-2 px-1"
                                >                                   
                                    <Tab eventKey="Yourvisitors" title={Yourvisitors} className=" ">
                                        <div className='col-12 my-1'>
                                            <div className='row justify-content-end'>
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center my-auto px-md-0">
                                                    <div class="">
                                                        <IoSearch className="search_icon" />
                                                        <input type="text"
                                                            value={search}
                                                            onChange={(event) => setSearch(event.target.value)}
                                                            className="form-control payment_history_searchbar" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 visiter_section_slider px-0">
                                            {cardSkeleton && (
                                                <SkeletonTheme >
                                                    <div className='px-3'>
                                                        <Skeleton height={50} count={10} className='mb-2' />
                                                    </div>
                                                </SkeletonTheme>
                                            )}
                                            {
                                                filteredpaumentdata?.map((item) => {
                                                    return (
                                                        <div className="col-12 my-2 px-md-1">
                                                            <div className="history_detail_row">
                                                                <div className="history_detail_icon">
                                                                    <img alt='Premium' src={VectorHistory} />
                                                                </div>
                                                                <div className="history_detail_section">
                                                                    <div className="container-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 text-start text-sm-start text-md-center text-lg-center text-xl-center">
                                                                                <span className="history_detail_id my-auto" >{item.paymentId}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 d-flex justify-content-between justify-content-sm-between  justify-content-md-evenly justify-content-lg-evenly justify-content-xl-evenly">
                                                                                <span className="history_detail_date my-auto">{item.date}</span>
                                                                                <span className="history_detail_price my-auto">{siteSetting?.paymentSetting?.symbol}{item.price}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="UsersideEncashment" title={UsersideEncashment} className=" ">
                                        <div className='col-12 my-1'>
                                            <div className='row justify-content-end'>
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center my-auto px-md-0">
                                                    <div class="">
                                                        <IoSearch className="search_icon" />
                                                        <input type="text"
                                                            value={influencersearch}
                                                            onChange={(event) => setInfluencerSearch(event.target.value)}
                                                            className="form-control payment_history_searchbar" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 visiter_section_slider px-0">
                                            {cardSkeleton && (
                                                <SkeletonTheme >
                                                    <div className='px-3'>
                                                        <Skeleton height={50} count={10} className='mb-2' />
                                                    </div>
                                                </SkeletonTheme>
                                            )}
                                            {
                                                filtereddata?.map((item) => {
                                                    return (
                                                        <div className="col-12 my-2 px-md-1">
                                                            <div className="history_detail_row">
                                                                <div className="history_detail_icon">
                                                                    <img alt='Premium' src={VectorHistory} />
                                                                </div>
                                                                <div className="history_detail_section">
                                                                    <div className="container-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 text-start text-sm-start text-md-center text-lg-center text-xl-center">
                                                                                <span className="history_detail_id my-auto" >{item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 d-flex justify-content-between justify-content-sm-between  justify-content-md-evenly justify-content-lg-evenly justify-content-xl-evenly">
                                                                                <span className="history_detail_date my-auto">{formatDate(item.created_at)}</span>
                                                                                <span className="history_detail_price my-auto">{siteSetting?.paymentSetting?.symbol}{item.amount}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Profilesvisited" title={Profilesvisited} className=" ">
                                      <div className='col-12 my-1'>
                                            <div className='row justify-content-end'>
                                              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center my-auto px-md-0">
                                                    <div class="">
                                                        <IoSearch className="search_icon" />
                                                        <input type="text"
                                                            value={referralpaymentvalue}
                                                            onChange={(event) => setreferralpaymentvalue(event.target.value)}
                                                            className="form-control payment_history_searchbar" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 visiter_section_slider px-0">
                                            {CardSkeletonpayemt && (
                                                <SkeletonTheme >
                                                    <div className='px-3'>
                                                        <Skeleton height={50} count={10} className='mb-2' />
                                                    </div>
                                                </SkeletonTheme>
                                            )}
                                            {
                                                filteredpayemtdata?.map((item) => {
                                                    return (
                                                        <div className="col-12 my-2 px-md-1">
                                                            <div className="history_detail_row">
                                                                <div className="newpaymentrefrralicon">
                                                                    <img alt='Premium' src={item.image} className='payemtuserimage'/>
                                                                </div>
                                                                <div className="history_detail_section">
                                                                    <div className="container-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-start text-sm-start text-md-center text-lg-center text-xl-center d-flex justify-content-between justify-content-md-center">
                                                                                <span className="history_detail_id my-auto" >{item.name}</span>
                                                                                <span className="history_detail_date my-auto d-block d-md-none">{item.type}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-between justify-content-sm-between  justify-content-md-evenly justify-content-lg-evenly justify-content-xl-evenly">
                                                                                <span className="history_detail_date my-auto d-none d-md-block">{item.type}</span>
                                                                                <span className="history_detail_date my-auto">{formatDate(item.created_at)}</span>
                                                                                <span className="history_detail_price my-auto">{siteSetting?.paymentSetting?.symbol}{item.amount}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     )
                                                })
                                            } 
                                    </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div> 
                        ) : 
                        <>
                        <div className="container" >
                        <div className="row my-3 my-sm-3 my-md-5 my-lg-4 my-xl-4">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 text-center text-sm-center text-md-end text-lg-end text-xl-end my-auto px-0  ">
                                <h1 className="mr-0 mr-sm-5 mr-md-0 mr-lg-5 mr-xl-5 Payment_History_Title">
                                    Payment History{" "}
                                    <span className="Blocked_User_Data_Count">
                                        {filteredpaumentdata?.length}
                                    </span>
                                </h1>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center my-auto px-0">
                                <div class="search_main_section">
                                    <IoSearch className="search_icon" />
                                    <input type="text"
                                        value={search}
                                        onChange={(event) => setSearch(event.target.value)}
                                        className="form-control payment_history_searchbar" placeholder="Search..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container Payment_History_sticky_second_section">
                        <div className="row mt-0 mt-md-4 mt-lg-0">
                            <div className="col-12 visiter_section_slider px-0">
                                {cardSkeleton && (
                                    <SkeletonTheme >
                                        <div className='px-3'>
                                            <Skeleton height={50} count={10} className='mb-2' />
                                        </div>
                                    </SkeletonTheme>
                                )}
                                {
                                    filteredpaumentdata?.map((item) => {
                                        return (
                                            <div className="col-12 my-2 px-md-1">
                                                <div className="history_detail_row">
                                                    <div className="history_detail_icon">
                                                        <img alt='Premium' src={VectorHistory} />
                                                    </div>
                                                    <div className="history_detail_section">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 text-start text-sm-start text-md-center text-lg-center text-xl-center">
                                                                    <span className="history_detail_id my-auto" >{item.paymentId}</span>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 d-flex justify-content-between justify-content-sm-between  justify-content-md-evenly justify-content-lg-evenly justify-content-xl-evenly">
                                                                    <span className="history_detail_date my-auto">{item.date}</span>
                                                                    <span className="history_detail_price my-auto">{siteSetting?.paymentSetting?.symbol}{item.price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    </>
                        )
                        }
                   
                    </div>
                    <FooterMobile />
                </div>

            </div>
            <Footer />
        </>
    )
}

export default PaymentHistory