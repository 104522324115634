import React, { useState } from 'react'
import Header from '../../CommonComponet/Header'
import Footer from '../../CommonComponet/Footer'
import "./Settingnotifications.css"
import "./TermsPolicies.css"
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { ReportProblemListSetting, ReportSetting } from '../../../api/Api'
import { useEffect } from 'react'
import AccountSettingsidebar from "./AccountSettingsidebar.js"
import RespoAccSettingsidebar from "./RespoAccSettingsidebar.js"
import DataLoader from '../../../components/ScreenLoader/DataLoader/index.js'
import { Store } from 'react-notifications-component'
import FooterMobile from '../../CommonComponet/FooterMobile'
import "./HelpSupport.css"
import { Helmet } from 'react-helmet'

const ReportProblem = () => {

    const [isDataLoader, setisDataLoader] = useState(false);
    const [description, setDescription] = useState("");
    const [reportList, setReportList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [reportType, setReportType] = useState("");
    const [reportsetType, setReportsetType] = useState("");
    const imageInputRef = React.useRef();


    const [erorrreportType, setErrorReportType] = useState("");
    const [erorrdescription, setErrorDescription] = useState("");
    const [erorrselectedFile, setErrorSelectedFile] = useState("");

    useEffect(() => {
        ReportProblemListSettingApi()
        document.title = "Report a Problem On Hukup Australia"
    }, [])
    
    const ReportProblemListSettingApi = () => {
        ReportProblemListSetting().then(function (response) {

            setReportType(response.data.data[0]?.report_type);
            setReportsetType(response.data.data[0]?.report_type);
            setReportList(response.data.data)
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const ReportSettingApi = () => {
        setisDataLoader(true)
        let valid = true;

        setErrorReportType("")
        setErrorDescription(" ")


        if (reportType == "") {
            setErrorReportType("select report type")
            valid = false;
        }
        if (description.trim() == "") {
            setErrorDescription("Enter reson")
            valid = false;
        }


        if (valid) {

            var formdata = new FormData();
            formdata.append("type", reportType);
            formdata.append("description", description.trim());
            formdata.append("media", selectedFile);

            ReportSetting(formdata).then(function (response) {
                setisDataLoader(false)

                if (response.data.status) {
                    Store.addNotification({
                        message: response.data.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__zoomIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: false
                        }
                    });
                }
                else {
                    Store.addNotification({
                        message: response.data.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__zoomIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: false
                        }
                    });
                }

                imageInputRef.current.value = "";
                setDescription("")
                setSelectedFile(null)
                setReportType(reportsetType)

            })
                .catch(function (error) {
                    setisDataLoader(false)
                    console.log(error);
                });
        }
        setisDataLoader(false)

    }

    const handlereason = (e) => {
        if (e.target.value.length > 1000) {
            setErrorDescription("Enter only 1000 letters");
        } else {
            setDescription(e.target.value);
            setErrorDescription("");
        }
    };

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setErrorSelectedFile('');
    //     if (file) {
    //         const extension = file.name.split('.').pop().toLowerCase();
    //         const allowedExtensions = ['jpg', 'jpeg', 'png', 'mp4'];

    //         if (allowedExtensions.includes(extension)) {
    //             setErrorSelectedFile('');
    //             setSelectedFile(file);
    //         } else {
    //             setErrorSelectedFile('Invalid file type. Please select a valid image or video file.');
    //             event.target.value = null;
    //         }
    //     }
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setErrorSelectedFile('');

        if (file) {
            const extension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'mp4'];
            const maxSizeInBytes = 10 * 1024 * 1024;

            if (file.size <= maxSizeInBytes && allowedExtensions.includes(extension)) {
                setErrorSelectedFile('');
                setSelectedFile(file);
            } else {
                if (file.size > maxSizeInBytes) {
                    setErrorSelectedFile('File size exceeds 10MB. Please select a smaller file.');
                } else {
                    setErrorSelectedFile('Invalid file type. Please select a valid image or video file.');
                }
                event.target.value = null;
            }
        }
    };

    return (
        <div>
            <Helmet>
                <meta name="description" content={"Report a Problem On Hukup Australia"} />
            </Helmet>

            {isDataLoader && (
                <DataLoader />
            )}

            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <img alt='smileemoji' src={require("../../../../src/images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='CurveBlackline' src={require("../../../../src/images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='orangeline' src={require("../../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt='greenline' src={require("../../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt='Ellipse' src={require("../../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt='footerblackline' src={require("../../../../src/images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                <Header />
                <div className="container-fluid content_main px-0">
                    <div className="Notifications_main_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-3  Settingnotifications_siderbar_main pl-lg-3 pl-xl-4 d-none d-lg-block">
                                    <AccountSettingsidebar />
                                </div>


                                <div className="col-12 col-lg-8 offset-0 offset-lg-1 ">
                                    <div className="text-center  mt-5 mb-4 d-none d-lg-block">
                                        <h1 className="Settingnotifications_Setting_title">Report a Problem</h1>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-3 d-block d-lg-none ">
                                        <div >
                                            <span className="notifiction_setting_mobile_title">Report a Problem</span>
                                        </div>
                                        <div >
                                            <RespoAccSettingsidebar />
                                        </div>
                                    </div>


                                    <div className="row ">
                                        <div className="col-12 col-md-8 col-lg-6 mx-auto">
                                            <div className="HelpSupport_Popular_Articles_main text-center">
                                                <form>
                                                    <select className="form-select Userdetails_modal_selector mt-3" aria-label="Default select example"
                                                        value={reportType} onChange={(e) => setReportType(e.target.value)}>
                                                        {reportList.map((e) => {
                                                            return (
                                                                <option value={e.report_type}>{e.report_type}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <span className="text-danger">{erorrreportType}</span>
                                                    <div class="mb-3 mt-4">
                                                        <textarea className="form-control Userdetails_modal_textarea" id="exampleFormControlTextarea1" rows="2" placeholder='Enter Reason ' value={description} onChange={(e) => handlereason(e)}></textarea>
                                                        <span className="text-danger">{erorrdescription}</span>
                                                    </div>
                                                    <Form.Group controlId="formFile" className="mb-3 file_upload_input">
                                                        <Form.Control type="file" className='set_input_upload' onChange={handleFileChange} ref={imageInputRef} />
                                                        <span className="text-danger">{erorrselectedFile}</span>
                                                    </Form.Group>
                                                    <div className="mb-4">
                                                        <Button className="Userdetails_modal_btn_Report_user" onClick={ReportSettingApi}>Report Problem</Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMobile />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ReportProblem