import React from 'react'
import { Modal } from 'react-bootstrap';
import '../CommonComponet/CropImageModal.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'

const CropImageModal = (props) => {

    return (
        <div>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={props.src ? true : false}>
                <div className='user_cropimage_model'>
                <Modal.Header>
                    <h4>Crop Image</h4>
                </Modal.Header>
                <Modal.Body className="p-0">
                    {props.src && (
                    <div  className='set_width_img'>
                        <ReactCrop
                            aspect={1.5 / 2}
                            crop={props.crop} onChange={(e) => {
                                props.setCrop(e)
                            }}
                        >
                            <img alt='Profile' className='set_main_img_cropmodel' onLoad={(e) => {
                            props.setImage(e.target)
                            }} src={props.src} />
                        </ReactCrop>
                    </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-space-between">
                    <button className='crop_button mr-2' onClick={props.cropImageNow}>Crop</button>
                    <button className='crop_button' onClick={() => {
                        props.setSrc(null)
                    }}>Cancel</button>
                    </div>
                </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}

export default CropImageModal