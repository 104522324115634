import React, { createContext, useContext, useEffect, useState } from "react";
import io from 'socket.io-client';
import api from "../../api";
const SOCKET_SERVER_URL = api.socketURL;

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

  const [Socket, setSocket] = useState(null);

  const connectSocket = (user_id) => {
    const socketData = io(SOCKET_SERVER_URL + "?userId=" + user_id);
    setSocket(socketData);
  }

  useEffect(() => {
    async function checkSocket() {
      const Token = localStorage.getItem("hukup-info");
      
      if (Token) {
        try {
          var data = JSON.parse(atob(Token));
          connectSocket(data.id)
        } catch (error) {
          setSocket(null);
        }
      } else {
        setSocket(null);
      }
    }
    checkSocket();

    return () => {
      Socket.disconnect();
      setSocket(null);
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        Socket,
        connectSocket
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
