import React from "react";
import Modal from "react-bootstrap/Modal";
import Calendar from "../../images/UserDetailspage/Calendar.png";
import { FaPlay } from "react-icons/fa6";
import { AiOutlinePause } from "react-icons/ai";
import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import { GallaryImageSetProfileApi } from "../../api/Api";
import { useAuth } from "../../middleware/AuthProvider";
import { useEffect } from "react";
import { Store } from "react-notifications-component";

function Showgallery({ image, userDetail, DeleteImagebutton }) {

  const { AuthData, setAuthData } = useAuth()
  const [show, setShow] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [mediaURL, setMediaURL] = useState(image.url);
  const [isImg, setIsImg] = useState(false);

  useEffect(() => {
    setMediaURL(image.url)
  }, [image.url])

  const handleClose = () => { setShow(false); setIsPlayingModal(false) };
  const handleShow = (url, type) => {
    if (type == "image") {
      setIsImg(true);
    }
    setModalUrl(url);
    setShow(true);
    if (isPlaying) {
      const video = videoRef.current;
      video.pause();
      setIsPlaying(false)
    }
  };

  useEffect(() => {
    if (image?.type == "video") {
      const videoModal = videoRefModal.current;
      if (videoModal) {
        videoModal.play();
        setIsPlayingModal(true);
      }
    }
  }, [show])


  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsPlaying(true);
    }
  };

  const videoRefModal = useRef(null);
  const [isPlayingmodal, setIsPlayingModal] = useState(false);

  const togglePlayModalVideo = () => {
    const videoModal = videoRefModal.current;
    if (isPlayingmodal) {
      videoModal.pause();
      setIsPlayingModal(false);
    } else {
      videoModal.play();
      setIsPlayingModal(true);
    }
  };


  const handleVideoEnded = () => {
    // Video has ended, set isPlaying to false
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
    }
  };


  const handleModalVideoEnded = () => {
    // Video has ended, set isPlaying to false
    const videoModal = videoRefModal.current;
    if (videoModal) {
      videoModal.currentTime = 0;
      videoModal.play();
    }
  };

  // ====== gallery time image upload convert ======= 
  const convertTimestampToTime = (timestamp) => {
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
    ];

    const messageDate = new Date(timestamp);

    const hours = messageDate.getHours();
    const minutes = messageDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedMonth = monthNames[messageDate.getMonth()];
    const formattedMonthAbbreviation = formattedMonth.slice(0, 3);
    const formattedTimes = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return `${messageDate.getDate()}th ${formattedMonthAbbreviation} ${messageDate.getFullYear()}, ${formattedTimes}`;
  };

  //  ===== uploded image to set a profile picture ====
  const setprofileuser = (id) => {
    var data = {
      post_id: id
    }
    GallaryImageSetProfileApi(data).then(function (response) {
      if (response.data.status) {
        setAuthData({ ...AuthData, image: response.data.data.image })
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      {image?.type == "image" ? (
        <div class="position-relative p-2">
          <img
            alt="Gallary"
            className="gallery__item"
            src={mediaURL}
            onClick={(e) => handleShow(mediaURL, image?.type)}
          />
          {AuthData.id == image.user_id && (
            <div className="delete_image">
              <IoClose className="deleteIcon" onClick={() => DeleteImagebutton(image.id)} />
            </div>
          )}
        </div>
      ) : (
        <div className="videoPlay p-2">
          <video
            ref={videoRef}
            controls={false}
            onEnded={handleVideoEnded}
            onClick={(e) => handleShow(mediaURL, image?.type)}
            className="gallery_video"
          >
            <source src={mediaURL} type="video/mp4" />
          </video>
          <span className="play_icon" onClick={togglePlay}>
            {isPlaying ? <AiOutlinePause /> : <FaPlay />}
          </span>
          {AuthData.id == image.user_id && (
            <div className="delete_image">
              <IoClose className="deleteIcon" onClick={() => DeleteImagebutton(image.id)} />
            </div>
          )}
        </div>
      )}

      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
        centered
      >
        <div className="user_image_model">
          <p className="user_image_model_close" onClick={handleClose}><IoCloseSharp /></p>
          <Modal.Header className="user_post_header d-none d-lg-block">
            <div className="user_header_main">
              <Link to={AuthData?.username == userDetail?.username ? "/my-profile" : "/user/" + userDetail?.username}>
                <img alt="Profile" src={userDetail?.image} className="profile_model_image"></img>
              </Link>
              <div>
                <div className="user_image_name">
                  <Link to={AuthData?.username == userDetail?.username ? "/my-profile" : "/user/" + userDetail?.username}>
                    {userDetail?.name}
                  </Link>
                </div>
                <div className="user_image_location">
                  {userDetail?.location}
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="p-0 gallery_modal_body_main">
            <div className="user_modal_main_section">
              {isImg ? (
                <img alt="Gallary" src={modalUrl} className="user_model_post"></img>
              ) : (
                <div className="videoPlay">
                  <video
                    ref={videoRefModal}
                    controls={false}
                    className="user_model_post_video"
                    onEnded={handleModalVideoEnded}
                  >
                    <source src={modalUrl} type="video/mp4" />
                  </video>
                  <span className="play_icon" onClick={togglePlayModalVideo}>
                    {isPlayingmodal ? <AiOutlinePause /> : <FaPlay />}
                  </span>
                </div>
              )}
            </div>
            {/* <div className="gallery_responcive_btn d-lg-none">
            {image?.type == "image" && !image?.is_private && AuthData?.username == userDetail?.username ? <button onClick={()=> setprofileuser(image.id)} className="ml-auto gallery_set_profile_btn">Set as profile</button> : ""}
            </div> */}
          </Modal.Body>
          <div className="gallery_responcive_btn d-lg-none">
            {image?.type == "image" && !image?.is_private && AuthData?.username == userDetail?.username ? <button onClick={() => setprofileuser(image.id)} className="ml-auto gallery_set_profile_btn">Set as profile</button> : ""}
          </div>
          <Modal.Footer className="user_post_footer d-none d-lg-block">
            <div className="user_image_model_footer">
              <img alt="Calander" src={Calendar} className="icon_heart_red" />
              <span className="mt-1 gallery_ima_modal_footer">
                {convertTimestampToTime(image.created_at)}
              </span>
              {image?.type == "image" && !image?.is_private && AuthData?.username == userDetail?.username ? <button onClick={() => setprofileuser(image.id)} className="ml-auto gallery_set_profile_btn">Set as profile</button> : ""}
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
export default Showgallery;
